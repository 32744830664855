import { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2";
import { AuthContext } from '../../../context/AuthContext';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { PiChatsCircleFill } from "react-icons/pi";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const FeedbackChart = () => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [selectedPeriod, setSelectedPeriod] = useState("week");
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser?.token) {
      setLoading(false);
      return;
    }

    const fetchFeedbacks = async () => {
      try {
        setLoading(true);
        const token = currentUser.token;
        const response = await fetch(
          `https://zola.technology/api/company/feedback/get-filtered-feedback/?filter=${selectedPeriod}`,
          {
            method: 'GET',
            headers: {
              'accept': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          }
        );

        const data = await response.json();
        if (!Array.isArray(data)) throw new Error("Invalid data format");

        // Set labels and data based on selected period
        let labels = [];
        let feedbackCounts = [];

        switch (selectedPeriod) {
          case "week":
            labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            feedbackCounts = data.map(day => day.feedback_count || 0);
            break;
          case "month":
            labels = Array.from({ length: 31 }, (_, i) => `Day ${i + 1}`);
            feedbackCounts = data.map(day => day.feedback_count || 0);
            break;
          case "year":
            labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            feedbackCounts = data.map(month => month.feedback_count || 0);
            break;
          default:
            break;
        }

        setChartData({
          labels,
          datasets: [
            {
              label: "Feedback Count",
              data: feedbackCounts,
              backgroundColor: feedbackCounts.map((count, index) =>
                index === 5 ? "#3b82f6" : "rgba(59, 130, 246, 0.5)"
              ),
              borderColor: "#3b82f6",
              borderWidth: 1,
              borderRadius: 5,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching feedbacks:", error);
        setChartData({ labels: [], datasets: [] });
      } finally {
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, [currentUser?.token, selectedPeriod]);

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw} feedbacks`,
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 20,
          callback: (value) => value,
        },
      },
    },
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg">
      <div className="p-4 flex items-center justify-between border-b gap-2">
        <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200 flex items-center gap-2">
          <span className="inline-flex items-center justify-center rounded bg-white border border-gray-300 dark:border-gray-600">
            <PiChatsCircleFill size={32} className="text-blue-400" />
          </span>
          Feedback Count
        </h2>
        <select
          value={selectedPeriod}
          onChange={handlePeriodChange}
          className="py-1 px-3 text-sm bg-white dark:bg-gray-700 text-gray-800 dark:text-white border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none"
        >
          <option value="week">This Week</option>
          <option value="month">This Month</option>
          <option value="year">This Year</option>
        </select>
      </div>

      {/* Bar Chart */}
      <div className="p-4 h-72">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
          </div>
        ) : (
          <Bar data={chartData} options={chartOptions} />
        )}
      </div>
    </div>
  );
};

export default FeedbackChart;
