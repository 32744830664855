import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { getLinkInteractions } from "../../../api";
import { IoMdArrowDropup } from "react-icons/io";
import "./Taps.css";
import { PiCardsFill } from "react-icons/pi";

function Taps() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("week");
  const [totalTaps, setTotalTaps] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const result = await getLinkInteractions(token, selectedPeriod);
        const transformedData = result.data.map((emp) => ({
          name: emp.name,
          taps: emp.filtered_taps_count,
        }));
        setData(transformedData);

        setTotalTaps(result.total_filtered_taps);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, [selectedPeriod]);

  const calculatePercentageChange = useMemo(() => {
    if (data.length === 0) return 0;

    const currentPeriodTaps = data.reduce((sum, emp) => sum + emp.taps, 0);
    const previousPeriodTaps = data.length * 100; // Assuming previous period taps are 100 for each employee for simplicity

    if (previousPeriodTaps === 0) {
      return currentPeriodTaps === 0 ? 0 : 100;
    }

    const change =
      ((currentPeriodTaps - previousPeriodTaps) / previousPeriodTaps) * 100;
    return change.toFixed(2);
  }, [data]);

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  return (
    <div className="rounded-lg bg-white">
      <div className="p-4 flex items-center justify-between border-b gap-2">
        <h2 className="text-2xl font-semibold text-gray-700 dark:text-gray-200 flex items-center gap-2">
          <span className="inline-flex items-center justify-center rounded bg-white border border-gray-300 dark:border-gray-600">
            <PiCardsFill size={32} className="text-blue-400" />
          </span>
          Active Cards
        </h2>
        <select
          value={selectedPeriod}
          onChange={handlePeriodChange}
          className="py-1 px-3 text-sm bg-white dark:bg-gray-700 text-gray-800 dark:text-white border border-gray-300 dark:border-gray-600 rounded-md focus:outline-none"
        >
          <option value="day">Today</option>
          <option value="week">This Week</option>
          <option value="month">This Month</option>
          <option value="year">This Year</option>
        </select>
      </div>

      <div className="p-4 flex text-6xl items-baseline gap-3 font-bold text-gray-800 dark:text-white mb-1">
      {totalTaps} <span className="text-gray-500 dark:text-gray-400 text-lg">
          Taps
        </span>
      </div>

      <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        {data.map((item, index) => (
          <div key={index} className="flex flex-col">
            <div className="flex items-center justify-between">
              <span className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {item.name}
              </span>
              <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
                {item.taps}
                <span className="text-xs font-normal text-gray-400 ml-1">
                  taps
                </span>
              </span>
            </div>
            <div className="flex items-center gap-2">
              <div className="flex-grow h-6 bg-gray-200 rounded-full overflow-hidden">
                <div
                  className="h-full bg-blue-500"
                  style={{ width: `${(item.taps / 150) * 100}%` }}
                ></div>
              </div>
              
            </div>
          </div>
        ))}
      </div>
        
    </div>
  );
}

Taps.propTypes = {
  className: PropTypes.string,
};

export default Taps;
