import ReviewsSection from "./ReviewsSection";

const Reviews = () => {
  return (
    <article>
      <ReviewsSection title="Reviews" />
    </article>
  );
};

export default Reviews;
