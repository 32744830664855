import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import logo from "./zola.jpg";

function AuthenticatedNavbar() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const [navbar, setNavbar] = useState(false);

  const handleSignOut = () => {
    logout();
    navigate("/login");
  };

  return (
    <nav className="w-full fixed top-0 bg-black shadow custom-navbar">
      <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <img
              src={logo}
              style={{
                width: "300px",
                height: "40px",
                objectFit: "cover",
              }}
              alt=""
            />
            <div className="md:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${navbar ? "block" : "hidden"}`}
          >
            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              <li className="text-white">
                <button className="sign-out-button" onClick={handleSignOut}>
                  Sign Out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );

  // return (

  //   <nav className="navbar">
  //     <div className="navbar-logo">
  //       <img
  //         src={logo}
  //         style={{
  //           width: "300px",
  //           height: "40px",
  //           objectFit: "cover",
  //         }}
  //         className="sm:ml-[7rem] ml-0"
  //         alt=""
  //       />
  //     </div>
  //     <div className="navbar-links">
  //       <button className="sign-out-button" onClick={handleSignOut}>
  //         Sign Out
  //       </button>
  //     </div>
  //   </nav>
  // );
}

export default AuthenticatedNavbar;
