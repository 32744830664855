import React, { useContext, useEffect, useState } from "react";
import { FaExclamationTriangle, FaBuilding } from "react-icons/fa";
import { TiArrowSortedDown } from "react-icons/ti";
import { PiGitBranchBold } from "react-icons/pi";
import { PiUsersFill } from "react-icons/pi";

import { BranchContext } from "../context/BranchContext";

import { HiChartBar } from "react-icons/hi";
import ReactApexChart from "react-apexcharts";
import { color } from "framer-motion";
import { data } from "autoprefixer";
import cover from "../assets/cover.png";
import { useMediaQuery } from "@mui/material";
import { fontSize, height, padding } from "@mui/system";

const HQAnalytics = () => {
  const [selectedBarTime, setSelectedBarTime] = useState("This Month");
  const [selectedPieTime, setSelectedPieTime] = useState("This Month");
  const [isBarDropdownOpen, setBarDropdownOpen] = useState(false);
  const [isPieDropdownOpen, setPieDropdownOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const timelines = ["This Month", "Last 3 Months", "Last 6 Months"];
  const { branches, employeeTaps } = useContext(BranchContext);
  const [branch, setBranch] = useState(null);
  const [branchData, setBranchData] = useState(null);
  const[allBranchData, setAllBranchData] = useState(null)
  const [error, setError] = useState(null);
  // Retrieve token from local storage

  useEffect(() => {
    const fetchBranchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        return;
      }
  
      try {
        // Fetch data from the first endpoint
        const response1 = await fetch(
          "https://zola.technology/api/hq-company/branches/get-data/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response1.ok) {
          throw new Error(`Error: ${response1.status}`);
        }
  
        const branchData = await response1.json();
  
        // Fetch data from the second endpoint
        const response2 = await fetch(
          "https://zola.technology/api/hq-company/list/paginated-or-all-branch-companies/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response2.ok) {
          throw new Error(`Error: ${response2.status}`);
        }
  
        const allBranchData = await response2.json();
  
        // You can update states for both data sets
        setBranchData(branchData);         // Update state for the first fetch
        setAllBranchData(allBranchData);   // Update state for the second fetch
  
      } catch (error) {
        setError(error.message);
      }
    };
  
    fetchBranchData();
  }, []); // Empty dependency array to run only on mount
  
  console.log(branchData, "set state");
  console.log(allBranchData, "all branch");

  // Data for the bar chart
  const employeeData = branches ? branches.map((branch) => ({
    name: branch.name,
    employees: branch.employees,
  })) : [];

  // ApexCharts barOptions and barSeries
  const barOptions = {
    chart: {
      type: "bar",

      width: isSmallScreen ? "100%" : "100%",
    },
    plotbarOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          position: "top",
        },
        columnWidth: isSmallScreen ? "30%" : "50%", // Increase space between columns on small screens
      },
    },

    colors: ["rgba(66, 100, 223, 0.39)"],

    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: employeeData.map((data) => data.name),
      position: "bottom",
      labels: {
        offsetY: 0,
        rotate: 0, // Set rotation to 0 to display labels horizontally
        style: {
          fontSize: isSmallScreen ? "10px" : "12px", // Smaller font size on small screens
        },
      },
      axisBorder: {
        show: !isSmallScreen, // Hide x-axis line on small screens
      },
      axisTicks: {
        show: !isSmallScreen, // Hide tick marks on small screens
      },
      barHeight: "70%", // Adjust the bar height to space out the bars
    },
    yaxis: {
      labels: {
        formatter: (val) => val,
      },
    },
    tooltip: {
      y: {
        formatter: (val) => val,
      },
    },
    grid: {
      show: !isSmallScreen, // Hide grid lines on small screens
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            width: "118%",
            offsetX: 5,
          },
          xaxis: {
            labels: {
              rotate: 0,
              style: {
                fontSize: "8px",
              },
            },
          },
          axisBorder: {
            show: false, // Hide x-axis line on small screens
          },
          axisTicks: {
            show: false, // Hide tick marks on small screens
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "30%", // Increase space between columns on small screens
          },
        },
        grid: {
          show: false, // Hide grid lines on small screens
        },
      },
    ],
  };

  const barSeries = [
    {
      name: "Employees",
      data: employeeData.map((data) => data.employees),
    },
  ];

  
  const colors = ["#E52777", "#00C459", "#FFBC07", "#209EFF", "#A577FF"];
  // Data for the pie chart
  const performanceData = allBranchData?.map((branch, index) => ({
    id: index,
    value: branch.performance_score || 0,
    label: branch.preferred_business_name,
    color: colors[index % colors.length],
  })) || [];

  // Sort the performance data by value in descending order and slice the top 5 entries
  const top5PerformanceData = performanceData?.slice(0, 5);


  // Calculate total value for percentage calculation
  const totalValue = top5PerformanceData?.reduce(
    (sum, item) => sum + item.value,
    0
  );

  // Extract values, labels, and percentages for the donut chart
  const donutSeries = top5PerformanceData.map((data) => data.value) || [];
  const donutLabels = top5PerformanceData.map((data) => data.label) || [];
  const donutPercentages = top5PerformanceData.map((data) =>
    ((data.value / totalValue) * 100).toFixed(2)
  ) || [];

  // Base width for the legend marker
  const baseWidth = isSmallScreen ? 4 : 5; // You can adjust this value as needed

  // ApexCharts options for the donut chart
  const donutOptions = {
    chart: {
      type: "donut",
      height: 250,
      offsetX: -100,
    },
    labels: donutLabels,
    plotOptions: {
      pie: {
        donut: {
          size: isSmallScreen ? "65%" : "65%", // Adjust the size of the donut chart

          labels: {
            show: true,
            total: {
              show: true,
              label: "Top 5 Branches",
              formatter: function () {
                // Use Unicode spaces to simulate line breaks
                return "";
              },
              style: {
                fontSize: isSmallScreen ? "16px" : "16px", // Adjust the font size if needed
                fontWeight: 800,
                color: "#000", // Customize the color as needed
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 6, // Increase the stroke width to 4
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 500,
            offsetX: 0,
            offsetY: 5,
          },

          legend: {
            position: "bottom",
            offsetY: 5,
            offsetX: 0,
            fontSize: "8px", // Smaller font size for small screens
            height: 200,
            itemPadding: {
              horizontal: 2,
              vertical: 2,
            },
            labels: {
              fontSize: "14px", // Smaller font size for small screens
              offsetX: 0,
            },
            itemMargin: {
              horizontal: 2,
              vertical: 2,
            },
          },
        },
      },
    ],
    legend: {
      position: "right",
      offsetY: 0,
      offsetX: isSmallScreen ? 0 : 0, // Adjust the offset on small screens
      with: 300,
      height: 240,
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },

      formatter: function (seriesName, opts) {
        const index = opts.seriesIndex;
        const percentage = donutPercentages[index];
        const color = top5PerformanceData[index].color;
        const finalWidth = baseWidth * percentage;
        return `
          <div class="custom-legend-item">
            <div class="legend-marker" style="width: ${finalWidth}px; background-color: ${color};">
              ${percentage}%
            </div>
            ${seriesName}
          </div>
        `;
      },
    },
    fill: {
      colors: top5PerformanceData?.map((data) => data.color),
    },
  };

  return (
    <div className="dark:bg-gradient-to-b from-[#1F2937] to-[#2D3748] min-h-screen  ">
      <h1 className="text-[24px] font-medium mb-4 xs:hidden">Analytics</h1>
      <div className="lg:hidden text-[14px] mb-6 mt-[-34px]">
        <h1 className="text-[20px] font-medium">Hello Mastercrafts</h1>
        <p className="text-[14px]">Welcome to the Headquarters Dashboard! </p>
      </div>
      <div className="grid xs:grid-cols-2 sm:grid-cols-2 h-[150px]  lg:grid-cols-4 lg:gap-8 xs:gap-5 lg:mb-8 xs:mb-[200px] ">
        {/* Total Employees Card */}
        <div className="group bg-white rounded-lg xs:h-[150px] lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-blue-600 text-white p-2 rounded-lg">
              <PiUsersFill size={24} />
            </div>
            <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
              Total <br />
              Employees
            </p>
          </div>
          <p className="text-3xl font-semibold text-[#1E1E1E] group-hover:text-white">
            {branchData?.total_employees}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>

        {/* Total Issues Card */}
        <div className="group bg-white xs:h-[150px]  rounded-lg lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-red-600 text-white p-2 rounded-lg">
              <FaExclamationTriangle size={24} />
            </div>
            <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
              Total <br />
              Feedback
            </p>
            <p className="text-3xl font-semibold text-gray-900 group-hover:text-white">
              {branchData?.total_feedback_count}
            </p>
          </div>
          <p className="px-2 rounded-xl bg-green-100 text-green-400 group-hover:bg-green-200 group-hover:text-green-600">
            Positive: {branchData?.total_positive_feedback_count}
          </p>
          <p className="px-2 rounded-xl bg-red-100 text-red-400 group-hover:bg-red-200 group-hover:text-red-600">
            Negative: {branchData?.total_negative_feedback_count}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>

        {/* Average Performance Card */}
        <div className="group bg-white xs:h-[150px]  rounded-lg lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-[#00C159] text-white p-2 rounded-lg">
              <HiChartBar size={24} />
            </div>
            <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
              Average <br />
              Performance
            </p>
          </div>
          <p className="text-3xl font-semibold text-[#1E1E1E] group-hover:text-white">
            {branchData?.average_performance_score}%
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>

        {/* Total Branches Card */}
        <div className="group bg-white xs:h-[150px]  rounded-lg lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-[#6100C1] text-white p-2 rounded-lg">
              <PiGitBranchBold size={24} />
            </div>
            <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
              Total <br />
              Branches
            </p>
          </div>
          <p className="text-3xl font-semibold text-[#1E1E1E] group-hover:text-white">
            {branchData?.total_branches}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>
      </div>

      {/* Bar Chart for Employees per Branch */}
      <div className="lg:w-full md:w-full sm:w-full xs:w-[340px] mb-12 bg-[#FFFFFF] lg:p-8 xs:p-2 rounded-[20px]">
        <div className="lg:flex  text-[#4C4C4C] justify-between mb-8 h-[40px] xs:hidden">
          <h2 className="text-[24px] font-semibold ">Employees</h2>

          <div className="relative">
            <button
              className="flex space-x-2 border rounded-[5px] justify-center items-center p-2"
              onClick={() => setBarDropdownOpen(!isBarDropdownOpen)}
            >
              <span className="text-[14px] text-[#181818] font-light">
                {selectedBarTime}
              </span>
              <TiArrowSortedDown className="h-5 w-5 text-[#929292]" />
            </button>
            {isBarDropdownOpen && (
              <ul className="absolute left-0 mt-1 bg-white shadow-lg rounded-md z-10">
                {timelines.map((timeline) => (
                  <li
                    key={timeline}
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                    onClick={() => {
                      setSelectedBarTime(timeline);
                      setBarDropdownOpen(false);
                    }}
                  >
                    {timeline}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="lg:w-full xs:w-full overflow-x-hidden">
          <style>
            {`
          .apexcharts-bar-series .apexcharts-series[seriesName="Employees"] .apexcharts-bar-area {
            fill: rgba(66, 100, 223, 0.39);
            transition: fill 0.3s;
            
          }
          .apexcharts-bar-series .apexcharts-series[seriesName="Employees"] .apexcharts-bar-area:hover {
            fill: #4264DF;
          
          }
          .apexcharts-toolbar {
            display: none;
          }
        
       
          
        `}
          </style>
          <ReactApexChart
            options={barOptions}
            series={barSeries}
            type="bar"
            height={isSmallScreen ? 300 : 350}
          />
        </div>
      </div>

      {/* Pie Chart for Branch Performance */}
      <div className="mb-12 bg-[#FFFFFF]  rounded-[20px]">
        <div className="xs:p-4 lg:p-8  lg:border-b">
          <div className="text-[#4C4C4C] flex  justify-between  lg:h-[40px] xs:h-[60px]">
            <h2 className="lg:text-[24px] xs:text-[16px] font-semibold lg:w-[300px] xs:w-4">
              Branch performance
            </h2>

            <div className="relative">
              <button
                className="flex space-x-2 border rounded-[5px] justify-center items-center p-2"
                onClick={() => setPieDropdownOpen(!isBarDropdownOpen)}
              >
                <span className="text-[14px]  text-[#181818] font-light">
                  {selectedPieTime}
                </span>
                <TiArrowSortedDown className="h-5 w-5 text-[#929292]" />
              </button>
              {isPieDropdownOpen && (
                <ul className="absolute left-0 mt-1 bg-white shadow-lg rounded-md z-10">
                  {timelines.map((timeline) => (
                    <li
                      key={timeline}
                      className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        setSelectedPieTime(timeline);
                        setPieDropdownOpen(false);
                      }}
                    >
                      {timeline}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <span className="text-[16px] text-[#6F707E]">
            Highest performing branches
          </span>
        </div>
        <div>
          <div
            style={{
              width: "100%",
              height: isSmallScreen ? 550 : 300,
              marginTop: 20,
            }}
            className="custom-legend-container"
          >
            <style>
              {`
          .custom-legend-container .custom-legend-item {
            font-size: 18px;
            color: #4C4C4C;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px;
            width: 100%;
            text-align: center;
          }

          .custom-legend-container .legend-marker {
            display: inline-block;
            height: 25px;
            margin-right: 5px;
            color: white;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            border-radius: 6px;
            padding: 5px;
          }

            .custom-legend-container .apexcharts-legend {
           border: 1px solid #e0e0e0;
            border-radius: 17px;
          
            height: auto; /* Adjust height for small screens */
            
            
   
            }

            .custom-legend-container .apexcharts-legend-marker {
              display: none; !important
            
            }
         .custom-legend-container span.apexcharts-legend-marker {
          display: none;
          }

          .custom-legend-container {
          margin-top: 50px;
          width: 100%;
          height: auto
          }
         
         
          
        

      
        .custom-legend-container span.apexcharts-legend-text {
         
          font-weight: 500;
          color: #4C4C4C;
       
        }
      
         @media (max-width: 480px) {

          .custom-legend-container .custom-legend-item {
            font-size: 15px;
            color: #4C4C4C;
            display: flex;
            align-items: center;
            justify-content: center;
            padding:0;
          
            width: 100%;
            text-align: center;
          }

          .custom-legend-container .legend-marker {
            display: inline-block;
            height: 22px;
            margin-right: 5px;
            color: white;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            border-radius: 6px;
            padding: 3px;
          }

            .custom-legend-container .apexcharts-legend {
            position: relative;
            justify-content: start;
            padding: 0;
            border: none;
            
           
           
            
            
   
            }

            
      

          .custom-legend-container {
          margin-top: 50px;
          width: 100%;
          padding-right: 10px
         
          }

          div.apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
          position: relative;
          justify-content: start;
          height: auto
         
           
          
        }

        
     
      }

         
         
          
        

     


            

          }
         

    
      

        
            `}
            </style>
            <ReactApexChart
              options={donutOptions}
              series={donutSeries}
              type="donut"
              height={isSmallScreen ? 300 : 300}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HQAnalytics;
