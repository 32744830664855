import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("token"));
  const [loggedOut, setLoggedOut] = useState(false);

  const navigate = useNavigate();
  let intervalId;

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    try {
      const response = await fetch("https://zola.technology/api/token/refresh/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh: refreshToken }),
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("token", data.access);
        const newExpiryTime = new Date().getTime() + 24 * 60 * 60 * 1000;
        localStorage.setItem("expiryTime", newExpiryTime);
      } else {
        logout();
      }
    } catch (error) {
      console.error("Failed to refresh token:", error);
      logout();
    }
  };

  const checkTokenExpiry = () => {
    const expiryTime = localStorage.getItem("expiryTime");
    const currentTime = new Date().getTime();

    if (expiryTime && currentTime >= expiryTime - 10 * 60 * 1000) {
      refreshToken();
    }
  };

  const checkTokensAndStartCountdown = () => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    if (token && refreshToken) {
      setIsAuthenticated(true);
      checkTokenExpiry();
      intervalId = setInterval(checkTokenExpiry, 5 * 60 * 1000);
    }
  };

  const login = ({ username, token, firstName, rtoken }) => {
    setCurrentUser({ username, token, firstName });
    setIsAuthenticated(true);
    const loginTime = new Date().getTime();
    const expiryTime = loginTime + 24 * 60 * 60 * 1000;

    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", rtoken);
    localStorage.setItem("loginTime", loginTime);
    localStorage.setItem("expiryTime", expiryTime);

    setLoggedOut(false);
    checkTokenExpiry();
  };

  const logout = () => {
    setCurrentUser(null);
    setIsAuthenticated(false);
    localStorage.clear();
    clearInterval(intervalId);
    // Broadcast logout event to other tabs
    localStorage.setItem("logout", Date.now());
    setLoggedOut(true);
    navigate("/");
  };

  useEffect(() => {
    checkTokensAndStartCountdown();
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "logout") {
        setCurrentUser(null);
        setIsAuthenticated(false);
        setLoggedOut(true);
        navigate("/");
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [navigate]);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
