import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Navbar from "../../components/Navbar/Navbar";

function OtpVerification() {
  const [otp, setOtp] = useState("");
  const [otpHash, setOtpHash] = useState("");
  const [otpPayload, setOtpPayload] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve OTP details from localStorage
    const storedData = JSON.parse(localStorage.getItem("otpData"));
    const storedUsername = localStorage.getItem("username");

    if (storedData && storedUsername) {
      setOtpHash(storedData.otp_hash);
      setOtpPayload(storedData.otp_payload);
      setUsername(storedUsername);
    } else {
      toast.error("OTP data not found. Please request a new OTP.");
      navigate("/forgotpassword"); // Redirect if there's no OTP data
    }
  }, [navigate]);

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://zola.technology/api/verify-otp/", {
        username,
        otp: otp,
        otp_hash: otpHash,
        otp_payload: otpPayload,
      });

      if (response.status === 200) {
        toast.success("OTP verified successfully!");
        // Redirect to forgot password page after successful verification
        navigate("/forgotpassword");
      } else if (response.status === 400){
        toast.error("Error verifying OTP. Invalid OTP");
      }else{
        toast.error("Error verifying OTP. Please try again.");
      }
    } catch (error) {
      toast.error("Error verifying OTP. Please try again.");
    }
  };

  return (
    <section className="flex items-center justify-center w-full min-h-screen p-6 sm:p-12 bg-gray-100">
      <Navbar/>

      <div className="w-full max-w-md sm:max-w-lg bg-white shadow-lg rounded-lg p-6 sm:p-8">
        <h2 className="text-xl sm:text-2xl font-bold text-center mb-4 sm:mb-6">
          Verify OTP
        </h2>
        <p className="text-center mb-4 text-gray-600">
          Enter the OTP sent to your email.
        </p>
        <form
          className="flex flex-col gap-4 sm:gap-6"
          onSubmit={handleVerifyOtp}
        >
          <input
            type="text"
            className="border rounded-md p-3 text-base bg-gray-50 focus:bg-white focus:border-gray-300 transition-colors"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
            type="submit"
          >
            Verify OTP
          </button>
        </form>
      </div>
      <ToastContainer />
    </section>
  );
}

export default OtpVerification;
