import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Zola from "./zola.jpg";

const industryDetails = {
  Manufacturing: "Involves the production of goods from raw materials through various processes. Examples: Automotive, electronics, textile, food and beverage manufacturing.",
  Technology: "Encompasses companies involved in the development and distribution of technology products and services. Examples: Software development, IT services, hardware production, internet services.",
  Healthcare: "Includes medical services, manufacturing of medical equipment, and pharmaceuticals. Examples: Hospitals, clinics, pharmaceutical companies, medical device manufacturers.",
  "Finance and Banking": "Involves managing money, investments, and financial transactions. Examples: Banks, insurance companies, investment firms, financial advisors.",
  "Retail and Consumer Goods": "Involves the sale of goods directly to consumers. Examples: Supermarkets, e-commerce platforms, fashion retailers, electronics stores.",
  Energy: "Covers the production and distribution of energy. Examples: Oil and gas, renewable energy, utilities.",
  "Transportation and Logistics": "Involves the movement of goods and people. Examples: Airlines, shipping companies, freight and logistics services.",
  "Real Estate": "Encompasses the buying, selling, and development of properties. Examples: Residential, commercial, and industrial real estate development and management.",
  Telecommunications: "Involves the transmission of information over distances. Examples: Mobile network operators, internet service providers, satellite communications.",
  "Hospitality and Tourism": "Includes services related to travel and accommodation. Examples: Hotels, restaurants, travel agencies, tourism services.",
  Education: "Covers institutions and services providing education and training. Examples: Schools, universities, online learning platforms, vocational training centers.",
  Agriculture: "Involves the production of food, fiber, and other goods through farming and forestry. Examples: Crop production, livestock farming, agribusiness companies.",
};

const StepTwo = ({ nextStep, handleChange, formData }) => {
  const { industryType, numberOfEmployees, businessIndustry, numberOfCards } = formData;

  const [isFormValid, setIsFormValid] = useState(false);

  const renderEmployeeAndCardFields = () => {
    if (industryType === "one_person" || industryType === "") {
      return null;
    }
    return (
      <div className="flex flex-col md:flex-row md:space-x-4 w-full">
        <div className="form-field flex-1">
          <label
            htmlFor="number-of-employees"
            className="mt-8 block text-sm font-medium text-gray-700"
          >
            Number of Employees <span className="text-red-500">*</span>
          </label>
          <input
            id="number-of-employees"
            type="text"
            name="numberOfEmployees"
            value={numberOfEmployees}
            onChange={handleChange("numberOfEmployees")}
            required
            className="mt-1 block w-full rounded-md border-gray-500 shadow-md focus:border-0 focus:ring-0"
          />
        </div>
        <div className="form-field flex-1">
          <label
            htmlFor="number-of-cards"
            className="mt-8 block text-sm font-medium text-gray-700"
          >
            Number of Cards <span className="text-red-500">*</span>
          </label>
          <input
            id="number-of-cards"
            type="text"
            name="numberOfCards"
            value={numberOfCards}
            onChange={handleChange("numberOfCards")}
            required
            className="mt-1 block w-full rounded-md border-gray-500 shadow-md focus:border-0 focus:ring-0"
          />
        </div>
      </div>
    );
  };

  const validateForm = () => {
    const isValid =
      industryType.trim() !== "" &&
      businessIndustry.trim() !== "" &&
      (industryType === "one_person" ||
        (industryType === "team" && numberOfEmployees.trim() !== "" && numberOfCards.trim() !== ""));

    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [industryType, numberOfEmployees, businessIndustry, numberOfCards]);

  const handleNextClick = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const { username } = userData;

    const data = {
      company_type: industryType,
      number_of_employees: industryType === "one_person" ? 1 : numberOfEmployees,
      industry_type: businessIndustry,
      number_of_cards: industryType === "one_person" ? 1 : numberOfCards,
    };

    try {
      const response = await fetch(`https://zola.technology/api/company/update/${username}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        nextStep();
      } else {
        console.error("Failed to update company data");
      }
    } catch (error) {
      console.error("An error occurred while updating company data", error);
    }
  };

  return (
    <section className="w-full p-6 flex flex-col items-center bg-white flex-1 md:ml-[75%] lg:ml-[50%]">
      <img src={Zola} alt="Zola Logo" className="w-24 h-24 object-cover rounded-full" />
      <h2 className="text-4xl font-normal text-gray-800 mt-4">Company Details</h2>
      <p className="text-xl mt-2 font-light text-gray-600 text-center">Please provide your company details</p>

      <div className="form-field w-full mt-6">
        <label
          htmlFor="industry-type"
          className="block text-sm font-medium text-gray-700"
        >
          Business Setting <span className="text-red-500">*</span>
        </label>
        <select
          id="industry-type"
          name="industryType"
          value={industryType}
          onChange={handleChange("industryType")}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        >
          <option value="">Select</option>
          <option value="one_person">Individual</option>
          <option value="team">Team</option>
        </select>
      </div>

      {renderEmployeeAndCardFields()}

      <div className="form-field w-full mt-6">
        <label
          htmlFor="business-industry"
          className="block text-sm font-medium text-gray-700"
        >
          Business Industry <span className="text-red-500">*</span>
        </label>
        <select
          id="business-industry"
          name="businessIndustry"
          value={businessIndustry}
          onChange={handleChange("businessIndustry")}
          required
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        >
          <option value="">Select</option>
          {Object.keys(industryDetails).map((industry) => (
            <option key={industry} value={industry}>
              {industry}
            </option>
          ))}
        </select>
      </div>

      {businessIndustry && (
        <div className="mt-4 p-4 bg-gray-100 rounded-lg shadow-inner w-full">
          <h3 className="text-xl font-medium text-gray-800">{businessIndustry}</h3>
          <p className="text-sm text-gray-600 mt-2">{industryDetails[businessIndustry]}</p>
        </div>
      )}

      <div className="flex justify-between mt-8 w-full">
        <button
          type="button"
          onClick={handleNextClick}
          disabled={!isFormValid}
          className={`px-4 py-2 w-full text-white text-sm font-medium rounded-md shadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
            isFormValid ? "bg-indigo-600 hover:bg-indigo-500" : "bg-black cursor-not-allowed"
          }`}
        >
          Next
        </button>
      </div>
    </section>
  );
};

StepTwo.propTypes = {
  nextStep: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    industryType: PropTypes.string.isRequired,
    numberOfEmployees: PropTypes.string,
    businessIndustry: PropTypes.string.isRequired,
    numberOfCards: PropTypes.string,
  }).isRequired,
};

export default StepTwo;
