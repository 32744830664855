import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import IconifyIcon from './IconifyIcon';
import Image from './Image';
import ProfileMenu from './ProfileMenu';
import { IoNotifications } from "react-icons/io5";
import ZolaLogo from "../sidebar/Layer_1.png";

const Topbar = ({ isClosing, mobileOpen, setMobileOpen }) => {
  const navigate = useNavigate()
  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  function handleClick() {
    // const navigate = useNavigate();
    navigate("/dashboard", { replace: true });
  }

  let userData = JSON.parse(localStorage.getItem('userData'));

  return (
    <div className="flex items-center justify-between lg:p-4 xs:p-8 md:p-4 lg:bg-white border-b border-[#EAEAEC] shadow-md dark:shadow-lg dark:bg-gray-900 dark:border-gray-700 xs:border-none md:border-none xs:bg-[#f5f8ff] md:bg-[#f5f8ff]">
      <div className="lg:flex items-center space-x-4 xs:hidden md:hidden">
        <div className="flex items-center space-x-2">
          <img
            src={userData.profile_image}
            alt="User Avatar"
            className="w-10 h-10 rounded-full object-cover border-[2px] border-[#0C4EF8]"
          />
          <div>
            <h2 className="text-lg font-semibold dark:text-white">
              Hello {userData.username}
            </h2>
            <p className="text-sm text-gray-500 dark:text-gray-300">
              Welcome to the Headquarters Dashboard!
            </p>
          </div>
        </div>
      </div>

      <div className="sm:flex items-center space-x-2 sm:px-4 lg:hidden">
        <img
          src={ZolaLogo}
          alt="Company Logo"
          className="md:w-15 md:h-10 xs:w-15 xs:h-8"
        />
      </div>

      <div className="lg:flex items-center space-x-4 xs:hidden md:hidden">
        <div className="flex items-center space-x-2 border border-gray-300 rounded-full p-2 bg-[#FAFCFF] dark:border-gray-700 dark:bg-gray-800">
          <IconifyIcon
            icon="eva:search-outline"
            className="text-[#343330] dark:text-gray-400 w-5 h-5"
          />
          <input
            type="text"
            placeholder=""
            className="w-10/12 focus:outline-none focus:ring-2 focus:ring-transparent bg-[#FAFCFF] dark:bg-gray-800 dark:text-white dark:focus:ring-blue-400"
          />
        </div>

        <button className="p-2 text-gray-700 dark:text-gray-300">
          <IoNotifications className="text-[#0C4EF8] h-5 w-5" />
        </button>

        <button className="px-4 py-2 bg-blue-600 text-white rounded-[12px] hover:bg-blue-700 transition shadow-md dark:bg-blue-500 dark:hover:bg-blue-600" onClick={()=>{handleClick()}}>
          Switch Dashboard
        </button>
      </div>
    </div>
  );
};

export default Topbar;
