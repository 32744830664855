import DataTable from "../Table/Table";
import { Link } from "react-router-dom";

const TeamManagement = () => {
  return (
    <div className="flex flex-col p-6 bg-white dark:bg-gray-900 min-h-screen">
      <div className="flex justify-end mb-4">
        <Link
          to="/dashboard/addemployee"
          className="bg-blue-600 text-white rounded px-4 py-2 hover:bg-blue-700 dark:hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
        >
          Add Employee
        </Link>
      </div>
      <DataTable />
    </div>
  );
};

export default TeamManagement;
