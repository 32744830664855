import React from 'react';
import { useLocation } from 'react-router-dom'; // To get the passed state from the Link
import { motion } from 'framer-motion';

function BlogDetails() {
  const location = useLocation();
  const blogPost = location.state; // Access the passed blog data

  if (!blogPost) {
    return <div className="text-center text-gray-700">Blog not found</div>;
  }

  return (
    <div className="mx-auto p-6 max-w-4xl mt-16 md:mt-0 lg:mt-0">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <motion.img
          src={blogPost.image}
          alt={blogPost.title}
          className="w-full h-96 object-cover"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        />
        <div className="p-6">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-4">{blogPost.title}</h1>
          <p className="text-sm text-gray-500 mb-6">
            {blogPost.date} by <span className="font-semibold">{blogPost.author}</span>
          </p>
          <p className="text-lg text-gray-700 leading-relaxed my-4">{blogPost.description}</p>
          <p className="text-lg text-gray-700 leading-relaxed">{blogPost.longDescription}</p>
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;
