import React from 'react';

const SkeletonCard = () => {
  return (
    <div className="animate-pulse flex flex-col space-y-4 p-4 border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 shadow-lg rounded-lg">
      <div className="h-32 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500 rounded"></div>
      <div className="h-6 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500 rounded w-3/4"></div>
      <div className="h-6 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500 rounded w-1/2"></div>
      <div className="h-6 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500 rounded w-1/4"></div>
    </div>
  );
};

export default SkeletonCard;