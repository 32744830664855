import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BranchContext } from "../../HQDashboard/context/BranchContext";

import {
  getLinkInteractions,
  listFeedbackWithoutParams,
  feedBackList,
} from "../../api";
import axios from "axios";
import { PiHandTapFill, PiUsersFill } from "react-icons/pi";
import { HiChartBar } from "react-icons/hi";
import cover from "../../HQDashboard/assets/cover.png";
import { FaExclamationTriangle, FaBuilding } from "react-icons/fa";
import { PiChatTeardropTextFill } from "react-icons/pi";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdOutlineSentimentSatisfied, MdSms } from "react-icons/md";
import { PiSmileyFill } from "react-icons/pi";
import star from "../assets/Star-Struck.png";
import smiley from "../assets/smiley.png";
import frown from "../assets/frown.png";
import angry from "../assets/angry.png";
import neutral from "../assets/neutral.png";
import { PiChatsFill } from "react-icons/pi";
import { TiArrowSortedDown } from "react-icons/ti";
import janet from "../assets/janet.png";
import { PiSealCheckFill } from "react-icons/pi";
import { FaStar } from "react-icons/fa";
import { PiThumbsUpFill } from "react-icons/pi";
import { PiThumbsDownFill } from "react-icons/pi";
import { PiHandPalmFill } from "react-icons/pi";
import { IoCheckmarkCircle } from "react-icons/io5";
import ReactApexChart from "react-apexcharts";
import { RiArrowUpSFill, RiChatFollowUpLine } from "react-icons/ri";
import {
  FcNegativeDynamic,
  FcNeutralTrading,
  FcPositiveDynamic,
} from "react-icons/fc";
import AverageSentiment from "./AverageSentimentCard";
const ReviewAnalytics2 = () => {
  // const [selectedTime, setSelectedTime] = useState("This Month");
  // const [isDropdownOpen, setDropdownOpen] = useState(false);
  // const timelines = ["This Month", "Last 3 Months", "Last 6 Months"];
  // const [selectedFeedbackTime, setSelectedFeedbackTime] =
  //   useState("This Month");
  // const [isFeedbackDropdownOpen, setFeedbackDropdownOpen] = useState(false);
  // const [selectedReviewsTime, setSelectedReviewsTime] = useState("This Month");
  // const [isReviewsDropdownOpen, setReviewsDropdownOpen] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState("month");
  const filterOptions = ["day", "week", "month", "year"];
  // const [isFilterDropdownOpen, setFilterDropdownOpen] = useState(false);

  const [performanceData, setPerformanceData] = useState([]);
  const [allEmployeesData, setAllEmployeesData] = useState(null);
  const [filteredFeedbackData, setFilteredFeedbackData] = useState(null);
  const [categorisedFeedbackData, setCategorisedFeedbackData] = useState(null);
  const currentPage = 1;
  const pageSize = 4;
  const [filteredTapsData, setFilteredTapsData] = useState(null); // New state for filtered taps
  const [googleReviews, setGoogleReviews] = useState(null);
  const [ratingData, setRatingData] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const companyType = userData.company_type;

  const [linkInteractions, setLinkInteractions] = useState(null);

  const [filterType, setFilterType] = useState("week");

  const [weeklyFeedbacks, setWeeklyFeedbacks] = useState(0);
  const [monthlyFeedbacks, setMonthlyFeedbacks] = useState(0);

  const [feedbackData, setFeedbackData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };

        setLoading(true);

        // Fetch all data in parallel
        const [
          performanceRes,
          allEmployeesRes,
          filteredFeedbackRes,
          categorisedFeedbackRes,
          filteredTapsRes, // New API call
          googleReviewsRes,
          ratingRes,
        ] = await Promise.all([
          axios.get(
            `https://zola.technology/api/company/employees/performance/get-filtered-performance/?filter=${selectedFilter}`,
            { headers }
          ),
          axios.get(`https://zola.technology/api/company/employees/get-all/`, {
            headers,
          }),
          axios.get(
            "https://zola.technology/api/company/feedback/get-filtered-feedback/",
            { headers }
          ),
          axios.get(
            "https://zola.technology/api/company/feedback/list-categorised/",
            { headers }
          ),
          axios.get(
            "https://zola.technology/api/company/employees/performance/filter/get-filtered-taps/",
            { headers }
          ),
          axios.get("https://zola.technology/api/company/reviews/list", {
            headers,
          }),
          axios.get(
            "https://zola.technology/api/company/analytics/performance-and-rating/get/",
            {
              headers,
            }
          ),
        ]);

        // Set state for each response
        setPerformanceData(performanceRes?.data.data);
        setAllEmployeesData(allEmployeesRes?.data.data);
        setFilteredFeedbackData(filteredFeedbackRes?.data);
        setCategorisedFeedbackData(categorisedFeedbackRes?.data);
        setFilteredTapsData(filteredTapsRes?.data); // Set data for new endpoint
        setGoogleReviews(googleReviewsRes?.data);
        setRatingData(ratingRes?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedFilter, currentPage, pageSize]);

  // Fetch link interactions data
  useEffect(() => {
    const fetchLinkInteractions = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const data = await getLinkInteractions(token);
        console.log(data);
        setLinkInteractions(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchLinkInteractions();
  }, []);

  useEffect(() => {
    const fetchSortedFeedback = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const response = await axios.get(
          "https://zola.technology/api/company/feedback/get-filtered-feedback/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              filter: "week", // Default filter type
            },
          }
        );

        const feedbackData = response.data;

        // Calculate the total feedback count
        const total = feedbackData.reduce(
          (sum, item) => sum + item.feedback.length,
          0
        );

        setWeeklyFeedbacks(total);
        console.log(`Total feedbacks for week:`, total);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchSortedFeedback();
  }, []);

  useEffect(() => {
    const fetchMonthlyFeedback = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }
        const response = await axios.get(
          "https://zola.technology/api/company/feedback/get-filtered-feedback/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              filter: "month",
            },
          }
        );

        const feedbackData = response.data;

        // Calculate the total feedback count
        const total = feedbackData.reduce(
          (sum, item) => sum + item.feedback.length,
          0
        );

        setMonthlyFeedbacks(total);
        console.log(`Total feedbacks for month:`, total);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMonthlyFeedback();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await feedBackList();
        console.log(data);
        setFeedbackData(data.categorized_feedbacks);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  // Calculate the number of taps and percentage change for link interactions
  const totalTaps = linkInteractions?.total_filtered_taps || 0;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data: : {error.message}</div>;
  }

  const resolvedFeedback =
    filteredFeedbackData[0]?.feedback?.filter(
      (feedback) => feedback.resolved === true
    ) || [];
  const unresolvedFeedback =
    filteredFeedbackData[0]?.feedback?.filter(
      (feedback) => feedback.resolved === false
    ) || [];

  const totalRatings =
    ratingData.rating_of_1_count +
    ratingData.rating_of_2_count +
    ratingData.rating_of_3_count +
    ratingData.rating_of_4_count +
    ratingData.rating_of_5_count;

  const ratings = [
    { count: ratingData.rating_of_5_count, label: 5, color: "#4ADE80" },
    { count: ratingData.rating_of_4_count, label: 4, color: "#DC78FF" },
    { count: ratingData.rating_of_3_count, label: 3, color: "#F5C148" },
    { count: ratingData.rating_of_2_count, label: 2, color: "#F5C148" },
    { count: ratingData.rating_of_1_count, label: 1, color: "#E6283B" },
  ];

  // Transform the data for ApexCharts
  const chartData = performanceData.map((item) => ({
    x: item.employee.username,
    y: item.performance_score,
  }));

  const chartOptions = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "category",
      categories: performanceData.map((item) => item.employee.username),
    },
    yaxis: {
      title: {
        text: "Performance Score",
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
  };

  const feedbackCount =
    filterType === "week" ? weeklyFeedbacks : monthlyFeedbacks;

  const {
    rating_of_1 = [],
    rating_of_2 = [],
    rating_of_3 = [],
  } = feedbackData || {};

  // Filter feedbacks with resolved status as false
  const unresolvedRating1 = rating_of_1.filter(
    (feedback) => !feedback.resolved
  );
  const unresolvedRating2 = rating_of_2.filter(
    (feedback) => !feedback.resolved
  );
  const unresolvedRating3 = rating_of_3.filter(
    (feedback) => !feedback.resolved
  );

  // Check if there are no follow-up data for 1, 2, and 3-star ratings
  const noFollowUpData =
    unresolvedRating1.length === 0 &&
    unresolvedRating2.length === 0 &&
    unresolvedRating3.length === 0;

  return (
    <div className="w-full space-y-4 p-2">
      <h1 className="text-[24px] text-[#282A3E] font-medium mb-4">
        Branch Dashboard
      </h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="bg-white rounded-[12px]">
          <div className="border-b p-4 flex justify-between">
            <div className="flex space-x-2">
              <div className=" border flex justify-center items-center bg-white text-[#0C4EF8] rounded-[4px] w-[32px] h-[32px]">
                <PiChatsFill size={24} />
              </div>
              <p className="text-[20px] text-[#282A3E] font-medium">Feedback</p>
            </div>
          </div>

          <div className="p-4">
            <p className="text-[#282A3E] text-[40px] font-semibold">
              {categorisedFeedbackData.total_feedback}
            </p>
            <div className="grid grid-cols-2 gap-12 py-2">
              <div className="space-y-4">
                <div className="flex space-x-2 items-center">
                  <PiThumbsUpFill className="w-[28px] text-[#00BA34] h-[25px]" />
                  <div>
                    <span className="text-[#6F707E] text-[14px]">Positive</span>
                    <p className="text-[#282A3E] font-semibold text-[20px]">
                      {categorisedFeedbackData.positive_feedback_percentage}%
                    </p>
                  </div>
                </div>
                <div className="w-full bg-[#EAEAEC] rounded-[27px]">
                  <div
                    className="bg-[#00BA34] h-[8px] rounded-[27px]"
                    style={{
                      width: `${categorisedFeedbackData.positive_feedback_percentage}%`,
                    }}
                  ></div>
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex space-x-2 items-center">
                  <PiThumbsDownFill className="w-[28px] text-[#E6283B] h-[25px]" />
                  <div>
                    <span className="text-[#6F707E] text-[14px]">Negative</span>
                    <p className="text-[#282A3E] font-semibold text-[20px]">
                      {categorisedFeedbackData.negative_feedback_percentage}%
                    </p>
                  </div>
                </div>
                <div className="w-full bg-[#EAEAEC] rounded-[27px]">
                  <div
                    className="bg-[#E6283B] h-[8px] rounded-[27px]"
                    style={{
                      width: `${categorisedFeedbackData.negative_feedback_percentage}%`,
                    }}
                  ></div>
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex space-x-2 items-center">
                  <PiHandPalmFill
                    className="text-[#FFBC07] w-[26px] h-[30px]"
                    size={24}
                  />
                  <div>
                    <span className="text-[#6F707E] text-[14px]">Neutral</span>
                    <p className="text-[#282A3E] font-semibold text-[20px]">
                      {categorisedFeedbackData.neutral_feedback_percentage}%
                    </p>
                  </div>
                </div>
                <div className="w-full bg-[#EAEAEC] rounded-[27px]">
                  <div
                    className="bg-[#FFBC07] h-[8px] rounded-[27px]"
                    style={{
                      width: `${categorisedFeedbackData.neutral_feedback_percentage}%`,
                    }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-4 mt-4">
              <div className="flex items-center px-4 py-2 bg-blue-100 rounded-full text-blue-600 font-medium">
                <div className="w-4 h-4 bg-blue-600 rounded-full flex items-center justify-center text-white mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                Responded:{" "}
                {resolvedFeedback.length > 0
                  ? resolvedFeedback.length
                  : "No responses yet"}
              </div>

              <div className="flex items-center px-4 py-2 bg-red-100 rounded-full text-red-600 font-medium">
                <div className="w-4 h-4 bg-red-600 rounded-full flex items-center justify-center text-white mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                No Response:{" "}
                {unresolvedFeedback.length > 0
                  ? unresolvedFeedback.length
                  : "No pending responses"}
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <div className="bg-white rounded-[12px] w-full mx-auto">
            <div className="border-b p-4 flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <div className="border bg-white text-[#F5C148] rounded-[4px] w-[32px] h-[32px] flex items-center justify-center">
                  <FaStar size={20} />
                </div>
                <p className="text-[20px] text-[#282A3E] font-medium">
                  Reviews
                </p>
              </div>
            </div>
            <div className="p-8 flex items-center text-center space-y-6">
              <div className="w-full">
                <p className="text-[#374151] text-[16px] font-medium">
                  Total Reviews
                </p>
                <p className="text-[#282A3E] text-[36px] font-semibold">
                  {googleReviews?.reviews}
                </p>
              </div>
              <div className="w-full">
                <p className="text-[#374151] text-[16px] font-medium">
                  Average Rating
                </p>
                <h1 className="text-[#282A3E] text-[36px] font-semibold">
                  {googleReviews?.rating.toFixed(1)}
                </h1>
                <div className="flex justify-center space-x-1">
                  {[...Array(5)].map((_, index) => (
                    <FaStar
                      key={index}
                      size={24}
                      className={
                        index < Math.floor(googleReviews?.rating)
                          ? "text-[#F3BB21]" // Full stars
                          : index === Math.floor(googleReviews?.rating) &&
                            googleReviews?.rating % 1 >= 0.5
                          ? "text-[#F3BB21] opacity-50" // Half star
                          : "text-[#EAEAEC]" // Empty stars
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 rounded-lg space-y-2 bg-white w-full">
            <div className="flex items-center space-x-2 border-b py-4 px-10">
              <div className="border-2 bg-white text-[#282A3E] rounded-[4px] w-[32px] h-[32px] flex items-center justify-center">
                <FaStar size={20} />
              </div>
              <p className="text-[20px] text-[#282A3E] font-medium">
                Feedback Distribution
              </p>
            </div>
            {ratings.map((rating, index) => (
              <div key={index} className="flex space-x-3 items-center">
                <p className="text-[#282A3E] text-[14px] font-semibold">
                  {rating.label}
                </p>
                <div className="w-full bg-gray-200 rounded-[31px] h-[12px]">
                  <div
                    className="h-[12px] rounded-[31px]"
                    style={{
                      backgroundColor: rating.color,
                      width: `${(rating.count / totalRatings) * 100}%`,
                    }}
                  ></div>
                </div>
                <p className="text-[#6F707E] font-semibold">{rating.count}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3  gap-3 ">
        <div className="group bg-white rounded-lg  lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-white text-[#A577FF] p-2 rounded-lg border">
              <PiUsersFill size={24} />
            </div>
            <p className="text-[#6F707E] font-semibold text-[16px] group-hover:text-white">
              Total <br />
              Employees
            </p>
          </div>
          <p className="text-3xl font-bold text-[#282A3E] group-hover:text-white">
            {allEmployeesData.length}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>

        <div className="group bg-white xs:h-[150px]  rounded-lg lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-white text-red-600 p-2 rounded-lg border">
              <FaExclamationTriangle size={24} />
            </div>
            <p className="text-[#6F707E] font-semibold text-[16px] group-hover:text-white">
              Total <br />
              Feedbacks
            </p>
          </div>
          <p className="text-3xl font-bold text-[#282A3E] group-hover:text-white">
            {categorisedFeedbackData.total_feedback}
          </p>
          {/* <div className="flex w-80 justify-evenly my-3.5">
            <p className="px-2 rounded-xl bg-green-100 text-green-400 group-hover:bg-green-200 group-hover:text-green-600">
              Positive: {categorisedFeedbackData.positive_feedback_count}
            </p>
            <p className="px-2 rounded-xl bg-yellow-100 text-yellow-400 group-hover:bg-yellow-200 group-hover:text-yellow-600">
              Neutral: {categorisedFeedbackData.neutral_feedback_count}
            </p>
            <p className="px-2 rounded-xl bg-red-100 text-red-400 group-hover:bg-red-200 group-hover:text-red-600">
              Negative: {categorisedFeedbackData.negative_feedback_count}
            </p>
          </div> */}
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>

        <div className="group bg-white xs:h-[150px]  rounded-lg lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-white text-[#00C159] p-2 rounded-lg border">
              <HiChartBar size={24} />
            </div>
            <p className="text-[#6F707E] font-semibold text-[16px] group-hover:text-white">
              Average <br />
              Performance
            </p>
          </div>
          <p className="text-3xl font-bold text-[#282A3E] group-hover:text-white">
            {ratingData.performance_score}%
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>

        <div className="group bg-white xs:h-[150px]  rounded-lg lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-white text-[#00C159] p-2 rounded-lg border">
              <FcPositiveDynamic size={24} />
            </div>
            <p className="text-[#6F707E] font-semibold text-[16px] group-hover:text-white">
              Positive <br />
              Feedbacks
            </p>
          </div>
          <p className="text-3xl font-bold text-[#00C159] group-hover:text-white">
            {categorisedFeedbackData.positive_feedback_count}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>
        <div className="group bg-white xs:h-[150px]  rounded-lg lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-white text-[#00C159] p-2 rounded-lg border">
              <FcNeutralTrading size={24} />
            </div>
            <p className="text-[#6F707E] font-semibold text-[16px] group-hover:text-white">
              Neutral <br />
              Feedbacks
            </p>
          </div>
          <p className="text-3xl font-bold text-[#d0e26c] group-hover:text-white">
            {categorisedFeedbackData.neutral_feedback_count}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>
        <div className="group bg-white xs:h-[150px]  rounded-lg lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-white text-[#e23737] p-2 rounded-lg border">
              <FcNegativeDynamic size={24} />
            </div>
            <p className="text-[#6F707E] font-semibold text-[16px] group-hover:text-white">
              Negative <br />
              Feedbacks
            </p>
          </div>
          <p className="text-3xl font-bold text-[#e23737] group-hover:text-white">
            {categorisedFeedbackData.negative_feedback_count}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>

        <div className="group bg-white xs:h-[150px]  rounded-lg lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-white text-[#00C159] p-2 rounded-lg border">
              <PiHandTapFill size={24} />
            </div>
            <p className="text-[#6F707E] font-semibold text-[16px] group-hover:text-white">
              Link
              <br />
              Engagements
            </p>
          </div>
          <p className="text-3xl font-bold text-[#282A3E] group-hover:text-white">
            {totalTaps}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>
        <div>
          <AverageSentiment />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white dark:bg-gray-800 dark:bg-opacity-80 dark:backdrop-filter dark:backdrop-blur-lg border border-gray-200 dark:border-gray-700 rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">
            Feedback Statistics
          </h2>
          <div className="flex gap-4 mb-6">
            <button
              onClick={() => handleFilterChange("week")}
              className={`py-2 px-6 rounded-lg text-lg font-semibold transition-transform transform ${
                filterType === "week"
                  ? "bg-gradient-to-r from-blue-400 to-blue-600 text-white shadow-lg"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              Weekly
            </button>
            <button
              onClick={() => handleFilterChange("month")}
              className={`py-2 px-6 rounded-lg text-lg font-semibold transition-transform transform ${
                filterType === "month"
                  ? "bg-gradient-to-r from-blue-400 to-blue-600 text-white shadow-lg"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              Monthly
            </button>
          </div>
          <div className="flex items-center gap-4">
            <MdOutlineSentimentSatisfied className="text-4xl text-green-500" />
            <p className="text-xl font-medium text-gray-800 dark:text-white">
              {filterType.charAt(0).toUpperCase() + filterType.slice(1)}{" "}
              Feedbacks: {feedbackCount}
            </p>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 dark:bg-opacity-80 dark:backdrop-filter dark:backdrop-blur-lg border border-gray-200 dark:border-gray-700 rounded-lg p-6">
          <div className="flex items-center gap-3 mb-4">
            <div className="bg-white text-[#e23737] p-2 rounded-lg border">
              <RiChatFollowUpLine size={24} />
            </div>
            <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">
            Follow Ups
          </h2>
          </div>
          <div className="flex flex-col gap-4">
            {noFollowUpData ? (
              <p className="text-black dark:text-white">
                No follow-up data available.
              </p>
            ) : (
              <>
                {unresolvedRating1.length > 0 && (
                  <p className="text-black dark:text-white">
                    You have {unresolvedRating1.length} feedbacks with 1-star
                    rating that needs follow-up.
                  </p>
                )}
                {unresolvedRating2.length > 0 && (
                  <p className="text-black dark:text-white">
                    You have {unresolvedRating2.length} feedbacks with 2-star
                    rating that needs follow-up.
                  </p>
                )}
                {unresolvedRating3.length > 0 && (
                  <p className="text-black dark:text-white">
                    You have {unresolvedRating3.length} feedbacks with 3-star
                    rating that needs follow-up.
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* <div className="grid grid-cols-2  gap-4 ">
        
        <div className="group1 bg-white rounded-lg  p-4 flex flex-col justify-between items-start space-y-4 transition transform ">
              <div className="flex items-center gap-3">
                <div className="bg-[#FFB11A]  text-white  p-2 rounded-lg">
                  <PiChatTeardropTextFill size={24} />
                </div>
                <p className="text-[#374151] font-semibold text-sm ">
                  Total <br />
                  Messages
                </p>
                <p className="text-3xl font-bold text-[#282A3E]  ml-9">
                125K
              </p>
              </div>

              <div className="flex items-center gap-3 ">
                <div>
                  <p className="text-3xl font-bold text-[#282A3E] ">
                    100K
                  </p>
                  <div className="flex">
                  <div className="bg-white  text-[#1FAF38] mr-2 rounded-lg">
                    <IoLogoWhatsapp size={24} />
                  </div>
                  <p className="text-[#374151] font-semibold text-sm  ">
                    Whatsapp
                  </p>
                  </div>
                </div>

                <div className="ml-12">
                  <p className="text-3xl font-bold text-[#282A3E]  ml-2">
                    25K
                  </p>
                  <div className="flex ">
                  <div className="bg-white  text-[#0C4EF8]  p-2 rounded-lg">
                    <MdSms size={24} />
                  </div>
                  <p className="text-[#374151] font-semibold text-sm  mt-2">
                    SMS
                  </p>
                  </div>
                
                </div>
                
              </div>
              
             
            </div>

           

      
        <div className="group1 bg-white rounded-lg  p-4 flex flex-col justify-between items-start space-y-4 transition transform ">
          <div className="grid grid-cols-3 gap-5">
            <div className="space-y-2">
              <div className="flex">
                <div className="bg-[#DC78FF] flex justify-center text-white mr-2 rounded-[4px] p-2 w-[43px] h-[43px]">
                <PiSmileyFill size={24}/>

                </div>
                <p className="text-[#374151] text-[16px] font-semibold">Overall <br/>
                Sentiment</p>
              </div>

              <div className="border rounded-[13px] space-y-2 p-2">
                <div className="flex">
                  <p className="text-[12px] text-[#6F707E] font-semibold">Average <br/> sentiment</p>
                  <p className="text-[#00C159] text-[16px] font-medium">+25%</p>

                </div>
                <div className="flex space-x-2">
                  <img src={star} alt="star-struck" className="w-8 h-8"/>
                  <p className="text-[#282A3E] font-semibold text-[20px]">Elated</p>

                </div>

              </div>
            </div>

            <div className="mt-[80px] space-y-2">
              <div className="border flex px-2 py-1 rounded-[13px] space-x-2">
                <img src={smiley} alt="happy-face" className="w-[20px] h-[20px]"/>
                <p className="text-[#282A3E] text-[14px] font-semibold">Happy</p>
                <p className="text-[#00C159]">15%</p>


              </div>
              <div className="border flex px-2 py-1 rounded-[13px] space-x-2">
                <img src={frown} alt="sad-face" className="w-[20px] h-[20px]"/>
                <p className="text-[#282A3E] text-[14px] font-semibold">Sad</p>
                <p className="text-[#00C159]">15%</p>



              </div>
            

            </div>

            <div className="mt-[80px] space-y-2">
              <div className="border flex px-2 py-1 rounded-[13px] space-x-2">
                <img src={neutral} alt="neutral-face" className="w-[20px] h-[20px]"/>
                <p className="text-[#282A3E] text-[14px] font-semibold">Neutral</p>
                <p className="text-[#00C159]">15%</p>


              </div>
              <div className="border flex px-2 py-1 rounded-[13px] space-x-2">
                <img src={angry} alt="angry-face" className="w-[20px] h-[20px]"/>
                <p className="text-[#282A3E] text-[14px] font-semibold">Angry</p>
                <p className="text-[#C10000]">85%</p>



              </div>
            

            </div>


          </div>
          
        </div>

        

      </div> */}
      {/* render if company type is team */}
      {companyType === "team" ? (
        <div className="bg-white  rounded-[21px] w-full">
          <div className="border-b p-4 flex justify-between">
            <div className="flex space-x-2">
              <div className=" border flex justify-center items-center bg-white text-[#0C4EF8] rounded-[4px] w-[32px] h-[32px]">
                <PiChatsFill size={24} />
              </div>
              <p className="text-[20px] text-[#282A3E] font-medium">
                Employee ratings
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4 md:p-8">
            {/* Employee Stats */}
            <div>
              <p className="text-4xl text-[#282A3E] font-semibold mb-4">
                {filteredTapsData.total_filtered_taps} taps
              </p>
              <div className="flex flex-col gap-6">
                <div>
                  <div className="bg-[#F5F8FF] rounded-[10px] px-2">
                    <p className="text-[20px] text-[#282A3E]">Employees</p>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    {allEmployeesData.slice(0, 2).map((employee, index) => (
                      <div
                        key={index}
                        className="border rounded-[16px] p-4 space-y-2"
                      >
                        <div className="flex space-x-2">
                          <div className="w-[49px] h-[49px] flex items-center justify-center bg-[#282A3E] rounded-full text-white font-bold text-[16px]">
                            {employee.employee.name
                              .split(" ")
                              .slice(0, 2) // Ensure only the first two words are considered
                              .map((n) => n[0].toUpperCase()) // Get first letter of each part and make it uppercase
                              .join("") ||
                              employee.employee.name[0].toUpperCase()}{" "}
                          </div>
                          <p className="text-[16px] font-medium text-[#282A3E]">
                            {employee.employee.name.includes(" ") ? (
                              <>
                                {employee.employee.name.split(" ")[0]} <br />
                                {employee.employee.name.split(" ")[1]}
                              </>
                            ) : (
                              employee.employee.name
                            )}
                          </p>
                        </div>

                        {employee.isTopEmployee && (
                          <div className="flex bg-[#E7EDFE] rounded-[38px] px-4 w-10/12">
                            <PiSealCheckFill className="text-[#0C4EF8] mt-[3px]" />
                            <p className="text-[#282A3E]">Top Employee</p>
                          </div>
                        )}

                        <div className="flex space-x-4">
                          <div className="flex">
                            <p className="text-[20px] text-[#282A3E] font-semibold">
                              {employee.employee.number_of_taps}
                            </p>
                            <span className="text-[14px] font-semibold text-[#282A3E] mt-[4px]">
                              taps
                            </span>
                          </div>

                          <div className="flex space-x-2">
                            <p className="text-[20px] font-semibold text-[#282A3E]">
                              {employee.average_rating}
                            </p>
                            <FaStar className="mt-[3px] w-[20px] h-[20px] text-[#F3BB21]" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <div className="bg-[#F5F8FF] rounded-[10px] px-2">
                    <p className="text-[20px] text-[#282A3E]">Employees</p>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    {allEmployeesData.slice(2, 4).map((employee, index) => (
                      <div
                        key={index}
                        className="border rounded-[16px] p-4 space-y-2"
                      >
                        <div className="flex space-x-2">
                          <div className="w-[49px] h-[49px] flex items-center justify-center bg-[#282A3E] rounded-full text-white font-bold text-[16px]">
                            {employee.employee.name
                              .split(" ")
                              .slice(0, 2) // Ensure only the first two words are considered
                              .map((n) => n[0].toUpperCase()) // Get first letter of each part and make it uppercase
                              .join("") ||
                              employee.employee.name[0].toUpperCase()}{" "}
                          </div>
                          <p className="text-[16px] font-medium text-[#282A3E]">
                            {employee.employee.name.includes(" ") ? (
                              <>
                                {employee.employee.name.split(" ")[0]} <br />
                                {employee.employee.name.split(" ")[1]}
                              </>
                            ) : (
                              employee.employee.name
                            )}
                          </p>
                        </div>

                        {employee.isTopEmployee && (
                          <div className="flex bg-[#E7EDFE] rounded-[38px] px-4 w-10/12">
                            <PiSealCheckFill className="text-[#0C4EF8] mt-[3px]" />
                            <p className="text-[#282A3E]">Top Employee</p>
                          </div>
                        )}

                        <div className="flex space-x-4">
                          <div className="flex">
                            <p className="text-[20px] text-[#282A3E] font-semibold">
                              {employee.employee.number_of_taps}
                            </p>
                            <span className="text-[14px] font-semibold text-[#282A3E] mt-[4px]">
                              taps
                            </span>
                          </div>

                          <div className="flex space-x-2">
                            <p className="text-[20px] font-semibold text-[#282A3E]">
                              {employee.average_rating}
                            </p>
                            <FaStar className="mt-[3px] w-[20px] h-[20px] text-[#F3BB21]" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Employees Chart */}
            <div>
              <div className="flex justify-between">
                <div className="flex items-center font-medium space-x-2">
                  <p className="text-[#292D32]">
                    Employee <br /> Overview
                  </p>
                  <div className="text-[#00C159] flex">
                    <RiArrowUpSFill size={24} />
                  </div>
                </div>

                <div className="relative">
                  <select
                    className="flex space-x-2 border rounded-[5px] justify-center items-center p-2 text-[14px] text-[#181818] font-light"
                    value={selectedFilter}
                    onChange={()=>{(event) => {
                      setSelectedFilter(event.target.value);
                    }}}
                  >
                    {filterOptions.map((filterOption) => (
                      <option
                        key={filterOption}
                        value={filterOption}
                        className="text-gray-700"
                      >
                        {filterOption}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <style>
                  {`
                    .apexcharts-toolbar {
                      display: none;
                    }
                  `}
                </style>
                <ReactApexChart
                  options={chartOptions}
                  series={[{ name: "Performance Score", data: chartData }]}
                  type="area"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReviewAnalytics2;
