import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const Image = ({ sx, ...rest }) => {
  return <Box component="img" sx={sx} {...rest} />;
};

Image.propTypes = {
  sx: PropTypes.object,
};

export default Image;
