import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { listReviews } from "../../api";

const useReviewsData = () => {
  const [reviews, setReviews] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalReviewsToday, setTotalReviewsToday] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [visitorData, setVisitorData] = useState({ start: 0, end: 0 });
  const [weeklyData, setWeeklyData] = useState([]);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser?.token) {
      setLoading(false);
      return;
    }

    const fetchReviews = async () => {
      try {
        const token = currentUser.token;
        const reviewData = await listReviews(token);
        const { reviews_list } = reviewData;

        if (!Array.isArray(reviews_list)) {
          throw new Error("Invalid data format: reviews_list is not an array");
        }

        const formattedData = reviews_list.map((review, index) => {
          const insertionDate = new Date(review.date);
          const month = insertionDate.getMonth();
          const year = insertionDate.getFullYear();
          const day = insertionDate.getDay();
          return {
            id: index + 1,
            userName: review.author_name,
            rating: review.rating,
            review: review.text || "No review text provided.",
            insertionMonth: month,
            insertionYear: year,
            insertionDay: day,
            userImage: review.author_image || "default_image_url",
            insertionDate,
          };
        });

        setReviews(formattedData);
        setTotalReviews(reviewData.reviews);
        setRating(reviewData.rating);
        calculateStatistics(formattedData);
        setWeeklyData(calculateWeeklyData(formattedData));
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setReviews([]);
      } finally {
        setLoading(false);
      }
    };

    const calculateStatistics = (reviews) => {
      const today = new Date().getDay();
      const dailyReviews = reviews.filter((review) => review.insertionDay === today);
      const yesterdayReviews = reviews.filter((review) => review.insertionDay === (today === 0 ? 6 : today - 1));
      const totalReviewsToday = dailyReviews.length;
      const totalReviewsYesterday = yesterdayReviews.length;
      const change = totalReviewsYesterday === 0 ? 0 : ((totalReviewsToday - totalReviewsYesterday) / totalReviewsYesterday) * 100;

      setTotalReviewsToday(totalReviewsToday);
      setPercentageChange(change);

      const visitorData = {
        start: totalReviewsYesterday === 0 ? 0 : (totalReviewsYesterday / reviews.length) * 100,
        end: (totalReviewsToday / reviews.length) * 100,
      };

      setVisitorData(visitorData);
    };

    const calculateWeeklyData = (reviews) => {
      const currentDate = new Date();
      const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
      const endOfWeek = new Date(currentDate.setDate(startOfWeek.getDate() + 6));

      const weekData = new Array(7).fill(0).map((_, i) => ({ day: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][i], black: 0 }));

      reviews.forEach(review => {
        const reviewDate = new Date(review.insertionDate);
        if (reviewDate >= startOfWeek && reviewDate <= endOfWeek) {
          const reviewDay = reviewDate.getDay();
          weekData[reviewDay].black++;
        }
      });

      return weekData;
    };

    fetchReviews();
  }, [currentUser?.token]);

  return { reviews, rating, loading, totalReviews, totalReviewsToday, percentageChange, visitorData, weeklyData };
};

export default useReviewsData;
