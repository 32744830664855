import React from 'react';
import {
  FaExclamationTriangle,
  FaUsers,
  FaCommentDots,
  FaMoneyBillWave,
} from "react-icons/fa";
import { HiChartBar } from "react-icons/hi";
import { RiBuildingFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';

function BranchGrid({ paginatedBranches }) {
  const navigate = useNavigate();

  const handleDetailsClick = (branchId) => {
    navigate(`/hqdashboard/branchdashboard`);
    console.log(`/${branchId}`);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {paginatedBranches.map((branch) => (
        <div key={branch.id} className="bg-white p-4 shadow rounded-lg flex flex-col">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <span className='text-blue-600 mr-4'><RiBuildingFill size={30}/></span>
            <span className='text-[24px]'>{branch.name}</span>
          </h3>
          <p className='flex items-center mt-2'>
            <span className='mr-2'><FaUsers/></span>
            People: {branch.employees.toLocaleString()}
          </p>
          <p className='flex items-center mt-2'>
            <span className='mr-2'><HiChartBar size={16}/></span>
            Performance: {branch.performance}%
          </p>
          <p className='flex items-center mt-2'>
            <span className='mr-2'><FaExclamationTriangle size={16}/></span>
            Issues: <span className="bg-green-200 text-green-500 px-2 py-1 rounded-lg">
              Resolved {branch.issues.resolved}
            </span>, 
            <span className='bg-red-200 text-red-500 px-2 py-1 rounded-lg'>
              Pending {branch.issues.pending}
            </span>
          </p>
          
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition mt-auto"
            onClick={() => handleDetailsClick(branch.id)} // Handle click
          >
            Details
          </button>
        </div>
      ))}
    </div>
  );
}

export default BranchGrid;
