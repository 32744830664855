import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useRating } from "../../context/RatingContext";

const incrementTapCount = async (company_username, employee_username) => {
  try {
    const response = await fetch(
      `https://zola.technology/api/company/employees/update/${company_username}/${employee_username}/increment-taps/`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      console.log("Tap count incremented successfully");
    } else {
      console.error("Failed to increment tap count");
    }
  } catch (error) {
    console.error("Error while sending tap data to backend", error);
  }
};

const shortenCompanyName = (fullCompanyName) => {
  let mainName = fullCompanyName.split(" - ")[0];

  if (mainName === fullCompanyName) {
    mainName = fullCompanyName.split(",")[0];
  }

  return mainName;
};

function Emoji() {
  const { company_username, employee_username } = useParams();
  const { state } = useLocation();
  const { feedback, employeeName } = state || {}; // Destructure state values
  const [companyName, setCompanyName] = useState("");
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [profileImage, setProfileImage] = useState(""); // State for profile image
  const [imageLoading, setImageLoading] = useState(true);
  const navigate = useNavigate();
  const { employeeRating } = useRating()

  console.log(employeeRating)

  useEffect(() => {
    const fetchBusinessName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/get/${company_username}`
        );
        const preferredName = response.data.preferred_business_name;
        setPreferredBusinessName(preferredName);
        const fullCompanyName = response.data.company_name;
        const shortenedName = shortenCompanyName(fullCompanyName);
        setCompanyName(shortenedName);
        setProfileImage(response.data.profile_image); // Store profile image URL
      } catch (error) {
        console.error("Error fetching company profile:", error);
      }
    };

    fetchBusinessName();
  }, [company_username]);

  const handleEmojiClick = async (emoji) => {
    incrementTapCount(company_username, employee_username);

    // Use the retrieved rating, feedback, and employeeName values
    const emojiEncoded = encodeURIComponent(emoji);
    const ratingEncoded = encodeURIComponent(employeeRating);
    const feedbackEncoded = encodeURIComponent(feedback);
    const employeeNameEncoded = encodeURIComponent(employeeName);

    // Determine which page to navigate to based on emoji
    if (emoji === "angry" || emoji === "sad") {
      navigate(
        `/negative-review-prompt/${encodeURIComponent(
          company_username
        )}/${encodeURIComponent(
          employee_username
        )}/${emojiEncoded}?rating=${ratingEncoded}&feedback=${feedbackEncoded}&employeeName=${employeeNameEncoded}`
      );
    } else if (emoji === "neutral" || emoji === "happy" || emoji === "love") {
      navigate(
        `/review-prompt/${encodeURIComponent(
          company_username
        )}/${encodeURIComponent(
          employee_username
        )}/${emojiEncoded}?rating=${ratingEncoded}&feedback=${feedbackEncoded}&employeeName=${employeeNameEncoded}`
      );
    }
  };

  return (
    <div className="min-h-screen relative flex flex-col items-center justify-center bg-white p-6">
      {/* Profile Image */}
      <div className="relative">
        {imageLoading && (
          <div className="w-24 h-24 bg-gray-200 rounded-full mb-4 animate-pulse"></div>
        )}
        {profileImage && (
          <img
            src={profileImage}
            alt="Company Profile"
            className={`w-24 h-24 rounded-full mb-4 border-4 border-blue-300 shadow-lg transition-opacity duration-500 ${
              imageLoading ? "opacity-0" : "opacity-100"
            }`}
            onLoad={() => setImageLoading(false)} // Handle image load
            onError={() => setImageLoading(false)} // Handle image error
          />
        )}
      </div>
      <p className="text-center font-serif text-4xl font-bold text-black mb-8">
        {preferredBusinessName || companyName}
      </p>
      <div className=" max-w-sm w-full text-center">
        <h2 className="text-2xl font-semibold mb-6">
          How was your experience today?
        </h2>
        <div className="flex justify-around">
          <span
            className="text-4xl cursor-pointer hover:text-red-600 transition-colors duration-300"
            onClick={() => handleEmojiClick("angry")}
          >
            😡
          </span>
          <span
            className="text-4xl cursor-pointer hover:text-blue-600 transition-colors duration-300"
            onClick={() => handleEmojiClick("sad")}
          >
            😞
          </span>
          <span
            className="text-4xl cursor-pointer hover:text-gray-500 transition-colors duration-300"
            onClick={() => handleEmojiClick("neutral")}
          >
            😐
          </span>
          <span
            className="text-4xl cursor-pointer hover:text-yellow-500 transition-colors duration-300"
            onClick={() => handleEmojiClick("happy")}
          >
            😊
          </span>
          <span
            className="text-4xl cursor-pointer hover:text-pink-500 transition-colors duration-300"
            onClick={() => handleEmojiClick("love")}
          >
            😍
          </span>
        </div>
      </div>
      <footer className="absolute bottom-2 text-center text-gray-600">
        <p className="text-sm">Powered by Zola Technologies</p>
      </footer>
    </div>
  );
}

export default Emoji;
