import React, { useState, useRef, useEffect } from 'react';
import { fetchPlacePredictions } from '../../pages/SignUp/googlePlaces';
import { toast, ToastContainer } from 'react-toastify';
import { AiOutlineBranches } from "react-icons/ai";

const Branch = () => {
  const [formData, setFormData] = useState({
    username: '',
    company_name: '',
    email: '',
    preferred_business_name: '',
    phone_number: '',
    password: '',
    first_name: '', // Add first_name
    last_name: '',  // Add last_name
  });
  const [errors, setErrors] = useState({}); // State to hold server errors
  const [loading, setLoading] = useState(false); // State to show loading indicator
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const [isHQ, setIsHQ] = useState(false); // State to check if user is from HQ company
  const suggestionsRef = useRef(); // useRef imported and defined

  useEffect(() => {
    // Fetch user data from localStorage
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (userData) {
      const { is_hq_company, first_name, last_name } = userData;
      setIsHQ(is_hq_company); // Set the HQ status

      // Prepopulate first_name and last_name if the user is from an HQ company
      if (is_hq_company) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          first_name: first_name || '',
          last_name: last_name || '',
        }));
      }
    }
  }, []);

  useEffect(() => {
    const { company_name } = formData;
    if (company_name.length > 2) {
      const fetchSuggestions = async () => {
        const suggestions = await fetchPlacePredictions(company_name);
        setPlaceSuggestions(suggestions);
      };
      fetchSuggestions();
    } else {
      setPlaceSuggestions([]);
    }
  }, [formData.company_name]);

  const handlePlaceSelect = (description) => {
    setFormData((prev) => ({
      ...prev,
      company_name: description,
    }));
    setPlaceSuggestions([]);
  };

  const handleClickOutside = (event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setPlaceSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    try {
      const token = localStorage.getItem('token');
      const userData = JSON.parse(localStorage.getItem('userData'));
      const superUser = userData.is_superuser;
      const isHQ = userData.is_hq_company;
      const userName = localStorage.getItem('username');
      // console.log(first)
      const firstName = userData.first_name;
      const lastName = userData.last_name;

      let apiEndpoint = 'https://zola.technology/api/hq-company/create-branch-company/';

      if (superUser) {
        apiEndpoint = `https://zola.technology/api/hq-company/create-branch/${userName}/`;
      }

      const updatedFormData = { ...formData };
      if (isHQ && !superUser) {
        updatedFormData.first_name = firstName;
        updatedFormData.last_name = lastName;
      }

     // If the user is a superuser, delete first_name and last_name from the form data
    if (superUser) {
      delete updatedFormData.first_name;
      delete updatedFormData.last_name;
    }

    const response = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedFormData),
    });

    if (response.ok) {
      const data = await response.json();
      onSubmit(data);
      toast.success('Branch created successfully! Please log in to the new account to complete signup.');
    } else {
      const errorData = await response.json();
      setErrors(errorData);
      toast.error(errors.error);
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setLoading(false);
  }
};

  return (
    <div className="max-w-2xl mx-auto bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-6">Create New Branch</h2>

      <form onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Input Fields */}
          {Object.keys(formData).map((key) => (
            <div key={key} className="relative mb-4">
              <label className="block text-gray-700 dark:text-white text-sm font-medium mb-1 capitalize">
                {key.replace(/_/g, ' ')}
              </label>
              <input
                type={key === 'email' ? 'email' : key === 'password' ? 'password' : 'text'}
                name={key}
                value={formData[key] || ''}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                disabled={isHQ && (key === 'first_name' || key === 'last_name')} // Disable first_name and last_name if isHQ is true
              />
              {errors[key] && <p className="text-red-500 text-sm mt-1">{errors[key][0]}</p>}

              {key === 'company_name' && placeSuggestions.length > 0 && (
                <div
                  ref={suggestionsRef}
                  className="absolute z-10 bg-white text-black mt-1 rounded-md shadow-md w-full max-h-40 overflow-y-auto"
                >
                  {placeSuggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className="p-2 hover:bg-gray-200 cursor-pointer"
                      onClick={() => handlePlaceSelect(suggestion.description)}
                    >
                      {suggestion.description}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        {errors.non_field_errors && (
          <div className="text-red-500 mb-4">{errors.non_field_errors[0]}</div>
        )}

        <div className="flex justify-end">
          <button
            type="submit"
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none transition duration-150"
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Create Branch'}
          </button>
        </div>
      </form>

      <ToastContainer />
    </div>
  );
};

export default Branch;
