import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

const PerformanceDashboard = () => {
  const [filter, setFilter] = useState("year");
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Taps",
        data: [],
        backgroundColor: "rgba(66, 100, 223, 0.4)",
        borderRadius: 4,
        barPercentage: 0.5,
        hoverBackgroundColor: "rgba(66, 100, 223, 1)",
      },
    ],
  });
  const [averageTaps, setAverageTaps] = useState(0);
  const [isMobileScreen, setIsMobileScreen] = useState(window.matchMedia("(max-width: 768px)").matches);
  const [isTabletScreen, setIsTabletScreen] = useState(window.matchMedia("(max-width: 1024px)").matches);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/get-spread-taps/?filter=${filter}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const result = await response.json();

          // Generate labels and data using the periods array from the new endpoint
          const labels = result.periods.map(period => period.period);
          const tapsData = result.periods.map(period => period.taps);

          setData({
            labels,
            datasets: [
              {
                label: "Taps",
                data: tapsData,
                backgroundColor: "rgba(66, 100, 223, 0.4)",
                borderRadius: 4,
                barPercentage: 0.5,
                hoverBackgroundColor: "rgba(66, 100, 223, 1)",
              },
            ],
          });

          // Calculate average taps
          const totalTaps = tapsData.reduce((sum, taps) => sum + taps, 0);
          setAverageTaps((totalTaps / tapsData.length).toFixed(2));
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const handleResize = () => {
      setIsMobileScreen(window.matchMedia("(max-width: 768px)").matches);
      setIsTabletScreen(window.matchMedia("(max-width: 1024px)").matches)
    };

    window.addEventListener("resize", handleResize);
    fetchData();
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filter]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: context => `${context.raw} taps`,
        },
        backgroundColor: "#ffffff",
        titleColor: "#333",
        bodyColor: "#333",
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        titleFont: { weight: "bold" },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: { color: "#666", font: { size: 14 } },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: Math.ceil(Math.max(...data.datasets[0].data) / 5) || 1,
          color: "#999",
          font: { size: 12 },
          padding: 10,
        },
        grid: { color: "rgba(0, 0, 0, 0.05)" },
      },
    },
  };

  return (
    <div className="flex flex-col lg:flex-row lg:items-start lg:mt-10 xs:mt-10 md:w-full xs:w-full space-y-4 lg:space-y-0 lg:space-x-4 bg-white p-4 rounded-[20px] ">
      <div className="flex flex-col lg:flex-row lg:items-start w-full space-y-4 lg:space-y-0 lg:space-x-4">
        <div className="w-full mb-4 lg:mb-0">
          <div className="flex justify-between p-4">
            <h3 className="mb-4 text-[24px] font-semibold text-[#4C4C4C] dark:text-gray-200">
              Total Taps
            </h3>
            <div className="flex items-center border border-[#EAEAEC] rounded-[5px] px-2 h-[40px]">
              <select
                value={filter}
                onChange={e => setFilter(e.target.value)}
                className="p-1 dark:bg-gray-700 bg-white dark:text-white focus:outline-none"
              >
                <option value="week">This Week</option>
                <option value="month">This Month</option>
                <option value="year">This Year</option>
              </select>
            </div>
          </div>
          <div className="w-full p-4 ">
      <Bar data={data} options={options}    style={{
          width: isMobileScreen ? "100%" : isTabletScreen ? "100%" : "100%",
          height: isMobileScreen ? "200px" : isTabletScreen ? "300px" : "350px",
        }}
      />
      </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceDashboard;
