import React from 'react';
import { Outlet } from 'react-router-dom';
import Dashboard from './New-Dashboard/Dashboard'

const DashboardLayout = () => {
  return (
    <Dashboard>
      <Outlet />
    </Dashboard>
  );
};

export default DashboardLayout;
