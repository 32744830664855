import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useNFC } from "../../context/NFCContext";

const NFCReaderComponent = () => {
  const { setNfcData } = useNFC();
  const navigate = useNavigate();

  useEffect(() => {
    if ("NDEFReader" in window) {
      const nfcReader = new window.NDEFReader();

      const onReading = async (event) => {
        try {
          const textDecoder = new TextDecoder();
          const { message } = event;

          const records = message.records || [];

          let scanUrl = null;
          let feedbackUrl = null;

          for (const record of records) {
            const recordData = textDecoder.decode(record.data);
            console.log("Record Data:", recordData); // Log the record data

            if (recordData.startsWith("https://zola.reviews/scan/")) {
              scanUrl = recordData;
            } else if (
              recordData.startsWith("https://zola.reviews/feedback/")
            ) {
              feedbackUrl = recordData;
            }
          }

          if (scanUrl) {
            const feedbackMatch = feedbackUrl?.match(
              /https:\/\/zola.reviews\/feedback\/([^/]+)\/([^/]+)/
            );

            if (feedbackMatch) {
              const company_username = feedbackMatch[1];
              const employee_username = feedbackMatch[2];
              console.log(
                "Extracted Usernames:",
                company_username,
                employee_username
              ); 

              try {
                const response = await fetch(
                  `https://zola.technology/api/company/employees/update/${company_username}/${employee_username}/increment-taps/`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (response.ok) {
                  console.log("Tap count incremented successfully");
                } else {
                  console.error("Failed to increment tap count");
                }
              } catch (error) {
                console.error("Error while sending tap data to backend", error);
              }

              setNfcData({ company_username, employee_username });
              navigate(
                `/feedback/${encodeURIComponent(
                  company_username
                )}/${encodeURIComponent(employee_username)}`
              );
            } else {
              console.error("Missing or invalid feedback URL");
            }
          } else {
            console.error("Missing or invalid scan URL");
          }
        } catch (error) {
          console.error("Error in onReading event:", error);
        }
      };

      nfcReader.addEventListener("reading", onReading);

      nfcReader
        .scan()
        .then(() => console.log("NFC Reader started"))
        .catch((error) => {
          console.error("NFC Reader failed to start", error);
        });

      // Cleanup event listener to prevent memory leaks
      return () => {
        nfcReader.removeEventListener("reading", onReading);
      };
    } else {
      console.error("Your phone may not support NFC scanning");
    }
  }, [navigate, setNfcData]);

  return (
    <div className="mt-20">
      <h1>NFC Reader Component</h1>
      <p>
        Scan your NFC tag to proceed. (**Make sure NFC is enabled on your
        phone**)
      </p>
    </div>
  );
};

export default NFCReaderComponent;
