import { useContext, useEffect, useState } from "react";
import {
  FaBuilding,
  FaUsers,
  FaExclamationTriangle,
  FaChartLine,
} from "react-icons/fa";
import { BsFillBarChartLineFill } from "react-icons/bs";
import PerformanceChart from "./PerformanceChart";
import { BranchContext } from "../context/BranchContext";
import Branches from "./Branches";
import cover from "../assets/cover.png";
import { PiUsersFill, PiCityFill } from "react-icons/pi";
import { HiChartBar } from "react-icons/hi";

const Dashboard = () => {
  const { branches, employeeTaps } = useContext(BranchContext);
  const [branch, setBranch] = useState(null);
  const [branchData, setBranchData] = useState(null);
  const [error, setError] = useState(null);
  // Retrieve token from local storage

  useEffect(() => {
    // Define an async function to fetch data
    const fetchBranchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        return;
      }

      try {
        const response = await fetch(
          "https://zola.technology/api/hq-company/branches/get-data/",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        setBranchData(data); // Update state with fetched data
      } catch (error) {
        setError(error.message);
      }
    };

    fetchBranchData(); // Call the fetch function when component mounts
  }, []); // Empty dependency array ensures this run

  
  console.log(branchData, "set state");

  return (
    <div>
      <h1 className="text-2xl font-light mb-8 dark:text-white">
        Headquarters Dashboard
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
        {/* Total Branches Card */}
        <div className="group bg-white rounded-lg xs:h-[150px] lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-blue-600 group-hover:bg-white text-white group-hover:text-blue-600 p-2 rounded-lg">
              <PiCityFill size={24} />
            </div>
            <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
              Total <br />
              Branches
            </p>
          </div>
          <p className="text-3xl font-semibold text-gray-900 group-hover:text-white">
            {branchData?.total_branches}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>

        {/* Total Employees Card */}
        <div className="group bg-white rounded-lg xs:h-[150px] lg:p-4 xs:p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-blue-600 group-hover:bg-white text-white group-hover:text-blue-600 p-2 rounded-lg">
              <PiUsersFill size={24} />
            </div>
            <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
              Total <br />
              Employees
            </p>
          </div>
          <p className="text-3xl font-semibold text-gray-900 group-hover:text-white">
            {branchData?.total_employees}
          </p>
          <style jsx>{`
            .group:hover {
              background-image: url(${cover});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          `}</style>
        </div>

        {/* Total Issues Card */}
        <div className="group bg-white rounded-lg p-4 flex flex-col justify-between items-start space-y-2 transition transform hover:scale-105 hover:bg-[#0c4ef8] hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-red-600 group-hover:bg-white  text-white group-hover:text-red-600  p-2 rounded-lg">
              <FaExclamationTriangle size={24} />
            </div>
            <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
              Total <br />
              Feedbacks
            </p>
            <p className="text-3xl font-semibold text-gray-900 group-hover:text-white">
              {branchData?.total_feedback_count}
            </p>
          </div>
          <div className="space-y-2">
            <p className="px-2 rounded-xl bg-green-100 text-green-400 group-hover:bg-green-200 group-hover:text-green-600">
              Positive: {branchData?.total_positive_feedback_count}
            </p>
            <p className="px-2 rounded-xl bg-red-100 text-red-400 group-hover:bg-red-200 group-hover:text-red-600">
              Negative: {branchData?.total_negative_feedback_count}
            </p>
          </div>
        </div>

        {/* Average Performance Card */}
        <div className="group bg-white rounded-lg p-4 flex flex-col justify-between items-start space-y-4 transition transform hover:scale-105 hover:bg-[#0c4ef8] hover:shadow-lg">
          <div className="flex items-center gap-3">
            <div className="bg-[#00C159] group-hover:bg-white text-white group-hover:text-[#00C159] p-2 rounded-lg">
              <HiChartBar size={24} />
            </div>
            <p className="text-gray-700 font-semibold text-sm group-hover:text-white">
              Average <br />
              Performance
            </p>
          </div>
          <p className="text-3xl font-semibold text-gray-900 group-hover:text-white">
            {branchData?.average_performance_score}%
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 mb-8">
        <PerformanceChart branches={branches} />
        <Branches showSearchAndCreate={false} />
      </div>
    </div>
  );
};

export default Dashboard;
