import React, { useState, useEffect } from "react";
import {
  IoMdAddCircleOutline,
  IoIosRadioButtonOff,
  IoIosCheckmarkCircle,
  IoMdList,
} from "react-icons/io";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Survey() {
  const [title, setTitle] = useState("");
  const [questions, setQuestions] = useState([
    { id: 1, text: "", type: "", options: [""] },
  ]);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [surveys, setSurveys] = useState([]); // State to store surveys
  const token = localStorage.getItem("token");
  const navigate = useNavigate(); // Initialize navigate


  useEffect(() => {
    // Fetch surveys automatically on component mount
    getAllSurveys();
  }, []);

  const addQuestion = () => {
    if (questions.length < 8) {
      setQuestions([
        ...questions,
        { id: questions.length + 1, text: "", type: "", options: [""] },
      ]);
    }
  };

  const updateQuestionText = (index, text) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].text = text;
    setQuestions(updatedQuestions);
  };

  const updateQuestionType = (index, type) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].type = type;
    if (type === "paragraph") updatedQuestions[index].options = [];
    setQuestions(updatedQuestions);
    setDropdownOpen((prev) => ({ ...prev, [index]: false }));
  };

  const toggleDropdown = (index) => {
    setDropdownOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const addOption = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options.push("");
    setQuestions(updatedQuestions);
  };

  const updateOptionText = (questionIndex, optionIndex, text) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = text;
    setQuestions(updatedQuestions);
  };

  const deleteOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(updatedQuestions);
  };

  const deleteQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const saveSurvey = async () => {
    const formattedQuestions = questions.map((q) => ({
      question_text: q.text,
      question_type:
        q.type === "single"
          ? "single_choice"
          : q.type === "multiple"
          ? "multi_choice"
          : "paragraph",
      choices:
        q.type === "paragraph"
          ? {}
          : q.options.reduce(
              (acc, opt, idx) => ({ ...acc, [`choice_${idx + 1}`]: opt }),
              {}
            ),
    }));

    const surveyData = {
      title,
      questions: formattedQuestions,
    };

    try {
      const response = await axios.post(
        "https://zola.technology/api/company/surveys/add-survey/",
        surveyData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Survey saved:", response.data);
      alert("Survey saved successfully!");
      getAllSurveys(); // Refresh the survey list after saving
    } catch (error) {
      console.error("Error saving survey:", error);
      alert("Failed to save survey.");
    }
  };

  // Fetch surveys from the API
  const getAllSurveys = async () => {
    try {
      const response = await axios.get(
        "https://zola.technology/api/company/surveys/get-all/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSurveys(response.data); // Set the fetched surveys in state
      console.log("Fetched surveys:", response.data);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      alert("Failed to fetch surveys.");
    }
  };

  return (
    <>
      <h2 className="text-2xl font-normal mb-4 text-gray-800">
        Customize Your Survey Page
      </h2>
      <div className="flex flex-col md:flex-row min-h-screen w-full">
        {/* Survey Form */}
        <div className="w-full md:w-2/3 p-4">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-medium">Survey</h3>
            <p className="mb-4">
              Create a maximum of 8 questions for your customers
            </p>

            {/* Survey Title Input */}
            <div className="mb-4">
              <input
                type="text"
                placeholder="Enter survey title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 placeholder-gray-500"
              />
            </div>

            {/* Questions */}
            <div className="space-y-6">
              {questions.map((question, questionIndex) => (
                <div key={question.id} className="p-4 rounded-lg shadow-sm">
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="text-gray-700 font-medium">
                      Question {questionIndex + 1}
                    </h3>
                    {questionIndex > 0 && (
                      <button
                        onClick={() => deleteQuestion(questionIndex)}
                        className="text-red-500 hover:text-red-700"
                      >
                        ✕
                      </button>
                    )}
                  </div>
                  <div className="flex items-center gap-4 justify-between mb-4">
                    <input
                      type="text"
                      placeholder="Enter question here"
                      value={question.text}
                      onChange={(e) =>
                        updateQuestionText(questionIndex, e.target.value)
                      }
                      className="w-full px-3 py-2 border-b border-gray-300 focus:outline-none focus:border-blue-500 placeholder-gray-500"
                    />

                    {/* Custom Dropdown for Question Type */}
                    <div className="relative w-1/3">
                      <button
                        onClick={() => toggleDropdown(questionIndex)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-white text-gray-700 flex items-center justify-between focus:outline-none"
                      >
                        {question.type
                          ? question.type === "single"
                            ? "Single Choice"
                            : question.type === "multiple"
                            ? "Multiple Choice"
                            : "Paragraph"
                          : "Question type"}
                        <svg
                          className={`w-4 h-4 text-gray-500 transition-transform ${
                            dropdownOpen[questionIndex] ? "rotate-180" : ""
                          }`}
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 011.06 1.06l-4.24 4.24a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>

                      {dropdownOpen[questionIndex] && (
                        <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                          <button
                            onClick={() =>
                              updateQuestionType(questionIndex, "paragraph")
                            }
                            className={`flex items-center w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 ${
                              question.type === "paragraph" ? "bg-gray-100" : ""
                            }`}
                          >
                            <IoMdList className="mr-2" /> Paragraph
                          </button>
                          <button
                            onClick={() =>
                              updateQuestionType(questionIndex, "multiple")
                            }
                            className={`flex items-center w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 ${
                              question.type === "multiple" ? "bg-gray-100" : ""
                            }`}
                          >
                            <IoIosCheckmarkCircle className="mr-2" /> Multiple
                            Choice
                          </button>
                          <button
                            onClick={() =>
                              updateQuestionType(questionIndex, "single")
                            }
                            className={`flex items-center w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 ${
                              question.type === "single" ? "bg-gray-100" : ""
                            }`}
                          >
                            <IoIosRadioButtonOff className="mr-2" /> Single
                            Choice
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Options for Single and Multiple Choice */}
                  {["single", "multiple"].includes(question.type) && (
                    <div className="space-y-4">
                      {question.options.map((option, optionIndex) => (
                        <div key={optionIndex} className="flex gap-3">
                          <input
                            type={
                              question.type === "single" ? "radio" : "checkbox"
                            }
                            disabled
                            className="text-blue-500"
                          />
                          <input
                            type="text"
                            placeholder={`Option ${optionIndex + 1}`}
                            value={option}
                            onChange={(e) =>
                              updateOptionText(
                                questionIndex,
                                optionIndex,
                                e.target.value
                              )
                            }
                            className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                          />
                          <button
                            onClick={() =>
                              deleteOption(questionIndex, optionIndex)
                            }
                            className="text-gray-500 hover:text-gray-700"
                          >
                            ✕
                          </button>
                        </div>
                      ))}
                      <button
                        onClick={() => addOption(questionIndex)}
                        className="text-blue-500 hover:text-blue-700 mt-2 text-sm font-semibold"
                      >
                        + Add option
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="flex justify-end space-x-4 mt-6">
              <button
                onClick={addQuestion}
                disabled={questions.length >= 8}
                className="px-6 py-3 flex items-center gap-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <IoMdAddCircleOutline size={20} className="inline-block" />
                Add Question
              </button>
              <button
                onClick={saveSurvey}
                className="px-6 py-3 flex items-center gap-2 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 transition"
              >
                Save Survey
              </button>
            </div>
          </div>
        </div>

        {/* Display Surveys */}
        <div className="w-full md:w-1/3 p-4 mt-6 md:mt-0">
          <h4 className="text-xl font-semibold mb-4">Saved Surveys</h4>
          <ul>
            {surveys.map((survey, index) => (
              <li
                onClick={() => navigate(`/dashboard/survey/${survey.id}`)}
                key={index}
                className="mb-3 p-3 bg-gray-100 rounded-lg shadow"
              >
                <h5 className="text-lg font-medium">{survey.title}</h5>
                <p>{survey.created_at}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Survey;
