import { useLocation } from 'react-router-dom';
import './success.css';

const SuccessPage = () => {
  const location = useLocation();
  const { rating } = location.state || {}; // Retrieve the rating passed from the Form page

  // Define different messages based on the rating
  const getSuccessMessage = () => {
    if (rating === 1 || rating === 2) {
      return "We're sorry you had a less than ideal experience. We will work on improving and someone from our team may reach out to you.";
    } else {
      return "Thank you😊 We will see you next time!";
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="p-8 rounded-lg text-center">
        <div className="icon-container mb-4">
          <svg
            className="tick-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#4caf50"
              strokeWidth="2"
              d="M2 12l5 5L22 4"
            />
          </svg>
        </div>
        <h1 className="text-3xl font-bold mb-4">Feedback Submitted Successfully!</h1>
        <p className="mb-4 text-xl">{getSuccessMessage()}</p>
      </div>
    </div>
  );
};

export default SuccessPage;
