import React, { useEffect } from 'react';
import { RiVerifiedBadgeFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

const Success = () => {
  const navigate = useNavigate();

  // Check for token in localStorage
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login'); // Redirect to login if no token found
    }
  }, [navigate]);

  const handleDashboardRedirect = () => {
    navigate('/dashboard');
  };

  return (
    <div className="flex items-center justify-center h-screen bg-green-100">
      <div className="bg-white border border-green-400 text-green-700 p-6 rounded shadow-lg">
        <div className="flex items-center mb-4">
          <RiVerifiedBadgeFill className="text-green-500 text-4xl" />
          <span className="text-xl ml-2">Success!</span>
        </div>
        <p className="text-lg mb-4">Your payment was successful.</p>
        <div className="flex justify-center">
          <button
            onClick={handleDashboardRedirect}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
