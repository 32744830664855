import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Account = () => {
  const [profile, setProfile] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get("https://zola.technology/api/company/profile/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProfile(response.data);
        setFirstName(response.data.first_name || "");
        setLastName(response.data.last_name || "");
        setEmail(response.data.email || "");
        setUsername(response.data.username || "");
        setPreferredBusinessName(response.data.preferred_business_name || "");
      })
      .catch((error) => {
        console.error("There was an error fetching the profile data!", error);
      });
  }, []);

  const handleSaveProfile = () => {
    const token = localStorage.getItem("token");
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      username,
      preferred_business_name: preferredBusinessName,
    };

    axios
      .patch("https://zola.technology/api/company/update/", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProfile(response.data);
        toast.success("Profile updated successfully!");
        setIsEditingProfile(false);
      })
      .catch((error) => {
        console.error("There was an error updating the profile!", error);
        toast.error("Failed to update profile. Please try again.");
      });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > 2 * 1024 * 1024) {
      toast.error("Image size must be less than or equal to 2MB.");
      return;
    }

    const token = localStorage.getItem("token");
    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    axios
      .post("https://zola.technology/api/upload-file/", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const imageUrl = response.data.file_url;
        return axios.patch(
          "https://zola.technology/api/company/update/",
          { profile_image: imageUrl },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
      .then((response) => {
        setProfile(response.data);
        toast.success("Image uploaded and profile updated successfully!");
      })
      .catch((error) => {
        console.error("There was an error uploading the image!", error);
        toast.error("Failed to upload image. Please try again.");
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleImageRemove = () => {
    const token = localStorage.getItem("token");

    axios
      .delete(
        `https://zola.technology/api/delete-file/?file_url=${encodeURIComponent(
          profile.profile_image
        )}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        return axios.patch(
          "https://zola.technology/api/company/update/",
          { profile_image: "" },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
      .then((response) => {
        setProfile(response.data);
        toast.success("Image removed and profile updated successfully!");
      })
      .catch((error) => {
        console.error("There was an error removing the image!", error);
        toast.error("Failed to remove image. Please try again.");
      });
  };

  return (
    <>
      <div className="flex items-start justify-between mb-6">
        {/* <h2 className="text-2xl font-semibold text-gray-900">
          {`${profile.first_name} ${profile.last_name}`}
        </h2> */}
        <div className="flex items-start flex-col">
          {profile.profile_image ? (
            <img
              className="w-32 h-32 rounded-full object-contain border ring-4 ring-offset-2 ring-blue-500 mb-4"
              src={profile.profile_image}
              alt={`${profile.first_name} ${profile.last_name}`}
            />
          ) : (
            <div
              className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer"
              onClick={() => document.getElementById("imageUpload").click()}
            >
              <span className="text-gray-700">Upload</span>
            </div>
          )}
          <div>
            <input
              type="file"
              id="imageUpload"
              className="hidden"
              accept="image/*"
              onChange={handleImageUpload}
            />
            {isEditingProfile && (
              <>
                <button
                  onClick={() => document.getElementById("imageUpload").click()}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md mr-2"
                  disabled={isUploading}
                >
                  {isUploading ? "Uploading..." : "Change Image"}
                </button>
                {profile.profile_image && (
                  <button
                    onClick={handleImageRemove}
                    className="px-4 py-2 border border-gray-300 rounded-md text-gray-700"
                    disabled={isUploading}
                  >
                    Remove Image
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        <button
          onClick={() => setIsEditingProfile((prev) => !prev)}
          className="px-4 py-2 bg-blue-600 text-white rounded-md"
        >
          {isEditingProfile ? "Cancel" : "Edit Profile"}
        </button>
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 mb-2">First Name</label>
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className={`w-full px-3 py-2 border rounded-md ${
            isEditingProfile ? "bg-white" : "bg-gray-100"
          }`}
          readOnly={!isEditingProfile}
        />
        {isEditingProfile && (
          <p className="text-sm text-gray-500 mt-1">Click to edit</p>
        )}
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 mb-2">Last Name</label>
        <input
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className={`w-full px-3 py-2 border rounded-md ${
            isEditingProfile ? "bg-white" : "bg-gray-100"
          }`}
          readOnly={!isEditingProfile}
        />
        {isEditingProfile && (
          <p className="text-sm text-gray-500 mt-1">Click to edit</p>
        )}
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 mb-2">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={`w-full px-3 py-2 border rounded-md ${
            isEditingProfile ? "bg-white" : "bg-gray-100"
          }`}
          readOnly={!isEditingProfile}
        />
        {isEditingProfile && (
          <p className="text-sm text-gray-500 mt-1">Click to edit</p>
        )}
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 mb-2">Username</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className={`w-full px-3 py-2 border rounded-md ${
            isEditingProfile ? "bg-white" : "bg-gray-100"
          }`}
          readOnly={!isEditingProfile}
        />
        {isEditingProfile && (
          <p className="text-sm text-gray-500 mt-1">Click to edit</p>
        )}
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 mb-2">
          Preferred Business Name
        </label>
        <input
          type="text"
          value={preferredBusinessName}
          onChange={(e) => setPreferredBusinessName(e.target.value)}
          className={`w-full px-3 py-2 border rounded-md ${
            isEditingProfile ? "bg-white" : "bg-gray-100"
          }`}
          readOnly={!isEditingProfile}
        />
        {isEditingProfile && (
          <p className="text-sm text-gray-500 mt-1">Click to edit</p>
        )}
      </div>

      {isEditingProfile && (
        <button
          onClick={handleSaveProfile}
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-md"
        >
          Save Changes
        </button>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        pauseOnHover
      />
    </>
  );
};

export default Account;
