import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import DashboardHeader from "./DashboardHeader";
import badgeCover1 from "../assets/badgeCover1.png";
import badgeCover2 from "../assets/badgeCover2.png";
import badgeCover3 from "../assets/badgeCover3.png";
import { PiTrophyFill } from "react-icons/pi";
import { PiArrowCircleLeft } from "react-icons/pi";
import { PiArrowCircleRight } from "react-icons/pi";
import ApexCharts from "react-apexcharts";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title
);

import badge1 from "../assets/Badge1.png"; // Adjust the path as necessary
import badge2 from "../assets/Badge2.png"; // Adjust the path as necessary
import badge3 from "../assets/badge3.png";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
  FaStar,
  FaTrophy,
} from "react-icons/fa";
import right from "../assets/right.png";
import left from "../assets/left.png";
const badges = [badge1, badge1, badge2, badge3];

function Reward() {
  const [topUsers, setTopUsers] = useState([]);
  const [otherUsers, setOtherUsers] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [reviewChange, setReviewChange] = useState("");
  const [rating, setRating] = useState(0);
  const [pastWinners, setPastWinners] = useState([]);
  const [currentWinnerIndex, setCurrentWinnerIndex] = useState(0);
  const [rewardData, setRewardData] = useState();
  // {const [reviewData, setReviewData] = useState({
  //   labels: [], // User names
  //   datasets: [
  //     {
  //       label: "Feedbacks Generated",
  //       data: [], // Individual user review counts
  //       borderColor: "rgba(75, 192, 192, 1)",
  //       backgroundColor: "rgba(75, 192, 192, 0.2)",
  //       borderWidth: 2,
  //       fill: true,
  //     },
  //   ],
  // });}
  const [reviewData, setReviewData] = useState({
    series: [{ name: "Feedbacks Generated", data: [] }],
    options: {
      chart: {
        type: 'area',
        height: 300,
        toolbar: {
          show: false
        },
     
      },
      xaxis: {
        categories: [], // Placeholder for user names
        labels: {
          style: {
            colors: '#6F707E'
          }
        }
      },
     
      yaxis: {
        labels: {
          style: {
            colors: '#6F707E'
          }
        }
      },
      stroke: {
        curve: 'straight',
        width: 2
      },
      markers: {
        size: 0,
        hover: { size: 0 } 
      },
      dataLabels: {
        enabled: false
      },
     

      grid : {
        color: '#e7e7e7',
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.2,
          stops: [0, 90, 100]
        }
      },
      colors: ["#00BA34"],
      tooltip: {
        theme: "dark"
      }
    }
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/rewards/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setRewardData(data);
        const mappedTopUsers = data.data.slice(0, 3).map((user, index) => ({
          name: user.employee.name,
          reviews: user.reward_total,
          position: index === 0 ? "1st" : index === 1 ? "2nd" : "3rd",
          avatar:
            user.employee.avatar ||
            "https://t3.ftcdn.net/jpg/06/33/54/78/360_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg",
          color:
            index === 0
              ? "bg-yellow-400"
              : index === 1
              ? "bg-red-400"
              : "bg-blue-400",
        }));

        const mappedOtherUsers = data.data.slice(3).map((user) => ({
          name: user.employee.name,
          reviews: user.reward_total,
          positionChange: user.position_change || "No Change",
        }));

        // Calculate total reviews from all employees
        const total = data.data.reduce(
          (acc, user) => acc + user.total_feedback,
          0
        );

        // Prepare data for line chart for individual users
        const reviewsPerUser = data.data; // Assuming each user has their own review data
        const labels = reviewsPerUser.map((user) => user.employee.name); // User names as labels
        const reviewCounts = reviewsPerUser.map((user) => user.total_feedback); // Reviews count for each user

        setTopUsers(mappedTopUsers);
        setOtherUsers(mappedOtherUsers);
        setTotalReviews(total); // Update totalReviews here
        setReviewChange(data.review_change || "+0%");
        setRating(data.average_rating || 0);
        setPastWinners(mappedTopUsers);
        // {setReviewData({
        //   labels: labels,
        //   datasets: [
        //     {
        //       label: "Feedbacks Generated",
        //       data: reviewCounts,
        //       borderColor: "rgba(75, 192, 192, 1)",
        //       backgroundColor: "rgba(75, 192, 192, 0.2)",
        //       borderWidth: 2,
        //       fill: true,
        //     },
        //   ],
        // });}
        setReviewData({
          series: [{ name: "Feedbacks Generated", data: reviewCounts }],
          options: {
            ...reviewData.options,
            xaxis: { ...reviewData.options.xaxis, categories: labels }
          }
        });
      } catch (error) {
        toast.error("Failed to fetch customer data.");
      }
    };

    fetchUserData();
  }, []);

  const reorderedUsers = [
    topUsers[0] || null,
    topUsers[1] || null,
    ...topUsers.slice(2),
  ].filter(Boolean);
  console.log(reorderedUsers);
  const nextWinner = () => {
    setCurrentWinnerIndex((prevIndex) => (prevIndex + 1) % pastWinners.length);
  };

  const prevWinner = () => {
    setCurrentWinnerIndex(
      (prevIndex) => (prevIndex - 1 + pastWinners.length) % pastWinners.length
    );
  };

  const [chartDimensions, setChartDimensions] = useState({ height: '400px', width: '100%' });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setChartDimensions({ height: '200px', width: '100%' });  // Small screens
      } else if (window.innerWidth < 1024) {
        setChartDimensions({ height: '400px', width: '80%' });  // Medium screens
      } else {
        setChartDimensions({ height: '300px', width: '60%' });  // Large screens
      }
    };

    handleResize(); // Set initial size
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="min-h-screen md:p-2">
      <DashboardHeader title="Rewards" />
      <div className="w-full overflow-hidden">
        <div className="flex justify-center flex-col hidden md:block items-center gap-8 p-6 bg-white dark:bg-gray-800 transition-colors duration-300 ease-in-out relative rounded-[16px] border border-[#EAEAEC] ">
          <h2 className=" md:text-[24px] text-center text-[#454861] mb-[120px] font-medium">
            Top 3 employees of the month
          </h2>
          <img
            src={right}
            alt="rightIcon"
            className="absolute right-0 bottom-0 -z-0"
          />
          <img
            src={left}
            alt="rightIcon"
            className="absolute left-0 bottom-0 -z-0"
          />
          <div className="flex justify-center items-center space-x-10 p-4">
            {reorderedUsers[1] && (
              <div className="relative w-[220px] md:w-[160px] lg:w-[155px] h-[280px]  flex flex-col items-center border-[0.8px] border-[#F5AD1B] gap-4 justify-center bg-white rounded-2xl transition-transform duration-300 hover:scale-105 z-100 " style={{ boxShadow: '0px 19.21px 38.42px -9.61px #1018282E' }}
>
                <div className="absolute -top-14">
                  <img src={badges[2]} alt="2nd Place" className="w-[70px] h-[80px]"/>
                </div>
                <img
                  src={reorderedUsers[1]?.avatar}
                  alt={reorderedUsers[1]?.name}
                  className="w-[80px] h-[80px]   mt-[30px]"
                />
                <p className="text-[12px] font-semibold text-[#454861] mt-[-5px]">
                  {reorderedUsers[1]?.name}
                </p>
                <div className=" w-[112px] p-2 text-center rounded-t-lg  flex flex-col h-1/2 justify-between  text-[#FEFEFF]  z-10 mt-[-12px]"
                   style={{
                    backgroundImage: `url(${badgeCover2})`, // Use the imported image
                    backgroundSize: 'cover', // Cover the entire div
                    backgroundPosition: 'center', // Center the image
                    backgroundRepeat: 'no-repeat', // Do not repeat the image
                  }}>
             
                  <p className="text-[26px] font-semibold">
                    2nd <br /> Place
                  </p>
                  <p className="text-[12px] mt-1 font-semibold">
                    {reorderedUsers[1]?.reviews} Reward <br />
                    Total
                  </p>
                </div>
              </div>
            )}

            {reorderedUsers[0] && (
              <div className="relative w-[193px] md:w-[160px] lg:w-[193px] h-[300px]  flex flex-col items-center border-[0.8px] border-[#F5AD1B] gap-4 justify-center bg-white rounded-2xl transition-transform duration-300 hover:scale-105 scale-110 z-10" style={{ boxShadow: '0px 24px 48px -12px #1018282E' }}
>
                <div className="absolute -top-[77px]">
                  <img src={badges[1]} alt="1st Place" />
                </div>
                <img
                  src={reorderedUsers[0]?.avatar}
                  alt={reorderedUsers[0]?.name}
                  className="w-[90px] h-[90px] rounded-full  mt-[34px]"
                />
                <p className="text-[14px] font-semibold text-[#454861] mt-[-15px]">
                  {reorderedUsers[0]?.name}
                </p>
                <div className="w-[140px] p-2 text-center rounded-t-lg flex flex-col h-1/2 justify-between  text-[#FEFEFF] z-0 mt-[-14px]"
                  style={{
                    backgroundImage: `url(${badgeCover1})`, // Use the imported image
                    backgroundSize: 'cover', // Cover the entire div
                    backgroundPosition: 'center', // Center the image
                    backgroundRepeat: 'no-repeat', // Do not repeat the image
                  }}>
                    
                  <p className="text-[32px] font-semibold">
                    1st <br /> Place
                  </p>
                  <p className="text-[14px] mt-1 font-semibold">
                    {reorderedUsers[0]?.reviews} Reward <br />
                    Total
                  </p>
                </div>
              </div>
            )}

            {reorderedUsers[2] && (
              <div className="relative w-[155px] md:w-[155px] lg:w-[155px] h-[280px] flex flex-col items-center border-[0.8px] border-[#F5AD1B] gap-4 justify-center bg-white rounded-2xl transition-transform duration-300 hover:scale-105 " style={{ boxShadow: '0px 19.21px 38.42px -9.61px #1018282E' }}
>
                <div className="absolute -top-[60px]">
                  <img src={badges[3]} alt="3rd Place" />
                </div>
                <img
                  src={reorderedUsers[2]?.avatar}
                  alt={reorderedUsers[2]?.name}
                  className="w-[80px] h-[80px] mt-[34px]"
                />
                <p className="text-[12px] font-semibold text-[#454861] mt-[-8px]">
                  {reorderedUsers[2]?.name}
                </p>
                <div className="w-[112px] p-2 text-center rounded-t-lg flex flex-col h-3/4 justify-between text-[#FEFEFF] z-0 mt-[-12px]"
                 style={{
                  backgroundImage: `url(${badgeCover3})`, // Use the imported image
                  backgroundSize: 'cover', // Cover the entire div
                  backgroundPosition: 'center', // Center the image
                  backgroundRepeat: 'no-repeat', // Do not repeat the image
                }}>
                  <p className="text-[26px] font-semibold">
                    3rd <br />
                    Place
                  </p>
                  <p className="text-[12px] mt-1 font-semibold">
                    {reorderedUsers[2]?.reviews} Reward <br /> Total
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="w-full mt-8 grid md:grid-cols-2 xs:grid-cols-1 gap-6 ">
          <div className="p-6 dark:shadow-md w-full max-w-4xl mx-auto bg-white rounded-[16px] border border-[#EAEAEC] dark:bg-gray-800">
            <div className="flex justify-between items-center mb-6">
              <h3 className="font-medium text-xl sm:text-2xl text-[#292D32] dark:text-white tracking-tight flex items-center">
                <PiTrophyFill size={24} className="mr-3 text-[#F5AD1B] " /> Past
                Winners
              </h3>
             <div className="border border-gray-300 rounded-md px-2 py-[4px]">
             <select className=" text-gray-700 bg-white focus:outline-none ">
                <option value="April">Month</option>
              </select>
             </div>
            </div>

            <div className="relative flex items-center justify-center">
  {/* Previous Button */}

  <button
    onClick={prevWinner}
     className="absolute left-0 bg-white dark:bg-gray-600 p-2 h-10 w-10 flex items-center justify-center rounded-full shadow-sm hover:bg-gray-300 dark:hover:bg-gray-500 transition-all duration-300 ease-in-out text-[24px]"
   
  >
    <PiArrowCircleLeft className="text-[#454861]"/>
  </button>


  {/* Cards Container */}
  <div className="flex space-x-4 justify-center items-center w-full items-center">
    {/* Previous Winner Card */}
    <div
      className={`flex flex-col items-center p-2 rounded-[10px] transition-transform duration-500 ease-in-out border-[0.6px] border-[#F5AD1B] h-[220px] w-[118px] ${
        currentWinnerIndex === 0 ? 'hidden' : 'block'
      } ${currentWinnerIndex === 0 ? '' : 'transform scale-90 opacity-80'}`}
    >
      <img
        src="https://t3.ftcdn.net/jpg/06/33/54/78/360_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg"
        alt={pastWinners[currentWinnerIndex - 1]?.name}
        className="w-20 h-20 rounded-full   "
      />
      <p className="text-[9px] font-semibold text-[#454861] dark:text-white md:mt-0 xs:mt-[5px]">
        {pastWinners[currentWinnerIndex - 1]?.name}
      </p>
      <div className="md:w-[85px] xs:w-[75px] p-2 text-center rounded-t-lg  text-[#FEFEFF] mt-[21px] " style={{
                  backgroundImage: `url(${badgeCover1})`, // Use the imported image
                  backgroundSize: 'cover', // Cover the entire div
                  backgroundPosition: 'center', // Center the image
                  backgroundRepeat: 'no-repeat', // Do not repeat the image
                }}>
        <p className="text-[16px] font-semibold">1st<br/> Place</p>
        <p className="text-[9px] font-semibold mt-1">
          {pastWinners[currentWinnerIndex - 1]?.reviews} <br/> Reward Total
        </p>
      </div>
    </div>

    {/* Current Winner Card */}
    <div className="flex flex-col items-center justify-center p-6 rounded-[12px] transition-transform duration-500 transform hover:scale-105 border-[0.8px] border-[#F5AD1B] w-4/12 md:h-[288px] xs:h-[250px]">
      <img
        src="https://t3.ftcdn.net/jpg/06/33/54/78/360_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg"
        alt={pastWinners[currentWinnerIndex]?.name}
        className="w-24 h-24 rounded-full mb-4 md:mt-4 xs:mt-3 "
      />
      <p className="text-[12px] font-semibold text-[#454861] dark:text-white md:mt-0 xs:mt-[-10px] sm:mt-[-25px] ">
        {pastWinners[currentWinnerIndex]?.name}
      </p>
      <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
        {pastWinners[currentWinnerIndex]?.month}{" "}
        {pastWinners[currentWinnerIndex]?.year}
      </p>
      <div className="md:w-[112px] xs:w-[80px] p-2 text-center rounded-t-lg  text-white lg:mt-[20px] sm:mt-[-5px] xs:mt-[5px]"style={{
                  backgroundImage: `url(${badgeCover1})`, // Use the imported image
                  backgroundSize: 'cover', // Cover the entire div
                  backgroundPosition: 'center', // Center the image
                  backgroundRepeat: 'no-repeat', // Do not repeat the image
                }}>
        <p className="text-[20px] font-semibold">1st <br/> Place</p>
        <p className="text-[12px] font-semibold mt-1">
          {pastWinners[currentWinnerIndex]?.reviews} <br/> Reward Total
        </p>
      </div>
    </div>

    {/* Next Winner Card */}
    <div
      className={`flex flex-col items-center p-4 rounded-2xl transition-transform duration-500 ease-in-out border-[0.6px] border-[#F5AD1B] w-4/12 h-[220px] ${
        currentWinnerIndex === pastWinners.length - 1 ? 'hidden' : 'block'
      } ${currentWinnerIndex === pastWinners.length - 1 ? '' : 'transform scale-90 opacity-80'}`}
    >
      <img
        src="https://t3.ftcdn.net/jpg/06/33/54/78/360_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg"
        alt={pastWinners[currentWinnerIndex + 1]?.name}
        className="w-20 h-20 rounded-full "
      />
      <p className="text-[9px] font-semibold text-gray-900 dark:text-white md:mt-0 xs:mt-3 sm:mt-[-0px]">
        {pastWinners[currentWinnerIndex + 1]?.name}
      </p>
      <div className="md:w-[85px] xs:w-[75px] p-2 text-center rounded-t-lg  text-[#FEFEFF] mt-[13px]" style={{
                  backgroundImage: `url(${badgeCover1})`, // Use the imported image
                  backgroundSize: 'cover', // Cover the entire div
                  backgroundPosition: 'center', // Center the image
                  backgroundRepeat: 'no-repeat', // Do not repeat the image
                }}>
        <p className="text-[16px] font-semibold">1st <br/> Place</p>
        <p className="text-[9px] font-semibold mt-1">
          {pastWinners[currentWinnerIndex + 1]?.reviews} Reward Total
        </p>
      </div>
    </div>
  </div>

  {/* Next Button */}
  <button
    onClick={nextWinner}
    className="absolute right-0 bg-white dark:bg-gray-600 p-2 h-10 w-10 flex items-center justify-center rounded-full shadow-sm hover:bg-gray-300 dark:hover:bg-gray-500 transition-all duration-300 ease-in-out text-[24px]"
  >
    <PiArrowCircleRight className="text-[#454861]"/>
  </button>
</div>

          </div>

          <div className="p-6 md:p-4 rounded-[13px]  dark:bg-gray-800 bg-white dark:text-white w-full border border-[#EAEAEC] h-auto">
          <div className="flex items-center justify-between">
           <div>
           <h3 className="font-medium text-[20px] text-[#6F707E] dark:text-white">
              Total Feedbacks
            </h3>
           
              <p className="text-[40px] font-semibold text-[#454861]">{totalReviews}</p>
           </div>
             <div className="border p-2 rounded-[4px] flex justify-center">
             <select className="  bg-white">
                <option value="all">Month</option>
              </select>
              </div>
            </div>

            <div className="">
            {/* {  <Line
                style={{ height: chartDimensions.height, width: chartDimensions.width }}
                data={reviewData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: true,
                      grid: {
                        color: "rgba(200, 200, 200, 0.3)", // Customize the grid color if needed
                      },
                    },
                    x: {
                      grid: {
                        display: false, // Hide the grid lines on the x-axis
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: true,
                      position: "top",
                      labels: {
                        color: "#333", // Customize label colors
                      },
                    },
                  },
                }}
              />} */}
               <ApexCharts
        options={reviewData.options}
        series={reviewData.series}
        type="area"
        height={300}
      />
            </div>
          </div>
        </div>
        
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-separate border-spacing-y-3">
            <thead className="text-[#6F707E] text-[16px]">
              <tr>
                <th className="px-4 py-2 text-left font-medium">S/N</th>
                <th className="px-4 py-2 text-left font-medium">Name</th>
                <th className="px-4 py-2 text-left font-medium">Performance</th>
                <th className="px-4 py-2 text-left font-medium">Feedbacks</th>
                <th className="px-4 py-2 text-left font-medium">Rewards</th>
                <th className="px-4 py-2 text-left font-medium">Rating</th>
              </tr>
            </thead>
            <tbody>
              {rewardData?.data.map((employeeData, index) => (
                <tr
                  key={employeeData.employee.id}
                  className="bg-white text-[#282A3E]  text-[16px]   hover:shadow-md transition "
                >
                  <td data-cell="S/N">{index + 1}</td>
                  <td data-cell="Name" className=" flex items-center space-x-2">
                    <img
                      src={employeeData.employee.company.profile_image}
                      alt={employeeData.employee.name}
                      className="w-10 h-10 rounded-full object-contain"
                    />
                    <span className="font-semibold">{employeeData.employee.name}</span>
                  </td>
                  <td data-cell="Performance" className="font-semibold">{employeeData.performance_score}%</td>
                  <td data-cell="Feedbacks" className="font-semibold">{employeeData.total_feedback}</td>
                  <td data-cell="Rewards" className="font-semibold">
                    {employeeData.reward_currency} {employeeData.reward_total}
                  </td>
                  <td data-cell="Ratings" className="font-semibold">
                    <div className="flex items-center">
                      {employeeData.average_rating}{" "}
                      <FaStar className="text-yellow-400 ml-1" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Reward;
