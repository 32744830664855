import Button from '../../components/Button/Button'
import DataDriven from './DataDriven.svg'
import './DataDrivenCard.css'

function DataDrivenCard() {
  return (
    <section className='data-driven'>
      <div className="data-driven-insight">
        <div className='data-driven-text'>
          <h2>Empower Your Business with Data-Driven Insights</h2>
          <p>Take reviews and feedback collection to the next level with Zola&apos;s NFC-enabled platform. Join leading businesses worldwide in harnessing the power of real-time feedback to drive informed decisions and elevate customer satisfaction.</p>
          <Button text="Sign Up Now" style={{backgroundColor: "#001514", color: "#fff"}} />
        </div>
        <div>
          <img src={DataDriven} alt="" />
        </div>
      </div>
    </section>
  )
}

export default DataDrivenCard