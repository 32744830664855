import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Testimonial() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Trigger animation when 10% of the component is visible
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  const testimonials = [
    {
      name: 'Kevin P.',
      image: 'https://via.placeholder.com/40',
      review: 'Zola has transformed our client interactions. We now collect valuable feedback in person, allowing us to enhance our services and showcase our excellence online.',
      rating: 5,
      platform: 'G',
    },
    {
      name: 'James Rana',
      image: '',
      review: 'I’ve been a customer for a month now and already have 400 subscribers with over 1500% ROI. The biggest selling point to me for signing up was the no contracts required so I could cancel at any time.',
      rating: 5,
      platform: 'G',
    },
    {
      name: 'Michael Reynolds',
      image: 'https://via.placeholder.com/40',
      review: 'Exceptional experience from the start! Thrilled with our ongoing relationship. A true technology partnership that\'s growing with our business while helping grow our business.',
      rating: 5,
      platform: 'G',
    },
  ];

  return (
    <div className="font-figtree py-12">
      <h2 className="text-center text-2xl font-semibold text-[38px] mb-16 leading-[49px]">Business owners trust Zola <br /> to drive results</h2>
      <motion.div 
        ref={ref} 
        variants={containerVariants} 
        initial="hidden" 
        animate={controls}
        className="max-w-[1340px] mx-auto grid gap-8 grid-cols-1 md:grid-cols-3"
      >
        {testimonials.map((testimonial, index) => (
          <motion.div 
            key={index} 
            variants={itemVariants}
            className="flex flex-col bg-[#fefbf2] p-6 rounded-3xl shadow-lg"
          >
            <div className="flex flex-col items-start gap-6">
              {testimonial.image ? (
                <img src={testimonial.image} alt={testimonial.name} className="w-10 h-10 rounded-full" />
              ) : (
                <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center text-xl font-bold">
                  {testimonial.name.charAt(0)}
                </div>
              )}
              <h4 className="font-medium text-2xl">{testimonial.name}</h4>
            </div>
            <p className="text-black font-normal text-lg flex-grow my-4">{testimonial.review}</p>
            <div className="flex items-center mt-auto">
              <span className="text-2xl font-bold mr-2">{testimonial.platform}</span>
              <div className="flex text-2xl">
                {Array.from({ length: testimonial.rating }, (_, i) => (
                  <span key={i} className="text-black">★</span>
                ))}
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default Testimonial;
