import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

function WhyZola() {
  const ref = useRef(null); // Create a reference for the section
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

  // Parent container animation variant
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.6, // Delay between child animations
      },
    },
  };

  // Individual element animation variant
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  // Variants for each column animation
  const fadeInLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.8 },
    },
  };

  const fadeInRight = {
    hidden: { opacity: 0, x: 50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.8 },
    },
  };

  return (
    <div className="bg-white py-8">
      {/* Header Section */}
      <motion.div
        className="text-center flex flex-col items-center mb-12"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Title */}
        <motion.h2
          className="text-6xl font-semibold text-gray-900 mb-4"
          variants={fadeInUp}
        >
          So, Why Choose Zola?
        </motion.h2>

        {/* Subtitle */}
        <motion.p
          className="text-2xl text-gray-600 max-w-2xl mx-auto"
          variants={fadeInUp}
        >
          At Zola, we specialize in empowering larger businesses with innovative
          solutions that drive growth and enhance customer experiences. Here's
          why leading enterprises trust us:
        </motion.p>

        {/* Image */}
        <motion.img
          src="https://images.pexels.com/photos/5898233/pexels-photo-5898233.jpeg?auto=compress&cs=tinysrgb&w=600"
          alt="Revolutionize with Cheers"
          className="max-w-full mt-4 h-auto"
          variants={fadeInUp}
        />
      </motion.div>

      {/* Two-Column Section */}
      <motion.div
        className="grid grid-cols-1 lg:grid-cols-2 gap-12 px-8 lg:px-32"
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
      >
        {/* Left Column */}
        <motion.div variants={fadeInLeft}>
          <h3 className="text-4xl font-semibold text-gray-900 mb-4">
            Advanced Analytics for Informed Decisions
          </h3>
          <p className="text-lg text-gray-600 mb-6">
            Zola provides comprehensive analytics that offer actionable insights
            into every facet of your in-person interactions. Our platform tracks
            employee performance, customer satisfaction, and key metrics
            tailored to your business objectives.
          </p>
          <ul className="list-disc list-inside space-y-2 text-lg text-gray-600">
            <li>
              <strong>Data-Driven Strategies:</strong> Make informed decisions
              with real-time data.
            </li>
            <li>
              <strong>Employee Performance Metrics:</strong> Identify top
              performers and areas for improvement.
            </li>
            <li>
              <strong>Customer Satisfaction Tracking:</strong> Understand
              customer needs to enhance loyalty.
            </li>
          </ul>
          <p className="mt-4 italic text-gray-500">
            While generic solutions offer limited data, Zola delivers in-depth
            analytics that empower you to drive efficiency and profitability.
          </p>
        </motion.div>

        {/* Right Column */}
        <motion.div variants={fadeInRight}>
          <h3 className="text-4xl font-semibold text-gray-900 mb-4">
            Custom Solutions Tailored to Your Needs
          </h3>
          <p className="text-lg text-gray-600 mb-6">
            We understand that no two enterprises are the same. Zola offers
            fully customizable solutions that seamlessly integrate with your
            existing systems, ensuring a perfect fit for your operations.
          </p>
          <ul className="list-disc list-inside space-y-2 text-lg text-gray-600">
            <li>
              <strong>Flexible Features:</strong> Choose the functionalities
              that matter most to you.
            </li>
            <li>
              <strong>Scalable Platform:</strong> Adapt our solutions as your
              business grows.
            </li>
            <li>
              <strong>Seamless Integration:</strong> Minimal disruption to your
              current processes.
            </li>
          </ul>
          <p className="mt-4 italic text-gray-500">
            Unlike one-size-fits-all services, we work closely with you to
            create a platform that meets your specific goals and challenges.
          </p>
        </motion.div>

        <motion.div variants={fadeInLeft}>
          <h3 className="text-4xl font-semibold text-gray-900 mb-4">
            Elevate In-Person Customer Experience
          </h3>
          <p className="text-lg text-gray-600 mb-6">
            In-person engagement is crucial for customer loyalty. Zola
            transforms these interactions by empowering your employees with
            NFC-enabled badges that facilitate instant feedback, reviews, and
            seamless payments.
          </p>
          <ul className="list-disc pl-5 space-y-2">
            <li>
              <strong>Instant Feedback Collection:</strong> Capture customer
              sentiments on the spot.
            </li>
            <li>
              <strong>Seamless Payment Processing:</strong> Make transactions
              effortless for your customers.
            </li>
            <li>
              <strong>Trust Building:</strong> Instantly verify employee
              identities to boost customer confidence.
            </li>
          </ul>
          <p className="mt-4 italic text-gray-500">
            While others may focus solely on online reviews, Zola enhances the
            entire customer journey, starting from the first face-to-face
            interaction.
          </p>
        </motion.div>

        {/* Right Column */}
        <motion.div variants={fadeInRight}>
          <h3 className="text-4xl font-semibold text-gray-900 mb-4">
            Experience the Zola Difference
          </h3>
          <p className="text-lg text-gray-600 mb-6">
            By choosing Zola, you're investing in a partner committed to your
            success. We go beyond standard offerings to provide a comprehensive
            solution that addresses the unique needs of larger enterprises.
          </p>
          <ul className="list-disc pl-5 space-y-2">
            <li>
              <strong>Unparalleled Support:</strong> Our dedicated team ensures
              you maximize the platform's potential.
            </li>
            <li>
              <strong>Industry Expertise:</strong> Benefit from our experience
              across various sectors.
            </li>
            <li>
              <strong>Proven Results:</strong> Join the ranks of businesses that
              have significantly improved their customer satisfaction and
              operational efficiency.
            </li>
          </ul>
          <p className="mt-4 italic text-gray-500">
            Elevate your business with advanced analytics, custom-tailored
            solutions, and unparalleled customer experiences.
          </p>
        </motion.div>
      </motion.div>

      <div className="relative bg-teal-900 py-24 mt-12 text-white">
        {/* Background Abstract Shapes */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute left-0 bottom-0 w-1/4 h-1/4 bg-teal-700 rounded-full opacity-50"></div>
          <div className="absolute right-0 top-0 w-1/4 h-1/4 bg-lime-300 rounded-full opacity-50"></div>
        </div>

        <div className="relative z-10 text-center max-w-2xl mx-auto space-y-6">
          {/* Title */}
          <h2 className="text-5xl font-semibold leading-snug">
            Ready to Elevate Your <br /> In-Person Interactions?
          </h2>

          {/* Subtitle */}
          <p className="text-lg leading-relaxed">
            Discover how Zola boosts customer engagement, reviews, and
            insights, driving unmatched efficiency and growth with NFC
            technology.
          </p>

          {/* Email Input Section */}
          <div className="mt-8 flex justify-center">
            <form className="w-full max-w-md flex shadow-md rounded-lg overflow-hidden">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-1 px-4 py-3 focus:outline-none text-gray-800"
              />
              <button
                type="submit"
                className="bg-lime-300 text-teal-900 px-6 py-3 font-semibold"
              >
                Get Started
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyZola;
