import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaRegMoon,
  FaSun,
  FaSignOutAlt,
  FaCog,
  FaChartBar,
  FaCubes,
  FaBars, // Icon for opening the sidebar
  FaTimes, // Icon for closing the sidebar
} from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import Logo from "./zolaDark.png";
import ZolaLogo from "./Layer_1.png";

import signOut from './SignOut.png'
// import ZolaLogo from "./Layer_1.png";
import { AuthContext } from "../../../context/AuthContext";
import { PiTextColumnsFill } from "react-icons/pi";
import { PiChartLineUp } from "react-icons/pi";
import { PiGitBranchBold } from "react-icons/pi";
import { PiSignOut } from "react-icons/pi";
import { AiFillMessage } from "react-icons/ai";

const Sidebar = ({ theme, toggleTheme, toggleSidebar }) => {
  const location = useLocation();
  const { currentUser, logout } = useContext(AuthContext);

  const [sidebarVisible, setSidebarVisible] = useState(false); // State to control sidebar visibility

  const handleLogout = () => {
    logout();
  };

  if (!currentUser) {
    return null;
  }

  const isActive = (path) =>
    location.pathname === path
      ? "bg-blue-600 dark:bg-blue-700 text-white"
      : theme === "light"
      ? "text-gray-900 hover:bg-blue-100"
      : "text-white hover:bg-gray-600";

  // Close sidebar when a link is clicked on smaller screens
  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setSidebarVisible(false); // Close sidebar on link click
    }
  };

  return (
    <>
      {/* Button to toggle sidebar */}
      {!sidebarVisible && (
        <button
          className="md:hidden absolute w-[40px] h-[40px] top-6 right-2 z-50 text-gray-700 dark:text-white mr-5 bg-[#FFFFFF] rounded-[4px]"
          onClick={() => setSidebarVisible(true)}
        >
          <FaBars size={24} className="m-2"/>
        </button>
      )}

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 transition-all duration-300 shadow-lg z-50 ${
          theme === "light" ? "bg-white text-gray-900" : "bg-gray-900 text-white"
        } w-64 transform ${
          sidebarVisible ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0`} // Added responsive toggle for sidebar visibility
      >
        {/* Top Section: Logo */}
        <div className="p-8">
          <img src={theme === "light" ? ZolaLogo : Logo} alt="Zola Logo" className="w-[150px] h-[30px] object-contain" />
        </div>

        {/* Navigation Links */}
        <nav className="flex-1 p-4">
          <ul className="space-y-2 ">
            <li onClick={handleLinkClick} >
              <Link
                to="/hqdashboard"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/hqdashboard"
                )}`}
              >
                <PiTextColumnsFill size={24}className={location.pathname === "/hqdashboard" ? "text-white" : "text-[#9C9DA6]"}/>
                <span className={location.pathname === "/hqdashboard" ? "text-white" : "text-[#9C9DA6]"}>Dashboard</span>
              </Link>
            </li>

            <li onClick={handleLinkClick}>
              <Link
                to="/hqdashboard/analytics"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/hqdashboard/analytics"
                )}`}
              >
                <PiChartLineUp size={24} className={location.pathname === "/hqdashboard/analytics" ? "text-white" : "text-[#9C9DA6]"}/>
                <span className={location.pathname === "/hqdashboard/analytics" ? "text-white" : "text-[#9C9DA6]"}>Analytics</span>
              </Link>
            </li>

            <li onClick={handleLinkClick}>
              <Link
                to="/hqdashboard/branches"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/hqdashboard/branches"
                )}`}
              >
                <PiGitBranchBold size={24} className={location.pathname === "/hqdashboard/branches" ? "text-white" : "text-[#9C9DA6]"}/>
                <span className={location.pathname === "/hqdashboard/branches" ? "text-white" : "text-[#9C9DA6]"}>Branches</span>
              </Link>
            </li>

            <li onClick={handleLinkClick}>
              <Link
                to="/hqdashboard/messages"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/hqdashboard/messages"
                )}`}
              >
                <AiFillMessage size={24} className={location.pathname === "/hqdashboard/messages" ? "text-white" : "text-[#9C9DA6]"}/>
                <span className={location.pathname === "/hqdashboard/messages" ? "text-white" : "text-[#9C9DA6]"}>Messages</span>
              </Link>
            </li>

            <li onClick={handleLinkClick}>
              <Link
                to="/hqdashboard/settings"
                className={`flex items-center gap-4 p-3 rounded-md group ${isActive(
                  "/hqdashboard/settings"
                )}`}
              >
                <FaCog size={24} className={location.pathname === "/hqdashboard/settings" ? "text-white" : "text-[#9C9DA6]"} />
                <span className={location.pathname === "/hqdashboard/settings" ? "text-white" : "text-[#9C9DA6]"}>Settings</span>
              </Link>
            </li>

          

          </ul>
        </nav>

        {/* Bottom Section: Dark Mode Toggle & Logout */}
        <div className="p-4 flex my-[50px] flex-col items-center space-y-4">
          {/* Theme Toggle */}
          <label
          htmlFor="theme-toggle"
          className="relative w-12 h-6 flex items-center bg-gray-300 rounded-full cursor-pointer"
        >
          <input
            id="theme-toggle"
            type="checkbox"
            checked={theme === "dark"}
            onChange={toggleTheme}
            className="sr-only"
          />
          <div
            className={`toggle-dot absolute w-6 h-6 bg-white rounded-full shadow-md transition-transform ${
              theme === "dark" ? "transform translate-x-6 bg-yellow-500" : ""
            }`}
          >
            {theme === "dark" ? (
              <FaSun className="text-white mx-auto mt-1" size={12} />
            ) : (
              <FaRegMoon className="text-gray-500 mx-auto mt-1" size={12} />
            )}
          </div>
        </label>
         <div className="pt-4">
           {/* Logout Button */}
           <Link
            to="/"
            onClick={handleLogout}
            className="flex items-center gap-2 p-3 px-[20px]  border border-[#EAEAEC] text-red-500 rounded-[8px] hover:bg-red-100"
          >
            <PiSignOut size={20} />
            <span className="text-[16px]">Logout</span>
          </Link>
         </div>
        </div>
      </div>

      {/* Overlay to close the sidebar when visible */}
      {sidebarVisible && (
        <div
          className="fixed inset-0 bg-black opacity-50 md:hidden z-40"
          onClick={() => setSidebarVisible(false)} // Close the sidebar when overlay is clicked
        ></div>
      )}
    </>
  );
};

export default Sidebar;
