import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoMdList, IoIosCheckmarkCircle, IoIosRadioButtonOff } from "react-icons/io";

function SurveyDetail() {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/surveys/survey/${surveyId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSurvey(response.data);
      } catch (error) {
        console.error("Error fetching survey:", error);
        alert("Failed to fetch survey details.");
      }
    };

    fetchSurvey();
  }, [surveyId, token]);

  if (!survey) return <p>Loading survey details...</p>;

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <button
        onClick={() => navigate(-1)}
        className="mb-4 flex items-center text-blue-600 hover:underline focus:outline-none"
      >
        <IoIosArrowBack className="mr-1" /> Back
      </button>

      {/* Survey Title and Info */}
      <h2 className="text-3xl font-semibold text-gray-800 mb-2">{survey.title}</h2>
      <p className="text-gray-500 mb-6">Created at: {new Date(survey.created_at).toLocaleString()}</p>

      {/* Questions List */}
      <div className="space-y-6">
        {survey.questions.map((question, index) => (
          <div key={question.id} className="p-4 bg-gray-100 rounded-lg shadow-md">
            <div className="flex items-center mb-2">
              <h3 className="text-lg font-semibold text-gray-700">
                Question {index + 1}: {question.question_text}
              </h3>
              <span className="ml-auto px-2 py-1 rounded-full text-sm font-medium text-white bg-blue-600">
                {question.question_type === "paragraph"
                  ? "Paragraph"
                  : question.question_type === "multi_choice"
                  ? "Multiple Choice"
                  : "Single Choice"}
              </span>
            </div>

            {/* Question Type Indicator and Choices */}
            <div className="flex items-center mt-2">
              {question.question_type === "paragraph" ? (
                <>
                  <IoMdList className="text-gray-600 mr-2 text-xl" />
                  <p className="text-gray-600">Respond in paragraph format.</p>
                </>
              ) : (
                <ul className="mt-2 ml-6 space-y-2">
                  {Object.entries(question.choices).map(([key, choice]) => (
                    <li key={key} className="flex items-center text-gray-600">
                      {question.question_type === "single_choice" ? (
                        <IoIosRadioButtonOff className="mr-2 text-blue-500" />
                      ) : (
                        <IoIosCheckmarkCircle className="mr-2 text-green-500" />
                      )}
                      <span>{choice}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SurveyDetail;
