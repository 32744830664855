import React, { useState, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import './stylings/dashboard.css';
import { FaSignOutAlt } from "react-icons/fa";
import { AuthContext } from "../context/AuthContext";

function Dashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const { logout } = useContext(AuthContext);


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.documentElement.classList.toggle('dark');
  };

  const handleLogout = () => {
    // Call your logout function here
    logout();
    setIsLogoutModalVisible(false); // Close modal after logging out
  };

  return (
    <div className={`flex w-full md:h-screen xs:min-h-screen ${theme === 'dark' ? 'dark' : ''}`}>
      <aside className={`w-64 text-white fixed inset-y-0 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-200 ease-in-out md:relative md:translate-x-0 z-10 bg-gray-900 dark:bg-gray-800`}>
        <Sidebar 
          theme={theme} 
          toggleSidebar={toggleSidebar} 
          toggleTheme={toggleTheme} 
          onLogoutRequest={() => setIsLogoutModalVisible(true)} 
        />
      </aside>
      <div className="flex-1 flex flex-col bg-[#F5F8FF] dark:bg-gray-900">
        <Topbar toggleSidebar={toggleSidebar} theme={theme} toggleTheme={toggleTheme} />
        <main className="flex-1 bg-[#F5F8FF] dark:bg-gray-700 p-4 overflow-y-scroll custom-scrollbar">
          <Outlet context={{isExpired}} />
          
          {/* Logout Confirmation Modal */}
          {isLogoutModalVisible && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white rounded-lg shadow-lg p-6 w-[300px] md:w-[400px]">
                <div className="flex flex-col items-center">
                  <FaSignOutAlt size={40} className="text-blue-500 mb-4" />
                  <h2 className="text-lg font-semibold text-gray-800 mb-2">Logout</h2>
                  <p className="text-sm text-gray-600 mb-6">Are you sure?</p>
                  <div className="flex space-x-4">
                    <button
                      onClick={() => setIsLogoutModalVisible(false)}
                      className="px-4 py-2 w-full bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleLogout}
                      className="px-4 py-2 w-full border border-gray-300 text-gray-700 rounded hover:bg-gray-100"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          <style jsx>{`
            .custom-scrollbar::-webkit-scrollbar {
              width: 0; /* Remove scrollbar space */
              background: transparent; /* Optional: just make scrollbar invisible */
            }
            .custom-scrollbar {
              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none; /* Internet Explorer 10+ */
            }
          `}</style>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
