import React from "react";
import { Outlet } from "react-router-dom";
import MainLayout from "./HQDashboard/components/dashboard";
import { BranchProvider } from "./HQDashboard/context/BranchContext";

function HQDashboardLayout() {
  return (
    <MainLayout>
      <BranchProvider>
        <Outlet />
      </BranchProvider>
    </MainLayout>
  );
}

export default HQDashboardLayout;
