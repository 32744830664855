import React from 'react';
import PropTypes from 'prop-types';
import "./LandingPageCard.css";

function LandingPageCard({ 
  title, 
  subtitle, 
  description,
  image, 
style = {} // Default parameter
}) {
  return (
    <section className="landing-page-card" style={style}>
      <div className="landing-page-card-text">
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
        <p>{description}</p>
        {/* <button className="cta-button">Learn More</button> */}
      </div>
      <div className="landing-page-card-image">
        <img src={image} alt="Effective Review" />
      </div>
    </section>
  );
}

LandingPageCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default LandingPageCard;
