import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaStar,
  FaUserPlus,
  FaSort,
  FaEllipsisH,
  FaCopy,
  FaSearch,
  FaClipboard,
  FaTimes,
  FaExclamationTriangle,
} from "react-icons/fa";
import { MdError, MdVerified } from "react-icons/md";
import { Line } from "react-chartjs-2";
import { PiFunnel } from "react-icons/pi";
import { IoCaretDownOutline, IoCaretUpOutline } from "react-icons/io5";
import EditForm from "./EditForm";
import { ToastContainer, toast } from "react-toastify";

const Employees = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10; // Items per page

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => clearTimeout(timer);
  }, [currentPage]);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/get-all/?page=${currentPage}&page_size=${pageSize}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const result = await response.json();
      console.log(result);
      setData(result.data);
      setTableData(result.data);

      setTotalPages(result.total_pages);
    } catch (error) {
      setError(error.message);
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (index) => {
    setSelectedItemIndex(index);
    // console.log(index);
    setShowPopup(true);
  };

  const handleEditFormClose = () => setShowPopup(false);

  const handleUpdateData = async (updatedValues) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }
    // console.log(data[selectedItemIndex].employee.id, "selected employee id");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/update/${data[selectedItemIndex].employee.id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updatedValues.name,
            username: updatedValues.username,
          }),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const updatedData = [...data];
      updatedData[selectedItemIndex] = {
        ...updatedData[selectedItemIndex],
        name: updatedValues.name,
        username: updatedValues.username,
      };
      setData(updatedData);
      toast.success("Employee data updated successfully!");
    } catch (error) {
      setError(error.message);
      toast.error(`Update Error: ${error.message}`);
    }
  };

  const filteredTableData =
    tableData?.filter((item) =>
      item?.employee?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  const cardData = data?.filter((item) => item?.employee) || [];

  const [isOpen, setIsOpen] = useState(false);
  const [sortOption, setSortOption] = useState("Sort by");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    name: "",
    username: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleDeleteClick = (employee) => {
    // console.log(employee.employee.id);
    setSelectedEmployee(employee.employee);
    setIsDeleteModalOpen(true); // Open the delete modal
  };

  const handleDeleteConfirm = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    setLoading(true);
    setDeletingIndex(selectedEmployee.id);

    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/delete/${selectedEmployee?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Filter out the deleted employee from data and tableData
        const updatedData = data.filter(
          (item) => item.employee.id !== selectedEmployee.id
        );
        setData(updatedData);
        setTableData(updatedData);

        toast.success(`${selectedEmployee?.name} deleted successfully!`);
      } else {
        throw new Error("Failed to delete employee");
      }
    } catch (error) {
      setError(error.message);
      toast.error(`Delete Error: ${error.message}`);
    } finally {
      setLoading(false);
      setDeletingIndex(null);
      setIsDeleteModalOpen(false); // Close the modal after deletion
      setSelectedEmployee(null); // Reset the selected employee
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false); // Close the delete modal
    setSelectedEmployee(null); // Reset the selected employee
  };

  const handleModalToggle = () => setIsModalOpen(!isModalOpen);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        "https://zola.technology/api/company/employees/add/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(employeeData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.username[0] || "Error adding employee");
        throw new Error("Network response was not ok");
      }

      // Success notification
      toast.success("Employee added successfully");
      setIsModalOpen(false);
      setEmployeeData({ name: "", username: "" });

      // Redirect to team management page
      setTimeout(() => navigate("/dashboard/members"), 500); // Wait for 2 seconds before redirecting
    } catch (error) {
      // console.error("Error adding employee:", error);
      toast.error("Error adding employee");
    }
  };

  // Ensure scrolling is locked properly when the modal is open
  useEffect(() => {
    const lockScroll = () => {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden"; // Lock html as well
    };

    const unlockScroll = () => {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto"; // Restore html scroll
    };

    if (isModalOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }

    // Cleanup to ensure scrolling is restored when component unmounts
    return () => unlockScroll();
  }, [isModalOpen]);

  const labels = data.map((item) => item.employee?.username || "");
  const chartdata = data.map((item) => item.employee?.number_of_taps || 0);

  // Chart data for Chart.js
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Performance",
        data: chartdata,
        borderColor: "#22c55e",
        backgroundColor: "rgba(34, 197, 94, 0.2)",
        pointBorderColor: "#22c55e",
        pointBackgroundColor: "#22c55e",
        tension: 0.4, // Smooth curves
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleSelect = (option) => {
    setSortOption(option); // Set the selected sort option
    setIsOpen(false); // Close the dropdown after selecting

    let sortedData = [...tableData];

    switch (option) {
      case "Date":
        sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
        break;

      case "High performance":
        sortedData.sort((a, b) => b.performance_score - a.performance_score);
        break;

      case "Name":
        sortedData.sort((a, b) =>
          a.employee.name.localeCompare(b.employee.name)
        );
        break;

      case "Issues":
        sortedData.sort((a, b) => b.issues - a.issues);
        break;

      case "Engagement":
        sortedData.sort(
          (a, b) => b.employee.number_of_taps - a.employee.number_of_taps
        );
        break;

      case "Rewards":
        sortedData.sort((a, b) => b.reward_total - a.reward_total);
        break;

      default:
        break;
    }

    setTableData(sortedData); // Update tableData with the sorted data
  };

  return (
    <div className="p-6 min-h-screen">
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        pauseOnHover
      />
      {/* Header Section */}
      <div className="flex items-start justify-between mb-6">
        <h2 className="text-2xl font-semibold">Employees</h2>
        <button
          onClick={handleModalToggle}
          className="bg-blue-600 text-white px-4 py-2 rounded-md shadow-sm flex items-center gap-2 hover:bg-blue-700 transition"
        >
          <FaUserPlus /> Add Employee
        </button>
      </div>

      {/* Add Employee Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl overflow-hidden w-[90%] max-w-md relative">
            <div className="bg-blue-500 p-4">
              <h2 className="text-white text-2xl">Add Employee</h2>
            </div>
            <form className="space-y-4 mt-4 p-4">
              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={employeeData.name}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  value={employeeData.username}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex justify-end space-x-2 mt-6">
                <button
                  type="button"
                  className="bg-red-500 text-white px-4 py-2 rounded-lg shadow-sm hover:bg-red-600 transition"
                  onClick={handleModalToggle}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-sm hover:bg-blue-700 transition"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {/* Employee Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        {cardData.length === 0 ? (
          <p className="text-gray-500 text-center col-span-full">
            Employees details will appear here
          </p>
        ) : (
          cardData.map((item, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-xl flex flex-col items-start space-y-3 cursor-pointer hover transition"
              onClick={() =>
                navigate(`/dashboard/employee-profile/${item.employee.id}`)
              }
            >
              <div className="flex items-center gap-2">
                <p className="w-16 h-16 rounded-full flex items-center justify-center text-white font-bold text-xl bg-blue-600">
                  {`${item.employee.name.split(" ")[0][0]}${
                    item.employee.name.split(" ")[1]
                      ? item.employee.name.split(" ")[1][0]
                      : ""
                  }`}
                </p>

                <h3 className="text-lg font-semibold text-center">
                  {item.employee.name?.split(" ")[0]} <br />
                  {item.employee.name?.split(" ")[1]}
                </h3>
              </div>
              <div
                className={`text-lg flex items-center space-x-2 ${
                  item.performance_label === "Poor Employee"
                    ? "bg-red-100 text-red-600"
                    : "bg-blue-100 text-blue-600"
                } px-3 py-1 rounded-full`}
              >
                <div className="flex items-center space-x-1 rounded-full">
                  {item.performance_label === "Poor Employee" ? (
                    <MdError className="text-red-600" />
                  ) : (
                    <MdVerified className="text-blue-600" />
                  )}
                  <span>{item.performance_label}</span>
                </div>
              </div>
              <div className="flex items-center space-x-2 mt-1">
                <span className="text-gray-700 font-medium text-xl">
                  {item.employee.number_of_taps}
                  <span className="text-sm ml-2">taps</span>
                </span>
                <div className="flex items-center space-x-1">
                  <span className="text-gray-700 font-medium text-xl">
                    {item.average_rating}
                  </span>
                  <FaStar className="text-yellow-500" />
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Chart Section */}
      <div className="bg-white p-6 rounded-lg mb-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold">Employee Overview</h3>
          <div className="relative">
            <select
              className="bg-gray-100 px-4 py-2 rounded-md text-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
              defaultValue="Monthly"
            >
              <option value="Monthly">Monthly</option>
              {/* <option value="Weekly">Weekly</option>
              <option value="Daily">Daily</option> */}
            </select>
          </div>
        </div>
        <div className="w-full h-64">
          <Line data={chartData} options={chartOptions} />
        </div>
      </div>

      {/* Employee Table */}
      <div>
        <div className="bg-white p-4 flex justify-between items-center">
          <h3 className="text-lg font-semibold">Employees</h3>
          <div className="flex items-center space-x-4">
            {/* Sort Dropdown */}
            <div className="relative inline-block text-left">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center space-x-2 p-2 border border-gray-300 rounded-lg bg-white text-gray-700 hover:bg-gray-100 transition"
              >
                <PiFunnel className="text-lg" />
                <span>{sortOption}</span>
                {isOpen ? (
                  <IoCaretUpOutline className="text-sm" />
                ) : (
                  <IoCaretDownOutline className="text-sm" />
                )}
              </button>

              {isOpen && (
                <div className="absolute z-10 mt-2 w-48 bg-white rounded-lg">
                  <ul className="py-1 text-sm text-gray-700">
                    {[
                      "Date",
                      "High performance",
                      "Name",
                      "Issues",
                      "Engagement",
                      "Rewards",
                    ].map((option) => (
                      <li
                        key={option}
                        onClick={() => handleSelect(option)}
                        className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Search Input */}
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className="px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FaSearch className="absolute right-3 top-3 text-gray-500" />
            </div>

            <button className="bg-gray-100 px-4 py-2 rounded-md hover:bg-gray-200 transition">
              See all
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-separate border-spacing-y-3">
            <thead>
              <tr className="text-left text-gray-600 text-sm">
                <th className="px-4 py-2 font-normal">S/N</th>
                <th className="px-4 py-2 font-normal">Name</th>
                <th className="px-4 py-2 font-normal">Card URL</th>
                <th className="px-4 py-2 font-normal">Feedback</th>
                <th className="px-4 py-2 font-normal">Rewards</th>
                <th className="px-4 py-2 font-normal">Rating</th>
                <th className="px-4 py-2 font-normal">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredTableData.map((item, index) => (
                <tr
                  key={index}
                  className="bg-white text-gray-700 rounded-lg hover transition"
                >
                  <td className="px-4 py-2">{index + 1}</td>
                  {/* Name with Avatar */}
                  <td className="px-4 py-2 flex items-center gap-2">
                    <p className="w-16 h-16 rounded-full flex items-center justify-center text-white font-bold text-xl bg-blue-600">
                      {`${item.employee.name.split(" ")[0][0]}${
                        item.employee.name.split(" ")[1]
                          ? item.employee.name.split(" ")[1][0]
                          : ""
                      }`}
                    </p>
                    <span>{item.employee.name}</span>
                  </td>
                  {/* Card URL with Copy Icon */}
                  <td className="px-4 py-2">
                    <div className="flex items-center gap-2">
                      <a
                        href={item.employee.employee_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        {item.employee.employee_url}
                      </a>
                      <button
      className="text-gray-400 hover:text-gray-600"
      onClick={() => {
        navigator.clipboard.writeText(item.employee.employee_url)
          .then(() => {
            // Show the toast notification after successfully copying
            toast.success("Employee URL copied to clipboard!");
            alert("Link copied succesffully")
          })
          .catch(() => {
            // Optional: Show error if copying fails
            toast.error("Failed to copy URL to clipboard.");
          });
      }}
    >
      <FaClipboard />
    </button>
                    </div>
                  </td>
                  <td className="px-4 py-2">{item.total_feedback}</td>
                  <td className="px-4 py-2">
                    {item.reward_currency} {item.reward_total}
                  </td>
                  {/* Rating with Star Icon */}
                  <td className="px-4 py-2">
                    <div className="flex items-center gap-2">
                      <p>{item.average_rating}</p>
                      <FaStar className="text-yellow-500" />
                    </div>
                  </td>
                  {/* Action Buttons */}
                  <td className="px-4 py-2 space-x-2">
                    <button
                      className="px-3 py-1 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 transition"
                      onClick={() => handleUpdate(index)}
                    >
                      Update
                    </button>
                    <button
                      onClick={() => handleDeleteClick(item)}
                      className="px-3 py-1 bg-red-100 text-red-500 rounded-md shadow-sm hover:bg-red-600 transition"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showPopup && selectedItemIndex !== null && data[selectedItemIndex] ? (
        <EditForm
          selectedItem={data[selectedItemIndex]?.employee} // Use optional chaining
          onUpdate={handleUpdateData}
          onClose={handleEditFormClose}
        />
      ) : null}

      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4">
            <FaExclamationTriangle className="text-red-500 text-5xl mx-auto" />
            <h2 className="text-2xl font-semibold">Delete Employee?</h2>
            <p className="text-gray-600">This action cannot be undone.</p>
            <div className="flex justify-center space-x-4 mt-4">
              <button
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition"
                onClick={handleDeleteCancel}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                onClick={handleDeleteConfirm}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Employees;
