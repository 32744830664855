import axios from 'axios';

const GOOGLE_PLACES_API_URL = 'https://zola.technology/api/places/autocomplete';

export const fetchPlacePredictions = async (input) => {
  try {
    const response = await axios.get(GOOGLE_PLACES_API_URL, {
      params: {
        input: input,
      },
    });
    return response.data || [];
  } catch (error) {
    console.error('Error fetching place predictions:', error);
    return [];
  }
};

