import React from 'react';
import { useParams, Link } from 'react-router-dom';

const helpContent = {
  1: { 
    title: 'Getting Started', 
    details: 'This guide helps you to get started with your new account. Follow these steps to set up your profile, add team members, and configure your settings for the best experience.',
    image: 'https://via.placeholder.com/600x300?text=Getting+Started+Guide',  // Example image URL
    video: 'https://www.youtube.com/embed/dQw4w9WgXcQ',  // Example YouTube video URL
    links: [
      { text: 'Profile Setup Guide', url: 'https://example.com/profile-setup' },
      { text: 'Team Management Tips', url: 'https://example.com/team-management' }
    ]
  },
  2: { 
    title: 'Managing Your Profile', 
    details: 'To update your profile, navigate to the settings page and modify your information as needed. Remember to save your changes before leaving the page.',
    image: 'https://via.placeholder.com/600x300?text=Managing+Your+Profile',
    video: null, // No video for this topic
    links: [
      { text: 'Profile Update FAQ', url: 'https://example.com/profile-faq' }
    ]
  },
  3: { 
    title: 'Understanding Analytics', 
    details: 'The analytics dashboard provides insights on your performance, including key metrics and trends. Learn to interpret the data to make informed decisions.',
    image: 'https://via.placeholder.com/600x300?text=Analytics+Overview',
    video: 'https://www.youtube.com/embed/xT1vWG0SQ58',
    links: []
  },
  4: { 
    title: 'Team Management', 
    details: 'To manage your team, you can add or remove members in the Team Management page. Set roles and permissions for each user to control access.',
    image: null,
    video: 'https://www.youtube.com/embed/LXb3EKWsInQ',
    links: [
      { text: 'Team Management Guide', url: 'https://example.com/team-guide' },
      { text: 'Roles and Permissions Explained', url: 'https://example.com/roles-permissions' }
    ]
  },
  5: { 
    title: 'Billing and Subscriptions', 
    details: 'Go to the Billing page to update your payment details and review your subscription plan. Contact support for any billing-related questions.',
    image: 'https://via.placeholder.com/600x300?text=Billing+and+Subscriptions',
    video: null,
    links: []
  },
  6: { 
    title: 'FAQs and Troubleshooting', 
    details: 'If you encounter an issue, check out the FAQs section or contact support for assistance. Our support team is available 24/7.',
    image: 'https://via.placeholder.com/600x300?text=FAQs+and+Troubleshooting',
    video: 'https://www.youtube.com/embed/6ZfuNTqbHE8',
    links: [
      { text: 'Common Issues', url: 'https://example.com/common-issues' },
      { text: 'Support Contact', url: 'https://example.com/contact-support' }
    ]
  }
};
function HelpDetails() {
  const { id } = useParams();
  const topic = helpContent[id];

  if (!topic) {
    return <div>Topic not found</div>;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">{topic.title}</h1>
      
      {/* Topic Details */}
      <p className="text-gray-700 mb-6">{topic.details}</p>

      {/* Render Image if Available */}
      {topic.image && (
        <img src={topic.image} alt={topic.title} className="mb-6 w-full h-auto rounded-lg shadow-sm" />
      )}

      {/* Render Video if Available */}
      {topic.video && (
        <div className="mb-6">
          <iframe
            width="100%"
            height="400"
            src={topic.video}
            title={topic.title}
            className="rounded-lg shadow-md"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}

      {/* Render External Links if Available */}
      {topic.links && topic.links.length > 0 && (
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Related Links</h2>
          <ul className="list-disc pl-6">
            {topic.links.map((link, index) => (
              <li key={index} className="text-blue-600 hover:underline">
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Back to Help Topics Link */}
      <Link to="/dashboard/help" className="text-blue-600 hover:underline">
        &larr; Back to Help Topics
      </Link>
    </div>
  );
}

export default HelpDetails;
