import React from 'react';
import TeamManagement from '../../../companydashboard/TeamManagement/TeamManagement'
const Members = () => {
  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 dark:text-gray-300">Members</h3>
      <TeamManagement/>
    </div>
  );
};

export default Members;
