import { useState, useEffect } from "react";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import Timeline from "./Timeline";
import { registerUser } from "../../api";

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    industryType: "",
    businessIndustry: "",
    numberOfEmployees: "",
    websiteURL: "",
    companyName: "",
    username: "",
    numberOfCards: "",
    numberOfStands: "",
  });

  useEffect(() => {
    // Fetch user data from localStorage and set form data
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setFormData({
        firstName: userData.first_name || "",
        lastName: userData.last_name || "",
        email: userData.email || "",
        phoneNumber: userData.phone_number || "",
        password: userData.password || "",
        industryType: "",
        businessIndustry: "",
        numberOfEmployees: "",
        websiteURL: "",
        companyName: "",
        username: "",
        numberOfCards: "",
        numberOfStands: "",
      });
    }
  }, []);

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  // const handlePhoneChange = (value) => {
  //   setFormData({ ...formData, phoneNumber: value });
  // };


  const nextStep = (url) => {
    if (url) {
      setPaymentUrl(url);
    }
    setCurrentStep(currentStep + 1);
  };


  // const nextStep = () => {
  //   setCurrentStep(currentStep + 1);
  // };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async () => {
    try {
      const response = await registerUser({
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone_number: formData.phoneNumber,
        password: formData.password,
        industry_type: formData.industryType,
        business_industry: formData.businessIndustry,
        number_of_employees: formData.numberOfEmployees,
        website_url: formData.websiteURL,
        company_name: formData.companyName,
        username: formData.username,
        number_of_cards: formData.numberOfCards,
        number_of_stands: formData.numberOfStands,
      });
      console.log("Company created:", response.data);
      // Handle success, navigate or perform other actions
    } catch (error) {
      console.error("Error creating company:", error.message);
    }
  };

  return (
    <div className="mt-[5rem] flex">
      <Timeline currentStep={currentStep} />

      {/* {currentStep === 1 && (
        <StepOne
          nextStep={nextStep}
          handlePhoneChange={handlePhoneChange}
          handleChange={handleChange}
          formData={formData}
        />
      )} */}
      {currentStep === 1 && (
        <StepTwo
          nextStep={nextStep}
          handleChange={handleChange}
          formData={formData}
        />
      )}
      {currentStep === 2 && (
        <StepThree
          prevStep={prevStep}
          handleSubmit={handleSubmit}
          nextStep={nextStep}
          handleChange={handleChange}
          formData={formData}
        />
      )}
      {currentStep === 3 && <StepFour url={paymentUrl} />}
    </div>
  );
};

export default MultiStepForm;
