import React from 'react'
import DataDrivenCard from '../Data-Driven-Card/DataDrivenCard'
import CentralizeFeedback from '../Centralize-Feedback/CentralizeFeedback'
import HowItWorks from '../How-It-Works/HowItWorks'

function Features() {
  return (
    <>
      <HowItWorks />
      <CentralizeFeedback />
      <DataDrivenCard />
    </>
  )
}

export default Features