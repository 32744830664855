import React, { useState } from "react";
import zolaLogo from './zola.png';

const PricingComponent = () => {
  const [employees, setEmployees] = useState(1);
  const [locations, setLocations] = useState(1);

  const calculatePrice = () => {
    if (employees >= 30 || locations > 1) {
      return "Contact Us";
    }
    return `$${employees * 5 + locations * 10}/month`;
  };

  const handleEmployeesChange = (e) => {
    setEmployees(parseInt(e.target.value));
  };

  const handleLocationsChange = (e) => {
    setLocations(parseInt(e.target.value));
  };

  return (
    <div className="bg-blue-300 py-16">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-white">Pricing that grows with you</h1>
      </div>

      <div className="max-w-lg mx-auto bg-pink-50 rounded-xl shadow-lg p-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-gray-900">Zola Pricing</h2>
          <img
            src={zolaLogo}
            alt="Zola icon"
            className="w-[120px] object-contain"
          />
        </div>

        <p className="text-gray-600 text-start mb-6">
          Use the sliders below to get your custom price.
        </p>

        {/* Employees Slider */}
        <div className="mb-4">
          <label className="block text-lg font-medium text-gray-700 mb-2">
            Number of Employees:
          </label>
          <input
            type="range"
            min="1"
            max="30"
            value={employees}
            onChange={handleEmployeesChange}
            className="w-full h-2 bg-blue-400 rounded-lg appearance-none cursor-pointer"
          />
          <div className="flex justify-between mt-2 text-sm text-gray-600">
            <span>Employees: {employees}</span>
            <span>30+</span>
          </div>
        </div>

        {/* Locations Slider */}
        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2">
            Number of Locations:
          </label>
          <input
            type="range"
            min="1"
            max="2"
            value={locations}
            onChange={handleLocationsChange}
            className="w-full h-2 bg-blue-400 rounded-lg appearance-none cursor-pointer"
          />
          <div className="flex justify-between mt-2 text-sm text-gray-600">
            <span>Locations: {locations}</span>
            <span>2+</span>
          </div>
        </div>

        {/* Pricing and Button */}
        <div
          className={`flex justify-between items-center px-6 py-4 rounded-b-xl ${
            employees >= 30 || locations > 1 ? "bg-blue-800" : "bg-blue-500"
          } text-white`}
        >
          <div className="text-2xl font-bold">{calculatePrice()}</div>
          <button
            className={`px-6 py-2 rounded-lg font-semibold ${
              employees >= 30 || locations > 1
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-white text-purple-600 hover:bg-gray-100"
            }`}
            disabled={employees >= 30 || locations > 1}
          >
            {employees >= 30 || locations > 1 ? "Contact Us" : "Start Free Trial"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
