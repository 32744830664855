import { fontWeight } from '@mui/system';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";


const fetchPerformanceData = async () => {
  const token = localStorage.getItem('token');
  const response = await fetch('https://zola.technology/api/company/employees/performance/get-spread-taps/?filter=year', {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

const OverallPerformance = () => {
  const [chartData, setChartData] = useState(null);
  const [isMobileScreen, setIsMobileScreen] = useState(window.matchMedia("(max-width: 768px)").matches);


  useEffect(() => {
    const getData = async () => {
      const data = await fetchPerformanceData();
      setChartData(data);
    };

    const handleResize = () => {
      setIsMobileScreen(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener('resize', handleResize);
    getData();
    handleResize(); // Call once to set initial state

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!chartData) {
    return (
      <div className="p-4 sm:p-6 bg-gray-100 dark:bg-gray-900 rounded-lg max-w-full shadow-lg border border-gray-300 dark:border-gray-700">
        <div className="animate-pulse flex flex-col space-y-4 h-72 sm:h-96">
          <div className="h-full bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500 rounded"></div>
        </div>
      </div>
    );
  }

  // Extract only the month name without the year
  const categories = chartData.periods.map(period => period.period.split(' ')[0]);
  const series = [
    {
      name: 'Taps',
      data: chartData.periods.map(period => period.taps),
    },
  ];

  const options = {
    chart: {
      type: 'area',
      height: 350,
      toolbar: { show: false },
    },
    stroke: {
      curve: 'straight', // Set the curve to straight
      width: 2, // Set the line thickness
    },
    dataLabels: { enabled: false },
    tooltip: { y: { formatter: val => `${val}%` } },
    xaxis: {
      categories,
      labels: { style: { colors: '#6F707E', fontSize: '14px' , fontWeight: 'medium' } },
    },
    yaxis: {
      labels: {
        formatter: val => `${val}%`,
        style: { colors: '#6F707E', fontSize: '14px' },
      },
    },

    grid: {
      show: false,
    },
    colors: ['#22c55e'],
    markers: {
      size: 0, // Remove the circle dots
      colors: ['#22c55e'],
      strokeWidth: 2,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.2,
        gradientToColors: ['#22c55e'],
        inverseColors: false,
        opacityFrom: 0.3,
        opacityTo: 0,
        stops: [0, 200],
      },
    },
  };

  return (
    <div className="p-4 sm:p-6 bg-white rounded-lg max-w-full xs:w-full ">
      <div className="flex justify-between items-center mb-4">
        <div className='flex'>
          <h2 className="text-[16px] font-semibold text-[#292D32]">Performance</h2>
        {/* {  <span className=" text-[#00BA34] flex items-center">
            <TiArrowSortedUp className="w-6 h-6" />
            <p className="text-[16px] font-semibold">25%</p>
            </span>} */}
        </div>
      {/* {  <div className='flex space-x-2 W-5/12 border text-[#282A3E] rounded-[4px] border-[#EAEAEC] px-2 text-[16px] h-[40px] items-center'>
          <button className='flex'>
            Monthly
            <TiArrowSortedDown className="w-6 h-6" />
          </button>

        </div>} */}
      </div>
      <p className="text-sm text-gray-600 mb-4">Performance overview</p>
      <div className="md:w-full xs:w-full">
        <ReactApexChart options={options} series={series} type="area" height={300} width={isMobileScreen ? "100%" : "100%"}/>
      </div>
    </div>
  );
};

export default OverallPerformance;
