import PropTypes from 'prop-types';
import '../stylings/header.css'
import { FaArrowLeft } from "react-icons/fa6";
const DashboardHeader = ({ title }) => {
  return (<div className='flex space-x-4  items-center text-[#454861] pb-4'>
    <FaArrowLeft className='w-[18px] h-[32px]' />

    <h1 className="text-[24px] font-medium dark:text-white ">{title}</h1>
  </div>);
};

DashboardHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default DashboardHeader