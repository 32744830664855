import { FaStar, FaChartLine } from "react-icons/fa";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import ganesh from "./ganesh.jpeg";

function CustomerResults() {
  // Animation controls
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2, // Trigger animation when 20% of the section is in view
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <section
      ref={ref} // Attach the ref to the section
      className="py-12 h-auto max-w-[1340px] mx-auto font-figtree p-4 my-8"
    >
      <motion.h2
        className="text-[38px] font-semibold text-center mb-8"
        initial="hidden"
        animate={controls}
        variants={itemVariants}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        Results from our customers
      </motion.h2>

      <motion.div
        className="flex flex-col gap-8 lg:flex-row justify-between mx-4 lg:mx-0 items-center"
        variants={containerVariants}
        initial="hidden"
        animate={controls}
      >
        {/* Stats Section */}
        <motion.div
          className="flex flex-col gap-4 sm:gap-6 bg-indigo-50 p-4 sm:p-6 lg:p-12 rounded-lg w-full sm:w-[80%] lg:w-[43%] mb-4 lg:mb-0"
          variants={itemVariants}
          whileHover={{ scale: 1.05 }}
        >
          <div className="flex items-center px-4 sm:px-6 lg:px-8 bg-white py-4 sm:py-6 lg:py-8 rounded-xl">
            <div className="flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 rounded-full bg-yellow-200">
              <FaStar className="text-yellow-500" />
            </div>
            <div className="ml-2 sm:ml-4">
              <p className="text-xl sm:text-2xl lg:text-3xl font-bold">800+</p>
              <p className="text-xs sm:text-sm text-gray-600">
                Reviews Generated
              </p>
            </div>
          </div>

          <div className="flex items-center px-4 sm:px-6 lg:px-8 bg-white py-4 sm:py-6 lg:py-8 rounded-xl">
            <div className="flex items-center justify-center h-10 w-10 sm:h-12 sm:w-12 rounded-full bg-green-200">
              <FaChartLine className="text-green-500" />
            </div>
            <div className="ml-2 sm:ml-4">
              <p className="text-xl sm:text-2xl lg:text-3xl font-bold">500%+</p>
              <p className="text-xs sm:text-sm text-gray-600">ROI</p>
            </div>
          </div>
        </motion.div>

        {/* Testimonial Section */}
        <motion.div
          className="lg:w-1/2 w-full text-center lg:text-left"
          variants={itemVariants}
        >
          <div className="flex items-center justify-center lg:justify-start">
            <img
              src={ganesh}
              alt="Russo's Logo"
              className="h-24 mb-4 lg:mb-0 mr-4"
            />
            <div>
              {/* <p className="font-normal text-2xl">Michael Miller</p> */}
              <p className="text-lg text-gray-500">Director of Operations</p>
            </div>
          </div>
          <p className="mt-4 text-gray-700 text-2xl">
            Zola has transformed our client interactions. We now collect
            valuable feedback in person, allowing us to enhance our services and
            showcase our excellence online.
          </p>
        </motion.div>
      </motion.div>
    </section>
  );
}

export default CustomerResults;
