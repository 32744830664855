import { Link } from 'react-router-dom';

const SignupSuccess = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log(userData);
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100 pt-[18rem] md:pt-[7rem]">
      <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden p-6 space-y-4">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Welcome, {userData.first_name}! You're Almost There!
        </h2>
        <p className="text-gray-700 leading-relaxed">
          Thanks for joining Zola! Unlock premium features and connect with our expert community. Here's what's waiting for you:
        </p>

        <div className="mt-4 space-y-2">
          <p className="text-gray-700">
            <span className="font-bold">In-Depth Reviews:</span> Make informed choices with comprehensive, unbiased reviews.
          </p>
          <p className="text-gray-700">
            <span className="font-bold">Expert Insights:</span> Get personalized advice and recommendations.
          </p>
          <p className="text-gray-700">
            <span className="font-bold">Engaged Community:</span> Share tips and experiences with like-minded members.
          </p>
        </div>
        
        <p className="text-gray-700 leading-relaxed mt-4">
          Ready to dive in? Click below to complete your payment and start exploring!
        </p>
        
        <div className="mt-6">
          <Link
            to="/setup-form"
            className="flex justify-center items-center bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg mr-4"
          >
            Proceed to Payment
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignupSuccess;
