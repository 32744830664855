import { createContext, useContext, useState } from "react";

const NFCContext = createContext();

export const NFCProvider = ({ children }) => {
  const [nfcData, setNfcData] = useState({
    company_username: "",
    employee_username: "",
  });

  return (
    <NFCContext.Provider value={{ nfcData, setNfcData }}>
      {children}
    </NFCContext.Provider>
  );
};

export const useNFC = () => useContext(NFCContext);
