import React from 'react';
import { Link, Outlet } from 'react-router-dom';

function HelpAndSupport() {
  const helpTopics = [
    { id: 1, title: 'Getting Started', description: 'Learn how to set up your account and start using the platform.' },
    { id: 2, title: 'Managing Your Profile', description: 'Understand how to update your profile information.' },
    { id: 3, title: 'Understanding Analytics', description: 'Learn how to navigate and use the analytics dashboard.' },
    { id: 4, title: 'Team Management', description: 'Get tips on how to manage and assign team members effectively.' },
    { id: 5, title: 'Billing and Subscriptions', description: 'Find out how to manage your billing information.' },
    { id: 6, title: 'FAQs and Troubleshooting', description: 'Browse common questions and solutions for issues.' }
  ];

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Help & Support</h1>
      <p className="mb-6 text-gray-600">Select a topic below to learn more:</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {helpTopics.map((topic) => (
          <Link
            key={topic.id}
            to={`/dashboard/help/${topic.id}`}
            className="p-4 bg-blue-100 rounded-lg hover:bg-blue-200 transition-colors shadow-sm"
          >
            <h2 className="text-xl font-semibold text-blue-600">{topic.title}</h2>
            <p className="text-gray-700">{topic.description}</p>
          </Link>
        ))}
      </div>

      {/* The child route component (HelpDetails) will render here */}
      <Outlet />
    </div>
  );
}

export default HelpAndSupport;
