import React, { useState } from 'react';
import smileyVideo from './smile.mp4';
import { motion } from 'framer-motion';
import ContactModal from './ContactModal'; // Import the ContactModal component

function CustomersHome() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="relative h-screen overflow-hidden bg-gray-50">
      {/* Background Video */}
      <div className="absolute inset-0 z-0">
        <video
          className="w-full h-full object-cover"
          src={smileyVideo}
          autoPlay
          loop
          muted
          playsInline
        />
      </div>

      {/* Overlay Background for Readability */}
      <div className="absolute inset-0 bg-gray-900 opacity-60 z-10"></div>

      {/* Content Section */}
      <motion.div
        className="relative z-20 p-8 rounded-lg shadow-md h-full flex flex-col items-center justify-center"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
      >
        {/* Main Heading */}
        <motion.div
          className="text-center max-w-4xl mx-auto"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <h2 className="text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-white leading-tight mb-4">
            Transform Customer Experiences with Actionable Insights
          </h2>
          <p className="text-lg md:text-xl text-gray-300 mb-8">
            Zola makes it easy to capture customer feedback and turn it into meaningful actions that drive growth and satisfaction.
          </p>
        </motion.div>

        {/* Call to Action Buttons */}
        <motion.div
          className="flex space-x-6 mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <button className="bg-gradient-to-r from-blue-500 to-teal-400 text-white px-8 py-4 rounded-full font-semibold hover:scale-105 transform transition-all duration-300">
            Register with Us
          </button>
          <button
            onClick={handleOpenModal} // Open modal on click
            className="border border-teal-400 text-teal-400 px-8 py-4 rounded-full font-semibold hover:scale-105 hover:bg-teal-400 hover:text-white transform transition-all duration-300"
          >
            Contact Us
          </button>
        </motion.div>

        {/* Key Features Section */}
        <motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <motion.div
            className="p-6 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg shadow-md hover:shadow-xl transition-all transform hover:scale-105"
            whileHover={{ scale: 1.05 }}
          >
            <h4 className="text-2xl font-bold text-teal-400">Instant Feedback Analysis</h4>
            <p className="text-gray-100 mt-4">
              Get real-time insights into customer sentiments with detailed analytics.
            </p>
          </motion.div>
          <motion.div
            className="p-6 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg shadow-md hover:shadow-xl transition-all transform hover:scale-105"
            whileHover={{ scale: 1.05 }}
          >
            <h4 className="text-2xl font-bold text-teal-400">Customized Reports</h4>
            <p className="text-gray-100 mt-4">
              Generate detailed, visual reports tailored to your business needs.
            </p>
          </motion.div>
          <motion.div
            className="p-6 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg shadow-md hover:shadow-xl transition-all transform hover:scale-105"
            whileHover={{ scale: 1.05 }}
          >
            <h4 className="text-2xl font-bold text-teal-400">Integrations</h4>
            <p className="text-gray-100 mt-4">
              Seamlessly integrate with platforms like Slack, CRM tools, and more.
            </p>
          </motion.div>
        </motion.div>
      </motion.div>

      {/* Contact Modal */}
      <ContactModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
}

export default CustomersHome;
