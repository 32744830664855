import { useState, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import { IoMdArrowDropup } from "react-icons/io";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const BarGraph = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [totalReviews, setTotalReviews] = useState(0);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("Token not found");
        }

        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/filter/get-filtered-taps/?filter=year&year=${selectedYear}`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        console.log(data);

        if (data && data.periods) {
          setReviews(data.periods); // Update reviews with the periods data
          setTotalReviews(data.total_filtered_taps); // Set total filtered taps
        } else {
          throw new Error("Invalid data format");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedYear]);

  // Modify the data variable for the chart
  const data = useMemo(() => {
    const counts = reviews.map((period) => period.taps);

    return {
      labels: reviews.map((period) => period.period.split(" ")[0]), // Extract month name from period
      datasets: [
        {
          label: "Reviews (%)",
          data: counts.map((count) =>
            totalReviews ? (count / totalReviews) * 100 : 0
          ),
          borderColor: "#34D399",
          pointRadius: 5,
          pointBackgroundColor: "#34D399",
          tension: 0.3,
          fill: true,
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              return null;
            }

            const gradient = ctx.createLinearGradient(
              0,
              chartArea.top,
              0,
              chartArea.bottom
            );
            gradient.addColorStop(0, "rgba(52, 211, 153, 0.5)");
            gradient.addColorStop(1, "rgba(52, 211, 153, 0.1)");
            return gradient;
          },
        },
      ],
    };
  }, [reviews, totalReviews]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw.toFixed(2)}%`,
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: { callback: (value) => `${value}%` },
        grid: { display: false },
      },
    },
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
              Most Taps
            </h3>
            <span className="text-gray-600 dark:text-gray-300">
              Active Cards
            </span>
          </div>
          <div className="flex items-center gap-2">
            <IoMdArrowDropup className="text-xl text-green-500" />
            <span className="text-xl font-medium text-green-500">25%</span>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {/* <button
            onClick={() => setSelectedYear(selectedYear - 1)}
            className="bg-gray-700 text-white rounded px-4 py-2 text-sm hover:bg-gray-600 transition-colors dark:bg-gray-600 dark:hover:bg-gray-500"
          >
            Previous
          </button> */}
          <span className="text-lg text-gray-800 dark:text-white">
            {/* Year: {selectedYear} */}
            Year: 2024
          </span>
          {/* <button
            onClick={() => setSelectedYear(selectedYear + 1)}
            className="bg-gray-700 text-white rounded px-4 py-2 text-sm hover:bg-gray-600 transition-colors dark:bg-gray-600 dark:hover:bg-gray-500"
          >
            Next
          </button> */}
        </div>
      </div>

      <div className="w-full h-[300px]">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default BarGraph;
