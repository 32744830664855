import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Topbar from "./topbar/Topbar";

const MainLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const location = useLocation(); 

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.documentElement.classList.toggle('dark');
  };
  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  // Check if the current route matches /messages
  const isMessagesRoute = location.pathname.includes("/messages");

  return (
    <div className={`flex w-full min-h-screen ${theme === 'dark' ? 'dark' : ''}`}>
      {/* Sidebar */}
      <Sidebar 
        mobileOpen={mobileOpen} 
        setMobileOpen={setMobileOpen} 
        theme={theme}
        toggleTheme={toggleTheme}
      />

      {/* Main Content */}
      <div 
        className={`flex-1 flex flex-col transition-all duration-300 
          ${mobileOpen ? "ml-64" : "ml-0"} lg:ml-64`}
      >
        {/* Topbar */}
        <Topbar 
          isClosing={isClosing} 
          mobileOpen={mobileOpen} 
          setMobileOpen={setMobileOpen} 
        />

        {/* Children */}
        <main
          className={`flex-1 ${
            isMessagesRoute ? "" : "p-6 bg-[#F5F8FF] dark:bg-gray-900"
          }`}
        >
          {children}
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
