import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ComingSoon from "./ComingSoon";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const MobileDataPresent = ({
  bgColor,
  coverImage,
  companyLogo,
  welcomeMessage,
  visitMessage,
  buttons,
  textColor,
  handleLeaveReview,
  facebookUrl,
  instagramUrl,
  twitterUrl,
}) => {
  const [showSocialIcons, setShowSocialIcons] = useState(false);

  return (
    <div
      className="relative overflow-hidden h-[100dvh] max-w-md mx-auto transition duration-300 transform"
      style={{ backgroundColor: bgColor }}
    >
      <div className="relative">
        <img
          src={coverImage}
          alt="Company Cover"
          className="w-full h-48 object-cover"
        />
        <img
          src={companyLogo}
          alt="Company Logo"
          // className="absolute -bottom-8 left-6 w-20 h-20 rounded-full border-4 border-white shadow-xl object-contain bg-white"
          className="absolute -bottom-16 left-4 bg-white w-36 h-36 rounded-full border-4 border-gray-200 dark:border-gray-600 object-contain"
        />
      </div>

      <div className="mt-24 text-center px-6" style={{ color: textColor }}>
        <h1 className="text-3xl font-bold mb-2">{welcomeMessage}</h1>
        <p className="text-lg mb-8">{visitMessage}</p>
      </div>

      <div className="flex flex-col items-center mb-8 px-6">
        {buttons &&
          Object.values(buttons).map((button, index) => {
            // Check if the button is the "Follow Us On Social Media" button
            if (button.label === "Follow Us On Social Media") {
              // Check if social media URLs are empty
              const socialMedia = button.socialMedia || {};
              const hasSocialMediaLinks =
                socialMedia.facebook ||
                socialMedia.instagram ||
                socialMedia.twitter;

              if (!hasSocialMediaLinks) {
                return null; // Don't render the button if no URLs are found
              }
            }

            return (
              <button
                key={index}
                className="p-4 mb-4 text-lg font-medium rounded-md transition duration-200 transform hover:scale-105 shadow-md hover:shadow-lg"
                style={{
                  backgroundColor: button.bgColor || "#1E40AF",
                  color: "#ffffff",
                }}
                aria-label={`Button for ${button.label}`}
                onClick={() => {
                  if (index === 0) {
                    handleLeaveReview(); // Trigger handleLeaveReview if index is 0
                  } else if (button.label === "Follow Us On Social Media") {
                    setShowSocialIcons((prev) => !prev); // Toggle social icons visibility
                  }
                }}
              >
                {button.label}
              </button>
            );
          })}
      </div>

      {/* Social Media Icons */}
      {showSocialIcons && (
        <div className="flex space-x-4 justify-center">
          {facebookUrl && (
            <a
              href={facebookUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-lg transition-transform transform hover:scale-110 hover:shadow-xl"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }} // Light background for contrast
            >
              <FaFacebook className="text-blue-600 w-6 h-6" />
            </a>
          )}
          {instagramUrl && (
            <a
              href={instagramUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-lg transition-transform transform hover:scale-110 hover:shadow-xl"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }} // Light background for contrast
            >
              <FaInstagram className="text-pink-600 w-6 h-6" />
            </a>
          )}
          {twitterUrl && (
            <a
              href={twitterUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-lg transition-transform transform hover:scale-110 hover:shadow-xl"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }} // Light background for contrast
            >
              <FaXTwitter className="text-blue-400 w-6 h-6" />
            </a>
          )}
        </div>
      )}

      <footer className="text-center absolute bottom-4 text-white w-full">
        <p className="text-lg">
          Powered by <span className="font-semibold">Zola Technologies</span>
        </p>
      </footer>
    </div>
  );
};

const MobileDataAbsent = ({
  profileImage,
  preferredBusinessName,
  handleLeaveReview,
  handleComingSoon,
}) => {
  return (
    <section className="min-h-screen flex flex-col items-center bg-white dark:bg-gray-800 transition duration-300">
      <div className="relative w-full h-60">
        <img
          src={profileImage}
          alt="Company Team"
          className="w-full h-60 object-cover shadow-lg transition-opacity duration-500"
        />
        <div className="absolute top-52 left-1/4 transform -translate-x-1/2 bg-blue-500 p-4 rounded-md text-white font-semibold max-w-[80%] text-center">
          {preferredBusinessName}
        </div>
      </div>
      <div className="mt-8 text-center px-4">
        <h1 className="text-2xl font-semibold mb-1 text-gray-900 dark:text-gray-100">
          Hi there 👋
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-400 mb-6">
          Thanks for being our visitor today!
        </p>
        <div className="space-y-4 w-full px-6">
          <button
            onClick={handleLeaveReview}
            className="w-full py-3 bg-white border border-gray-300 rounded-md text-lg font-semibold hover:bg-gray-100 transition dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600"
            aria-label="Leave a review"
          >
            Leave Us A Review
          </button>
          <button
            onClick={handleComingSoon}
            className="w-full py-3 bg-white border border-gray-300 rounded-md text-lg font-semibold hover:bg-gray-100 transition dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600"
            aria-label="Refer us to a friend"
          >
            Refer Us To A Friend
          </button>
          <button
            onClick={handleComingSoon}
            className="w-full py-3 bg-white border border-gray-300 rounded-md text-lg font-semibold hover:bg-gray-100 transition dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600"
            aria-label="Follow us on Instagram"
          >
            Follow Us On Instagram
          </button>
        </div>
      </div>
      <footer className="absolute bottom-4 text-center text-white">
        <p className="text-lg">
          Powered by <span className="font-semibold">Zola Technologies</span>
        </p>
      </footer>
    </section>
  );
};

const CompanyDetails = () => {
  const { company_username, employee_username } = useParams();
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  const [mobileData, setMobileData] = useState(null);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const navigate = useNavigate();
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");

  useEffect(() => {
    const fetchBusinessName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/get/${company_username}`
        );
        setPreferredBusinessName(response.data.preferred_business_name);
        setProfileImage(response.data.profile_image);
      } catch (error) {
        console.error("Error fetching company profile:", error);
      }
    };
    fetchBusinessName();
  }, [company_username]);

  useEffect(() => {
    const fetchMobileData = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/${company_username}/feedback/mobile/get-mobile-page/`
        );
        console.log(response);
        setMobileData(response.data);
        const buttons = response.data.buttons;
        const socialMedia = buttons.button_2?.socialMedia || {}; // Assuming button_2 is the one that contains socialMedia

        setFacebookUrl(socialMedia.facebook || "");
        setInstagramUrl(socialMedia.instagram || "");
        setTwitterUrl(socialMedia.twitter || "");
      } catch (error) {
        console.error("Error fetching company mobile data:", error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is complete
      }
    };
    fetchMobileData();
  }, [company_username]);

  const handleLeaveReview = () => {
    navigate(`/employee_rating/${company_username}/${employee_username}`);
  };

  const handleComingSoon = () => {
    setShowComingSoon(true);
  };

  const resetComingSoon = () => {
    setShowComingSoon(false);
  };

  if (showComingSoon) {
    return (
      <ComingSoon
        companyUsername={company_username}
        employeeUsername={employee_username}
        onGoBack={resetComingSoon}
      />
    );
  }

  if (loading) {
    // Show a loading spinner or message while data is being fetched
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-lg">Loading...</p>
      </div>
    );
  }

  return (
    <>
      {mobileData ? (
        <MobileDataPresent
          bgColor={mobileData.bg_color}
          coverImage={mobileData.cover_image}
          companyLogo={mobileData.company_logo}
          welcomeMessage={mobileData.welcome_message}
          visitMessage={mobileData.visit_message}
          buttons={mobileData.buttons}
          textColor={mobileData.text_color}
          handleLeaveReview={handleLeaveReview}
          facebookUrl={facebookUrl} // Use state variable here
          instagramUrl={instagramUrl} // Use state variable here
          twitterUrl={twitterUrl}
        />
      ) : (
        // <MobileDataAbsent
        //   profileImage={profileImage}
        //   preferredBusinessName={preferredBusinessName}
        //   handleLeaveReview={handleLeaveReview}
        //   handleComingSoon={handleComingSoon}
        // />
        handleLeaveReview()
      )}
    </>
  );
};

export default CompanyDetails;
