import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  FaStar,
  FaUserPlus,
  FaSort,
  FaEllipsisH,
  FaCopy,
  FaSearch,
  FaClipboard,
  FaTimes,
  FaExclamationTriangle,
} from "react-icons/fa";
import { MdError, MdVerified } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { PiFunnel } from "react-icons/pi";
import { IoCaretDownOutline, IoCaretUpOutline } from "react-icons/io5";
import EditForm from "./EditForm";
import { useNavigate } from "react-router-dom";

function CompanyEmployees() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", username: "" });
  const [sortOption, setSortOption] = useState("Sort by");
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10; // Items per page
  const currentPage = 1;
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        toast.error("No token found");
        return;
      }

      setLoading(true);
      try {
        const response = await fetch(
          `https://zola.technology/api/company/employees/get-all/?page=${currentPage}&page_size=${pageSize}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Network response was not ok");

        const result = await response.json();
        console.log(result);
        setData(result.data);
        setTableData(result.data);
        setTotalPages(result.total_pages);
      } catch (error) {
        setError(error.message);
        toast.error(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, pageSize]); // Add dependencies here to re-fetch data on change

  const labels = data.map((item) => item.employee?.name || "");
  const chartdata = data.map((item) => item.employee?.number_of_taps || 0);

  // Chart data for Chart.js
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Performance",
        data: chartdata,
        borderColor: "#22c55e",
        backgroundColor: "rgba(34, 197, 94, 0.2)",
        pointBorderColor: "#22c55e",
        pointBackgroundColor: "#22c55e",
        tension: 0.4, // Smooth curves
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // Function to toggle the modal visibility and clear form data when closed
  const handleModalToggle = () => {
    if (isModalOpen) setFormData({ name: "", username: "" }); // Reset form data when closing
    setIsModalOpen(!isModalOpen);
  };

  // form submission from reloading the page
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");

    if (!formData.name.trim()) {
      toast.error("Name cannot be empty.");
      return;
    }
    if (!formData.username.trim()) {
      toast.error("Username cannot be empty.");
      return;
    }

    try {
      const response = await fetch(
        "https://zola.technology/api/company/employees/add/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.username[0] || "Error adding employee");
        throw new Error("Network response was not ok");
      }
      // Success notification
      toast.success("Employee added successfully");
      setIsModalOpen(false);
      // Redirect to team management page
    } catch (error) {
      // console.error("Error adding employee:", error);
      toast.error("Error adding employee");
    }
    console.log("Form submitted", formData);
    handleModalToggle();
  };

  // Update form input state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle sorting based on selected option
  const handleSelect = (option) => {
    setSortOption(option);
    setIsOpen(false);
    const sortedData = [...tableData];
    switch (option) {
      case "Rating":
        sortedData.sort((a, b) => b.average_rating - a.average_rating);
        break;
      case "Name":
        sortedData.sort((a, b) =>
          a.employee.name.localeCompare(b.employee.name)
        );
        break;
      case "Feedbacks":
        sortedData.sort((a, b) => b.total_feedback - a.total_feedback);
        break;

      case "Rewards":
        sortedData.sort((a, b) => b.reward_total - a.reward_total);
        break;
      default:
        break;
    }
    setTableData(sortedData);
  };

  // Handle updating employee (dummy function)
  const handleUpdate = (index) => {
    setShowPopup(true);
    setSelectedItemIndex(index);
    // console.log("Update employee at index:", index);
    toast.success("Employee updated!");
  };

  const handleEditFormClose = () => setShowPopup(false);

  const handleUpdateData = async (updatedValues) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }
    // console.log(data[selectedItemIndex].employee.id, "selected employee id");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/update/${data[selectedItemIndex].employee.id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updatedValues.name,
            username: updatedValues.username,
          }),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const updatedData = [...data];
      updatedData[selectedItemIndex] = {
        ...updatedData[selectedItemIndex],
        name: updatedValues.name,
        username: updatedValues.username,
      };
      setData(updatedData);
      toast.success("Employee data updated successfully!");
    } catch (error) {
      setError(error.message);
      toast.error(`Update Error: ${error.message}`);
    }
  };

  // Handle deleting employee (dummy function)
  const handleDeleteClick = (item) => {
    setSelectedEmployee(item.employee);
    setIsDeleteModalOpen(true); // Open the delete modal
  };

  const filteredTableData =
    tableData?.filter((item) =>
      item?.employee?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  const handleDeleteConfirm = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    setLoading(true);
    setDeletingIndex(selectedEmployee.id);

    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/delete/${selectedEmployee?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Filter out the deleted employee from data and tableData
        const updatedData = data.filter(
          (item) => item.employee.id !== selectedEmployee.id
        );
        setData(updatedData);
        setTableData(updatedData);

        toast.success(`${selectedEmployee?.name} deleted successfully!`);
      } else {
        throw new Error("Failed to delete employee");
      }
    } catch (error) {
      setError(error.message);
      toast.error(`Delete Error: ${error.message}`);
    } finally {
      setLoading(false);
      setDeletingIndex(null);
      setIsDeleteModalOpen(false); // Close the modal after deletion
      setSelectedEmployee(null); // Reset the selected employee
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false); // Close the delete modal
    setSelectedEmployee(null); // Reset the selected employee
  };

  return (
    <div className="md:p-6 min-h-screen">
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        pauseOnHover
      />
      {/* Header Section */}
      <div className="flex items-start justify-between mb-6">
        <h2 className="text-lg md:text-2xl font-semibold">Employees</h2>
        <button
          onClick={handleModalToggle}
          className="bg-blue-600 text-white px-2 md:px-4 py-1 md:py-2 rounded-md shadow-sm flex items-center gap-2 hover:bg-blue-700 transition"
        >
          <FaUserPlus /> Add Employee
        </button>
      </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50"
          aria-modal="true"
          role="dialog"
        >
          <div className="bg-white rounded-xl overflow-hidden w-[90%] max-w-md relative">
            <div className="bg-blue-500 p-4">
              <h2 className="text-white text-2xl">Add Employee</h2>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4 mt-4 p-4">
              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex justify-end space-x-2 mt-6">
                <button
                  type="button"
                  className="bg-red-500 text-white px-4 py-2 rounded-lg shadow-sm hover:bg-red-600 transition"
                  onClick={handleModalToggle}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-sm hover:bg-blue-700 transition"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        {data.length === 0 ? (
          <p className="text-gray-500 text-center col-span-full">
            Employee details will appear here
          </p>
        ) : (
          data.map((item, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-xl flex flex-col items-start space-y-3 cursor-pointer hover transition"
              onClick={() =>
                navigate(`/dashboard/employee-profile/${item.employee.id}`)
              }
            >
              <div className="flex items-center gap-2">
                <p className="w-16 h-16 rounded-full flex items-center justify-center text-white font-bold text-xl bg-blue-600">
                  {`${item.employee.name.split(" ")[0][0].toUpperCase()}${
                    item.employee.name.split(" ")[1]
                      ? item.employee.name.split(" ")[1][0].toUpperCase()
                      : ""
                  }`}
                </p>

                <h3 className="text-lg font-semibold text-center">
                  {item.employee.name.split(" ")[0]} <br />
                  {item.employee.name.split(" ")[1]}
                </h3>
              </div>
              <div
                className={`text-lg flex items-center space-x-2 ${
                  item.performance_label === "Poor Employee"
                    ? "bg-red-100 text-red-600"
                    : "bg-blue-100 text-blue-600"
                } px-3 py-1 rounded-full`}
              >
                <div className="flex items-center space-x-1 rounded-full">
                  {item.performance_label === "Poor Employee" ? (
                    <MdError className="text-red-600" />
                  ) : (
                    <MdVerified className="text-blue-600" />
                  )}
                  <span>{item.performance_label}</span>
                </div>
              </div>
              <div className="flex items-center space-x-2 mt-1">
                <span className="text-gray-700 font-medium text-xl">
                  {item.employee.number_of_taps}
                  <span className="text-sm ml-2">taps</span>
                </span>
                <div className="flex items-center space-x-1">
                  <span className="text-gray-700 font-medium text-xl">
                    {item.average_rating}
                  </span>
                  <FaStar className="text-yellow-500" />
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <div className="bg-white p-4 md:p-6 rounded-lg mb-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold">Employee Overview</h3>
          <div className="relative">
            <select
              className="bg-gray-100 px-4 py-2 rounded-md text-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
              defaultValue="Monthly"
            >
              <option value="Monthly">Monthly</option>
            </select>
          </div>
        </div>
        <div className="w-full h-64">
          <Line data={chartData} options={chartOptions} />
        </div>
      </div>

      <div>
        <div className="bg-white p-4 w-full flex flex-col md:flex-row flex-wrap justify-between items-center gap-4">
          <h3 className="text-base md:text-lg font-semibold">Employees</h3>

          <div className="flex flex-col md:flex-row items-center w-full md:w-auto gap-4 relative">
            {" "}
            {/* Add relative here */}
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="flex justify-between md:justify-center items-center w-full md:w-auto gap-3 px-3 py-2 border border-gray-300 rounded-lg bg-white text-gray-700 hover:bg-gray-100 transition"
            >
              <PiFunnel className="text-lg" />
              <span className="flex gap-1">
                Sort <span className="block md:hidden lg:block">by</span>
              </span>
              {isOpen ? (
                <IoCaretUpOutline className="text-sm" />
              ) : (
                <IoCaretDownOutline className="text-sm" />
              )}
            </button>
            {isOpen && (
              <div className="absolute z-10 mt-2 w-full md:w-48 rounded-lg shadow-lg bg-white border border-gray-300">
                {" "}
                {/* Updated dropdown */}
                <ul className="py-1 text-sm text-gray-700">
                  {["Rating", "Name", "Rewards", "Feedbacks"].map((option) => (
                    <li
                      key={option}
                      onClick={() => handleSelect(option)}
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="relative w-full md:w-auto">
              <input
                type="text"
                placeholder="Search..."
                className="w-full md:w-52 lg:w-64 px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FaSearch className="absolute right-3 top-3 text-gray-500" />
            </div>
            <button className="w-full lg:w-auto bg-gray-100 px-4 py-2 rounded-md hover:bg-gray-200 transition">
              See all
            </button>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-separate border-spacing-y-3">
          <thead>
            <tr className="text-left text-gray-600 text-sm">
              <th className="px-4 py-2 font-normal">S/N</th>
              <th className="px-4 py-2 font-normal">Name</th>
              <th className="px-4 py-2 font-normal">Card URL</th>
              <th className="px-4 py-2 font-normal">Feedback</th>
              <th className="px-4 py-2 font-normal">Rewards</th>
              <th className="px-4 py-2 font-normal">Rating</th>
              <th className="px-4 py-2 font-normal">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredTableData.map((item, index) => (
              <tr
                key={index}
                className="bg-white text-gray-700 rounded-lg hover transition"
              >
                <td data-cell="S/N" className="px-4 py-2">
                  {index + 1}
                </td>
                <td data-cell="Name" className="px-4 py-2">
                  <div className="flex items-center gap-2">
                    <p className="w-10 h-10 rounded-full flex items-center justify-center text-white font-bold text-xl bg-blue-600">
                      {item.employee.name
                        .split(" ")
                        .slice(0, 2) // Only consider the first two parts of the name
                        .map((n) => n[0].toUpperCase()) // Get the first letter of each part and make it uppercase
                        .join("") || item.employee.name[0].toUpperCase()}{" "}
                    </p>

                    <span>{item.employee.name}</span>
                  </div>
                </td>
                <td data-cell="card URL" className="px-4 py-2">
                  <div className="nfc-url flex items-center md:gap-2">
                    <a
                      href={item.employee.employee_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 text-xs md:text-base underline"
                    >
                      {item.employee.employee_url}
                    </a>
                    <button
                      className="text-gray-400 hover:text-gray-600"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          item.employee.employee_url
                        );
                        toast.success("Employee URL copied to clipboard!");
                      }}
                    >
                      <FaClipboard />
                    </button>
                  </div>
                </td>
                <td data-cell="Feedback" className="px-4 py-2">
                  {item.total_feedback}
                </td>
                <td data-cell="Rewards" className="px-4 py-2">
                  {item.reward_currency} {item.reward_total}
                </td>
                <td data-cell="Rating" className="px-4 py-2">
                  <div className="flex items-center gap-2">
                    <p>{item.average_rating}</p>
                    <FaStar className="text-yellow-500" />
                  </div>
                </td>
                <td data-cell="Action" className="px-4 py-2">
                  <div className="flex flex-row gap-2">
                    <button
                      className="px-3 py-1 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 transition"
                      onClick={() => handleUpdate(index)}
                    >
                      Update
                    </button>
                    <button
                      onClick={() => handleDeleteClick(item)} // Opens delete confirmation modal
                      className="px-3 py-1 bg-red-100 text-red-500 rounded-md shadow-sm hover:bg-red-600 transition"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showPopup && selectedItemIndex !== null && data[selectedItemIndex] ? (
        <EditForm
          selectedItem={data[selectedItemIndex]?.employee} // Use optional chaining
          onUpdate={handleUpdateData}
          onClose={handleEditFormClose}
        />
      ) : null}

      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4">
            <FaExclamationTriangle className="text-red-500 text-5xl mx-auto" />
            <h2 className="text-2xl font-semibold">Delete Employee?</h2>
            <p className="text-gray-600">This action cannot be undone.</p>
            <div className="flex justify-center space-x-4 mt-4">
              <button
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition"
                onClick={handleDeleteCancel} // Closes the delete confirmation modal
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                onClick={handleDeleteConfirm} // Confirms deletion of the selected employee
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompanyEmployees;
