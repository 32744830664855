import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import axios from 'axios';
import { useRating } from "../../context/RatingContext";

const shortenCompanyName = (fullCompanyName) => {
  let mainName = fullCompanyName.split(" - ")[0];
  if (mainName === fullCompanyName) {
    mainName = fullCompanyName.split(",")[0];
  }
  return mainName;
};

function EmployeeRating() {
  const { company_username, employee_username } = useParams();
  const { setEmployeeRating } = useRating();
  const [hoverRating, setHoverRating] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  const navigate = useNavigate();
  const [employeeName, setEmployeeName] = useState("");

  useEffect(() => {
    const fetchBusinessName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/get/${company_username}`
        );
        const preferredName = response.data.preferred_business_name;
        setPreferredBusinessName(preferredName);
        const fullCompanyName = response.data.company_name;
        const shortenedName = shortenCompanyName(fullCompanyName);
        setCompanyName(shortenedName);
        setProfileImage(response.data.profile_image); // Store profile image URL
      } catch (error) {
        console.error("Error fetching company profile:", error);
      }
    };
    fetchBusinessName();
  }, [company_username]);

  useEffect(() => {
    const getEmployeeName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/employees/get/${company_username}/${employee_username}/employee-name/`
        );
        setEmployeeName(response.data.name); 
      } catch (error) {
        console.error("Error fetching employee profile:", error);
      }
    };
    getEmployeeName();
  }, [company_username, employee_username]);

  const handleStarClick = (selectedRating) => {
    setEmployeeRating(selectedRating);
    navigate(`/emoji/${encodeURIComponent(company_username)}/${encodeURIComponent(employee_username)}/`, {
      state: { employeeName }
    });
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center  p-6">
      {/* Business Info Section */}
      <div className="p-8 mb-8 flex flex-col items-center">
        {/* Profile Image */}
        <div className="relative">
          {imageLoading && (
            <div className="w-24 h-24 bg-gray-200 rounded-full mb-4 animate-pulse"></div>
          )}
          {profileImage && (
            <img
              src={profileImage}
              alt="Company Profile"
              className={`w-24 h-24 rounded-full mb-4 border-4 border-blue-300 shadow-lg transition-opacity duration-500 ${
                imageLoading ? "opacity-0" : "opacity-100"
              }`}
              onLoad={() => setImageLoading(false)} // Handle image load
              onError={() => setImageLoading(false)} // Handle image error
            />
          )}
        </div>
        {/* Business Name */}
        <h1 className="text-xl text-center md:text-2xl lg:text-3xl font-bold text-gray-800 mb-2">
          {preferredBusinessName || companyName}
        </h1>
        <p className="text-sm text-gray-500">{companyName}</p>
      </div>

      {/* Rating Section */}
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 text-gray-900 text-center">
        Please rate your experience with our employee
      </h2>

      <div className="p-6 max-w-md mx-auto transform transition duration-500 hover:scale-105">
        <div className="flex justify-center gap-3">
          {[...Array(5)].map((_, index) => {
            const starValue = index + 1;
            return (
              <FaStar
                key={starValue}
                size={40}
                className={`cursor-pointer transition-transform duration-300 ${
                  starValue <= (hoverRating)
                    ? "text-yellow-400 scale-125"
                    : "text-gray-300"
                }`}
                onClick={() => handleStarClick(starValue)}
                onMouseEnter={() => setHoverRating(starValue)}
                onMouseLeave={() => setHoverRating(0)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default EmployeeRating;
