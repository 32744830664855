import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

function ForgotPassword() {
  const [username, setUsername] = useState("");
  const navigate = useNavigate(); // To programmatically navigate the user

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      // Save username in localStorage
      localStorage.setItem("username", username);

      // Send request to the API with the username
      const response = await axios.post(
        `https://zola.technology/api/generate-otp/${username}/`
      );

      if (response.status === 200) {
        toast.success("Password reset instructions have been sent!");
        // Redirect to /forgotpassword after successful request
        console.log(response.data)
        localStorage.setItem("otpData", JSON.stringify(response.data))
        navigate("/otppage");
      }
    } catch (error) {
      toast.error("Failed to send reset instructions. Check username and try again.");
    }
  };

  return (
    <section className="flex items-center justify-center w-full min-h-screen p-6 sm:p-12 bg-gray-100">
      <div className="w-full max-w-md sm:max-w-lg bg-white shadow-lg rounded-lg p-6 sm:p-8">
        <h2 className="text-xl sm:text-2xl font-bold text-center mb-4 sm:mb-6">
          Forgot Password
        </h2>
        <p className="text-center mb-4 text-gray-600">
          Enter your username and we’ll send you instructions to reset your
          password.
        </p>
        <form
          className="flex flex-col gap-4 sm:gap-6"
          onSubmit={handleForgotPassword}
        >
          <input
            type="text"
            className="border rounded-md p-3 text-base bg-gray-50 focus:bg-white focus:border-gray-300 transition-colors"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
            type="submit"
          >
            Send Reset Instructions
          </button>
        </form>
        <p className="text-center mt-4 text-sm sm:text-base">
          Remembered your password?{" "}
          <Link to="/login" className="text-blue-500 hover:underline">
            Go back to Login
          </Link>
        </p>
      </div>
      <ToastContainer />
    </section>
  );
}

export default ForgotPassword;
