import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

function NegativeReviewPrompt() {
  const { company_username, employee_username, emoji } = useParams();
  const [placeId, setPlaceId] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const rating = queryParams.get("rating");
  const feedback = queryParams.get("feedback");

  useEffect(() => {
    const fetchBusinessName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/get/${company_username}`
        );
        setCompanyName(response.data.company_name);
      } catch (error) {
        console.error("Error fetching company profile:", error);
      }
    };

    fetchBusinessName();
  }, [company_username]);

  const fetchPlaceId = async () => {
    try {
      const response = await axios.get(
        `https://zola.technology/api/company/reviews/${company_username}`
      );

      const placeId = response.data;
      setPlaceId(placeId);
      return placeId;
    } catch (error) {
      console.error("Error fetching place ID:", error);
      setPlaceId(null);
      return null;
    }
  };

  useEffect(() => {
    fetchPlaceId();
  }, [company_username]);

  const generateGoogleReviewURL = (placeId) => {
    return `https://search.google.com/local/writereview?placeid=${placeId}`;
  };

  const handlePostReviewClick = () => {
    if (placeId) {
      window.location.href = generateGoogleReviewURL(placeId);
    } else {
      alert("Failed to fetch place ID. Please try again.");
    }
  };

  const handleSendFeedbackClick = () => {
    // Ensure that you have `rating` and `feedback` values
    const ratingToSend = rating || "default"; // Provide a default rating if needed
    const feedbackToSend = feedback || ""; // Provide a default feedback message if needed
  
    navigate(
      `/feedback/${encodeURIComponent(company_username)}/${encodeURIComponent(employee_username)}/${encodeURIComponent(emoji)}/make-a-zola-review?rating=${encodeURIComponent(ratingToSend)}&feedback=${encodeURIComponent(feedbackToSend)}`
    );
  };
  

  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <div className="bg-white rounded-lg p-6 text-center max-w-lg w-full">
        {/* <img src={zolaLogo} alt="Zola logo" className="mx-auto mb-4 w-20" /> */}
        
        {/* Displaying the companyName here */}
        {/* {companyName && (
          <p className="absolute top-2 right-0 text-2xl font-bold mb-2">{companyName}</p>
        )} */}

        <p className="text-3xl font-semibold mb-2">We sincerely apologize for any inconvenience you experienced.</p>
        <p className="text-2xl mb-12 mt-8">Allow us the opportunity to make it right for you.</p>
        
        <button
          onClick={handleSendFeedbackClick}
          className="bg-blue-500 text-white py-3 px-4 rounded-full mb-4 w-full text-xl hover:bg-blue-600 font-semibold"
        >
          Send Feedback to the Manager
        </button>
        <p className="text-lg my-3">Or</p>
        <button
          onClick={handlePostReviewClick}
          className="border border-blue-500 text-black py-3 px-4 rounded-full w-full mt-2 hover:bg-blue-50 text-xl font-semibold"
        >
          Share Online
        </button>
      </div>
      
      <div className="absolute top-4 left-4">
        <button onClick={() => navigate(-1)} className="text-gray-700 text-4xl">
          ←
        </button>
      </div>
      
      <div className="absolute bottom-4 w-full text-center text-black">
        Powered by Zola Technologies
      </div>
    </div>
  );
}

export default NegativeReviewPrompt;
