import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconifyIcon from "../../HQDashboard/components/topbar/IconifyIcon";
import { IoNotifications } from "react-icons/io5";
import { FaBars } from "react-icons/fa";
import ZolaLogo from "../../HQDashboard/components/sidebar/Layer_1.png";
import { AuthContext } from "../../context/AuthContext";

const Topbar = ({ toggleSidebar, theme, toggleTheme }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser, isAuthenticated, logout } = useContext(AuthContext);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const profileImage = userData?.profile_image;
  const isHq = userData?.is_hq_company;
  const navigate = useNavigate();

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  function handleClick() {
    navigate("/hqdashboard");
  }

  if (!currentUser || !isAuthenticated) {
    return null;
  }

  return (
    <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-900 shadow-md dark:shadow-lg border-b-[1px] border-[#EAEAEC] lg:w-full xs:w-full">
      {/* Hamburger Icon for Mobile */}

      {/* Logo */}
      <div className="md:flex lg:hidden items-center space-x-4">
        <img
          src={ZolaLogo}
          alt="Company Logo"
          className="w-24 h-10 object-contain"
        />
      </div>

      {/* User Greeting (hidden on mobile) */}
      <div className="hidden lg:flex items-center space-x-2 mx-4">
        <div className="flex items-center space-x-2">
          <img
            src={profileImage}
            alt="User Avatar"
            className="w-10 h-10 rounded-full object-cover border-[2px] border-[#0C4EF8]"
          />
          <div>
            <h2 className="text-lg font-normal uppercase dark:text-white">
              {userData.company_name}
            </h2>
          </div>
        </div>
      </div>

      {/* Notifications & HQ Switch */}
      <div className="flex items-center space-x-2">
        <button className="p-2 text-gray-700 dark:text-gray-300 hidden sm:block">
          <IoNotifications className="text-[#0C4EF8] h-5 w-5" />
        </button>

        {/* Hamburger Icon For Sidebar Toggling */}
        <button
          className="lg:hidden p-2 text-gray-700 dark:text-gray-300"
          onClick={toggleSidebar}
          >
          <FaBars className="h-6 w-6" />
          </button>

        {isHq && (
          <button
            className="hidden sm:block px-4 py-2 bg-blue-600 text-white rounded-[12px] hover:bg-blue-700 transition shadow-lg dark:bg-blue-500 dark:hover:bg-blue-600"
            onClick={handleClick}
          >
            Switch HQ Dashboard
          </button>
        )}
      </div>
    </div>
  );
};

export default Topbar;
