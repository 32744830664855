import PropTypes from 'prop-types';
import { useEffect } from 'react';

const StepFour = ({ url }) => {
  useEffect(() => {
    if (url) {
      window.location.href = url; // Redirect to the payment URL
    }
  }, [url]);

  return (
    <div className="w-full mx-auto p-6 flex flex-col items-center bg-white flex-1 md:ml-[75%] lg:ml-[50%]">
      <h2 className="text-xl font-semibold mb-4">Step 4: Payment</h2>
      <p>Redirecting to payment gateway...</p>
    </div>
  );
};

StepFour.propTypes = {
  url: PropTypes.string.isRequired,
};

export default StepFour;
