import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function MobileSurvey() {
  const [survey, setSurvey] = useState(null);
  const [error, setError] = useState(null);
  const [responses, setResponses] = useState({}); // Store user responses here
  const { company_username } = useParams(); // Retrieve username and survey_id from URL params

  const survey_id = 16
  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/surveys/survey/get/${company_username}/${survey_id}/`
        );
        setSurvey(response.data);
      } catch (error) {
        console.error("Error fetching survey:", error);
        setError("Failed to load survey. Please try again.");
      }
    };

    fetchSurvey();
  }, [company_username, survey_id]);

  const handleResponseChange = (questionId, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: value,
    }));
  };

  const handleCheckboxChange = (questionId, choice) => {
    setResponses((prevResponses) => {
      const currentSelections = prevResponses[questionId] || [];
      const updatedSelections = currentSelections.includes(choice)
        ? currentSelections.filter((item) => item !== choice)
        : [...currentSelections, choice];
      return {
        ...prevResponses,
        [questionId]: updatedSelections,
      };
    });
  };

  if (error) return <div>{error}</div>;
  if (!survey) return <div>Loading survey...</div>;

  return (
    <div className="p-4 bg-gray-100 rounded-lg shadow-md h-screen">
      <h1 className="text-2xl font-semibold text-gray-800 mb-4">{survey.title}</h1>
      <p className="text-sm text-gray-500 mb-6">
        Created at: {new Date(survey.created_at).toLocaleDateString()}
      </p>

      <div className="space-y-6">
        {survey.questions && survey.questions.map((question, index) => (
          <div key={question.id} className="bg-white p-4 rounded-lg shadow">
            <p className="font-semibold text-gray-700 mb-2">
              {index + 1}. {question.question_text}
            </p>

            {question.question_type === 'paragraph' && (
              <textarea
                className="w-full border border-gray-300 rounded-lg p-2 mt-2"
                rows="4"
                placeholder="Type your answer here..."
                value={responses[question.id] || ''}
                onChange={(e) => handleResponseChange(question.id, e.target.value)}
              />
            )}

            {question.question_type === 'single_choice' && (
              <div className="space-y-2 mt-2">
                {question.choices.map((choice, choiceIndex) => (
                  <div key={choiceIndex} className="flex items-center space-x-2">
                    <input
                      type="radio"
                      id={`question-${question.id}-choice-${choiceIndex}`}
                      name={`question-${question.id}`}
                      value={choice}
                      checked={responses[question.id] === choice}
                      onChange={() => handleResponseChange(question.id, choice)}
                      className="form-radio text-blue-600"
                    />
                    <label htmlFor={`question-${question.id}-choice-${choiceIndex}`} className="text-gray-700">
                      {choice}
                    </label>
                  </div>
                ))}
              </div>
            )}

            {question.question_type === 'multiple_choice' && (
              <div className="space-y-2 mt-2">
                {question.choices.map((choice, choiceIndex) => (
                  <div key={choiceIndex} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id={`question-${question.id}-choice-${choiceIndex}`}
                      name={`question-${question.id}`}
                      value={choice}
                      checked={responses[question.id]?.includes(choice) || false}
                      onChange={() => handleCheckboxChange(question.id, choice)}
                      className="form-checkbox text-blue-600"
                    />
                    <label htmlFor={`question-${question.id}-choice-${choiceIndex}`} className="text-gray-700">
                      {choice}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MobileSurvey;
